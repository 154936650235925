import {AiTool, Campaign, TrainingSet, TrainingSetFile, TrainingSetVideo} from "./ai-model";
import {AiPersonaTaskLog, ParsedTaskInstructions, Persona} from "./persona";
import {Question} from "./questions";
import {Survey} from "./survey";

export interface BaseAiOrchestration {
	id: string;
	workspaceId: string;
	name: string;
	outputTypeId: string;
	description: string;
}

export interface AiOrchestrationOutputTypes {
	id: string;
	code: string;
	name: string;
	vurveyOnly: boolean;
	createdAt: string; // Maybe Date? But API says String!
	updatedAt: string;
}

export interface AiOrchestration extends BaseAiOrchestration {
	// base
	inputParameters: string;
	instructions: string;
	outputType: AiOrchestrationOutputTypes;
	status?: 'in_progress' | 'completed' | 'failed' | 'paused';

	// tasks
	tasks: AiOrchestrationTask[];
	aiPersonaTasks: AiPersonaTask[];

	// sources
	files: TrainingSetFile[];
	questions: Question[];
	campaigns: Campaign[];
	trainingSets: TrainingSet[];
	videos: TrainingSetVideo[];
}

export interface PageInfo {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string | null;
    endCursor: string | null;
}

export interface AiOrchestrationEdge {
    node: AiOrchestration;
    cursor: string;
}

export interface AiOrchestrationsConnection {
    edges: AiOrchestrationEdge[];
    pageInfo: PageInfo;
    totalCount: number;
}

export interface AiOrchestrationData {
	aiOrchestration: AiOrchestration;
}

export interface CreateAiOrchestrationData {
	createAiOrchestration: BaseAiOrchestration;
}

export interface CreateAiOrchestrationVars {
	workspaceId: string;
	name: string;
	description?: string;
	inputParameters?: string;
	instructions?: string;
	outputTypeId?: string;
	surveyIds?: string[];
	trainingSetIds?: string[];
	questionIds?: string[];
	fileIds?: string[];
	videoIds?: string[];
	autoWorkflow?: boolean;
	orchestrationId?: string; // Add orchestrationId parameter
}

export interface UpdateOrchestrationData {
	updateAiOrchestration: AiOrchestration;
}

export type UpdateOrchestrationVars = Omit<Partial<CreateAiOrchestrationVars>, "workspaceId"> & {id: string};

export interface AiOrchestrationOutputTypesData {
	aiOrchestrationOutputTypes: AiOrchestrationOutputTypes[];
}

export interface AiOrchestrationDeleteData {
	deleteAiOrchestration: {id: string};
}

/**
 * A FrontEnd interface we're building on the fly.
 * Should help with making flows... flow correctly
 */
export interface AiOrchestrationTask {
	personaId: string;
	taskId: string;
	personaName: string;
	category: string
	taskLog: AiPersonaTaskLog[];
	instructions: string;
	parsedInstructions?: ParsedTaskInstructions;
	index: number;
	tools: AiTool[];
	picture?: string;
    processingState: string;
    output: string;
	persona: Persona;
}

export interface ProcessingStep {
    timestamp: number;
    message: string;
    sequence?: number;
}

export enum AiPersonaTaskOperation {
	CREATE = "CREATE",
	UPDATE = "UPDATE",
	DELETE = "DELETE",
}

export interface AiPersonaTask {
	id: string;
	persona?: Persona;
	index: number;
	processingState?: string;
	currentStep?: string;
	processingSteps?: ProcessingStep[];
	output?: string;
	task?: {
		taskPrompt: string;
		output?: string;
		id?: string;
	};
	smartPromptEnabled?: boolean;
	chatMode?: string;
}

export interface AiOrchestrationHistoryTasks {
	taskId: string;
	persona?: Persona;
	personaId: string;
	personaName: string;
	input: string;
	output: string;
	processState: string;
	taskPrompt: string;
}

export type AiOrchestrationSources = Campaign | TrainingSet | Question | Survey | TrainingSetFile | TrainingSetVideo;

export interface AiOrchestrationHistory {
	id: string;
	aiOrchestrationId: string;
	startedAt: string;
	completedAt: string;
	status: 'failed' | 'in_progress' | 'completed';
	output: string;
	outputHistoryTasks: AiOrchestrationHistoryTasks[];
	reportUrl: string;
	reportWordUrl: string;
	processState: string;
	populatedSources: AiOrchestrationSources[];
	sources: {
		fileIds: string[];
		questionIds: string[];
		surveyIds: string[];
		trainingSetIds: string[];
		videoIds: string[];
	}
}

export interface AiOrchestrationHistoryData {
    aiOrchestrationHistory: AiOrchestrationHistory[];
}

export interface AiOrchestrationsInput {
    first?: number;
    last?: number;
    after?: string;
    before?: string;
    sort?: string;
}

export interface TaskUpdated {
    type: 'TaskUpdated';
    data: {
        orchestrationId: string;
        taskId: string;
        personaId: string;
        personaName: string;
        processingState: string;
    };
    metadata?: {
        timestamp: string;
        sequence: number;
        final?: boolean;
        error?: boolean;
    };
}
