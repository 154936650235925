import {usePopper} from "react-popper";
import classNames from "classnames/bind";
import React, {ReactElement, useCallback, useMemo, useState} from "react";

import {GET_TRAINING_SET_MEDIA_CRITERIA} from "@/graphql/queries/ai-models-queries";
import {GetTrainingSetMediaCriteriaResponse, GetTrainingSetMediaCriteriaVariables, TrainingSetMediaCriteria, TrainingSetMediaCriteriaSort} from "@/models/ai-model";
import {getTrainingSetMediaCriteriaId, isFileTag, isFileTagKey, isTrainingSetMediaEmbeddingsStatus, isTrainingSetMediaTypeAlias} from "@/shared";
import {NetworkStatus} from "@apollo/client";
import {SearchInput, Spinner} from "@/shared/v2";
import {TrainingSetMediaCriteriaChip} from "../../training-set-media-criteria-chip";
import {useDebounceValue, useLoadingQuery} from "@/hooks";
import {useMediaCriteriaContext} from "@/context/media-criteria-context";
import {useTask} from "@/hooks/useTask";
import {useThemeMode} from "@/context/theme-mode-context";
import {useWorkspaceContext} from "@/context/workspace-context";

import styles from "./search-media-input.module.scss";

const cx = classNames.bind(styles);

const PAGE_SIZE = 50;

export interface SearchMediaInputProps {
  search: string;
  setSearch: (search: string) => void;
  trainingSetId?: string;
}

export const SearchMediaInput = ({search, setSearch, trainingSetId}: SearchMediaInputProps): ReactElement => {
  const {isDarkMode} = useThemeMode();
  const {add: _addCriteria, criteria: selectedCriteria} = useMediaCriteriaContext();

  const addCriteria = useCallback((criteria: TrainingSetMediaCriteria) => {
    setSearch("");
    _addCriteria(criteria);
  }, [_addCriteria]);

  const {workspace: {id: workspaceId}} = useWorkspaceContext();
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const [debouncedSearch] = useDebounceValue(search, 500);

	const {
    data,
    networkStatus,
    handleFetchMore,
  } = useLoadingQuery<
    GetTrainingSetMediaCriteriaResponse,
    GetTrainingSetMediaCriteriaVariables
  >(GET_TRAINING_SET_MEDIA_CRITERIA, {
    fetchPolicy: "network-only",
		variables: {
			workspaceId: workspaceId,
			limit: PAGE_SIZE,
			sort: TrainingSetMediaCriteriaSort.NAME_ASC,
      filter: {
        name: debouncedSearch,
        trainingSetId,
      }
		},
	});

  const fetchedCriteria: TrainingSetMediaCriteria[] = useMemo(() => {
    return data?.trainingSetMediaCriteria.items || [];
  }, [data]);

  const hasMore = useMemo(() => {
    return (data?.trainingSetMediaCriteria.remaining || 0) > 0;
  }, [data]);

  const displayedCriteria: TrainingSetMediaCriteria[] = useMemo(() => {
    return fetchedCriteria.filter((criteria) => !selectedCriteria.some(c => getTrainingSetMediaCriteriaId(c) === getTrainingSetMediaCriteriaId(criteria)));
  }, [fetchedCriteria, selectedCriteria]);

  const {styles: {popper: popperStyles}, attributes} = usePopper(referenceElement, popperElement, {
		placement: "bottom",
		strategy: "fixed",
		modifiers: [
			{
				name: "offset",
				options: {
					offset: [0, 8],
				},
			},
			{
				name: "flip",
			},
		],
	});

  const {run: fetchMore} = useTask(async () => {
    if (hasMore && networkStatus === NetworkStatus.ready) {
      await handleFetchMore(PAGE_SIZE);
    }
  }, [hasMore, networkStatus, handleFetchMore]);

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    const scrollBottom = target.scrollHeight - target.scrollTop - target.clientHeight;
    if (scrollBottom < 30) {
      fetchMore();
    }
  }, [fetchMore]);

  const isLoadingAll = useMemo(() => {
    return [
      NetworkStatus.loading,
      NetworkStatus.setVariables,
      NetworkStatus.refetch,
    ].includes(networkStatus);
  }, [networkStatus]);

  const isFetchingMore = useMemo(() => {
    return networkStatus === NetworkStatus.fetchMore;
  }, [networkStatus]);

  return (
    <div
      ref={setReferenceElement}
      className={styles.container}
    >
      <SearchInput
        value={search}
        onChange={setSearch}
        className={styles.searchInput}
      />

      {(isLoadingAll || isFetchingMore || networkStatus === NetworkStatus.ready) && (
        <div
          ref={setPopperElement}
          className={cx("dropdown", {isDarkMode})}
          style={popperStyles}
          onScroll={handleScroll}
          {...attributes.popper}
        >
          {!isLoadingAll && displayedCriteria.map(criteria => {
            return (
              <button
                className={styles.dropdownItem}
                key={getTrainingSetMediaCriteriaId(criteria)}
                onClick={() => addCriteria(criteria)}
              >
                <TrainingSetMediaCriteriaChip criteria={criteria} />
              </button>
            )
          })}
          {(isLoadingAll || isFetchingMore || hasMore) && <Spinner className={styles.fetchingSpinner} />}
        </div>
      )}
    </div>
  );
}

