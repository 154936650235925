import React, {ReactElement, Dispatch, SetStateAction} from "react";
import classNames from "classnames/bind";

import {InputProps} from "../input";
import {InputElements} from "../input-elements";

import styles from "../input/input.module.scss";

const cx = classNames.bind(styles);

export interface NumberInputProps extends Omit<InputProps<number | undefined>, "type" | "min" | "max" | "onChange"> {
	min?: number;
	max?: number;
	onChange: ((value: number | undefined) => void) | Dispatch<SetStateAction<number>>;
}

export const NumberInput = ({
	value,
	onChange,
	className,
	size = "small",
	variant = "default",
	label,
	hintText,
	leftIcon,
	rightIcon,
	onBlur,
	onEnter,
	max,
	min,
	...props
}: NumberInputProps): ReactElement => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (e.target.value === "") {
			// Handle undefined case for React state setters
			if (typeof onChange === 'function') {
				// For React state setters, we'll use 0 instead of undefined
				if (onChange.length === 1) {
					(onChange as Dispatch<SetStateAction<number>>)(0);
				} else {
					(onChange as (value: number | undefined) => void)(undefined);
				}
			}
			return;
		}
		
		const numValue = Number(e.target.value);
		if (max !== undefined && numValue > max) {
			onChange(max);
			return;
		}
		if (min !== undefined && numValue < min) {
			onChange(min);
			return;
		}
		onChange(numValue);
	}

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		if (e.key === "Enter") {
			e.preventDefault();
			onEnter?.(value);
		}
	}

	const onFocus = (e: React.FocusEvent<HTMLInputElement>): void => e.currentTarget.select();

	return <InputElements
		variant={variant}
		className={className}
		label={label}
		hintText={hintText}
		leftIcon={leftIcon}
		rightIcon={rightIcon}
	>
		<input
			className={cx("input", size, variant, leftIcon && "withLeftIcon", rightIcon && "withRightIcon")}
			value={value === undefined ? "" : value}
			onChange={handleChange}
			onBlur={() => onBlur?.(value)}
			onFocus={onFocus}
			onKeyDown={onKeyDown}
			type="number"
			{...props}
		/>
	</InputElements>
}
