import React, {ReactElement} from "react";

import {SearchInput as BaseSearchInput} from "@/shared/v2";
import {useSearchContext} from "../../contexts/search-context";

import styles from "./search-sources-input.module.scss";

export const SearchInput = (): ReactElement => {
  const {
    search,
    setSearch,
  } = useSearchContext();

  return (
    <BaseSearchInput
      value={search}
      onChange={setSearch}
      className={styles.searchInput}
    />
  );
}

