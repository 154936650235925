import classNames from "classnames/bind";
import React, {HTMLAttributes, ReactElement, ReactNode} from "react";

import {CloseMediumIcon} from "@/icons";
import {useThemeMode} from "@/context/theme-mode-context";

import styles from "./training-set-media-criteria.module.scss";

const cx = classNames.bind(styles);

export interface TrainingSetMediaCriteriaChipBaseProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  onRemove?: () => void;
}

export const TrainingSetMediaCriteriaChipBase = ({
  children,
  onRemove,
  ...htmlDivAttributes
}: TrainingSetMediaCriteriaChipBaseProps): ReactElement => {
  const {isDarkMode} = useThemeMode();

  return (
    <div className={cx("inputChip", {isDarkMode})} {...htmlDivAttributes}>
      {children}
      {onRemove &&
        <CloseMediumIcon
          className={styles.closeIcon}
          onClick={onRemove}
        />
      }
    </div>
  );
};
