import React, { ReactElement, useState } from "react";

import { useWorkspaceContext } from "@/context/workspace-context";
import { AddTrainingSetModal } from "@/datasets/modals";
import { TrainingSet } from "@/models/ai-model";
import { BaseModalProps, Button, Modal, Separator, Spinner } from "@/shared/v2";
import { TrainingCardSelect } from "@/modals/select-ai-sources-modal/components/training-card-select";
import { useToastContext } from "@/context/toast-context";
import { useSaveReportToDataset } from "./hooks/useSaveReportToDataset";
import { DatasetList } from "@/datasets/components/dataset-list";
import { CreateDatasetCard } from "@/datasets/components/create-dataset-card";

import styles from "./choose-dataset-modal.module.scss";

interface ChooseDatasetModalProps extends BaseModalProps {
  reportUrl?: string;
}

export const ChooseDatasetModal = ({
  isOpen,
  onClose,
  reportUrl,
}: ChooseDatasetModalProps): ReactElement => {
  const { updateToast } = useToastContext();
  const { saveReportToDataset } = useSaveReportToDataset();
  const {
    workspace: { id: workspaceId },
  } = useWorkspaceContext();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedTrainingSet, setSelectedTrainingSet] =
    useState<TrainingSet | null>(null);

  const handleTrainingSetSelect = (trainingSet: TrainingSet): void => {
    if (selectedTrainingSet?.id === trainingSet.id) {
      setSelectedTrainingSet(null);
    } else {
      setSelectedTrainingSet(trainingSet);
    }
  };

  const clearAndClose = () => {
    setSelectedTrainingSet(null);
    onClose();
  };

  const addToDataset = () => {
    if (selectedTrainingSet) {
      saveReportToDataset(selectedTrainingSet?.id, reportUrl);
    }

    updateToast({
      description:
        "Adding output to dataset in progress... You will receive a notification when it's done.",
      type: "informational",
    });

    clearAndClose();
  };

  const createAndAddToDataset = (id: string): void => {
    saveReportToDataset(id, reportUrl);

    updateToast({
      description:
        "Adding output to created dataset in progress... You will receive a notification when it's done.",
      type: "informational",
    });

    clearAndClose();
  };

  const renderCreateCard = (
    <CreateDatasetCard
      onClick={() => setIsAddModalOpen(true)}
      text='Create & Add'
    />
  );

  const renderDatasetCard = (trainingSet: TrainingSet) => (
    <TrainingCardSelect
      key={trainingSet.id}
      trainingSet={trainingSet}
      isSelected={selectedTrainingSet?.id === trainingSet.id}
      onSelected={handleTrainingSetSelect}
    />
  );

  return (
    <Modal
      portal
      className={styles.modal}
      headerWrapperClassName={styles.modalHeader}
      size='large'
      isOpen={isOpen}
      onClose={onClose}
      title='Choose Dataset'
      description='Choose a dataset to add the output to or create a new one'>
      <DatasetList
        workspaceId={workspaceId}
        renderCreateCard={renderCreateCard}
        renderDatasetCard={renderDatasetCard}
        loadingComponent={<Spinner />}
        containerClassName={styles.fullWidth}
      />
      <div className={styles.footerWrapper}>
        <Separator className={styles.seperator} />
        <div className={styles.footer}>
          <Button variant='outlined' onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={addToDataset} disabled={!selectedTrainingSet}>
            Add to Dataset
          </Button>
        </div>
      </div>

      <AddTrainingSetModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        saveText='Create & Add'
        onComplete={createAndAddToDataset}
      />
    </Modal>
  );
};
