import { useMutation } from "@apollo/client";
import { useToastContext } from "@/context/toast-context";
import { UPLOAD_FILE } from "@/graphql/mutations/mutations";
import {
  ADD_FILE_TO_TRAINING_SET,
  CREATE_TRAINING_SET_FILE,
} from "@/graphql/mutations/ai-mutations";
import { Link } from "@/route";
import { Body } from "@/shared/v2";
import React from "react";

import styles from "@/hooks/hookStyles.module.scss";

export const useSaveReportToDataset = () => {
  const { updateToast } = useToastContext();
  const [uploadFile] = useMutation(UPLOAD_FILE, {
    onError: (error) => {
      updateToast({
        description: error.message,
        type: "failure",
      });
    },
  });
  const [createTrainingSetFileMutation] = useMutation(CREATE_TRAINING_SET_FILE);
  const [addFileToTrainingSetMutation] = useMutation(ADD_FILE_TO_TRAINING_SET);

  const getFileName = (url?: string) => {
    if (!url) return "";
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  const saveReportToDataset = async (
    trainingSetId: string,
    reportUrl?: string
  ) => {
    if (!reportUrl) {
      return;
    }

    try {
      // Download the file from reportUrl
      const response = await fetch(reportUrl);
      const blob = await response.blob();
      const fileName = getFileName(reportUrl);
      const file = new File([blob], fileName, { type: "application/pdf" });

      // Upload the file
      const uploadResult = await uploadFile({
        variables: {
          file,
        },
      });

      if (uploadResult.data) {
        const trainingSetFileData = await createTrainingSetFileMutation({
          variables: {
            trainingSetId,
            uploadItemId: uploadResult.data.upload.id,
          },
        });

        // Add file to training set
        if (trainingSetFileData.data) {
          await addFileToTrainingSetMutation({
            variables: {
              trainingSetId,
              fileId: trainingSetFileData.data.createTrainingSetFile.id,
            },
          });

          updateToast({
            description: (
              <Body size='s' color='gray-modern-25'>
                Output successfully added to dataset.{" "}
                <Link
                  type='underline'
                  className={styles.link}
                  to={`/datasets/dataset/${trainingSetId}`}>
                  You can click here to go to the dataset.
                </Link>
              </Body>
            ),
            type: "success",
            displayTime: 10000,
          });
        }
      }
    } catch (error) {
      updateToast({
        description: `Failed to add report to dataset. Please try again.`,
        type: "failure",
      });
    }
  };

  return { saveReportToDataset };
};
