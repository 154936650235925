import {AiPersonaTask} from "@/models/ai-orchestration";
import {UpdateAiPersonaTaskInput, UpdateAiPersonaTaskMutationInput} from "@/reducer/workflow-reducer";

export const updateTask = (allTasks: AiPersonaTask[], taskId, input: UpdateAiPersonaTaskInput) => {
  const task = allTasks.find((task) => task.id === taskId);
  let newTasks = [...allTasks];

  if (!task) {
    return allTasks;
  }

  newTasks = newTasks.map((task) => {
    if (task.id !== taskId) {
      return task;
    }

    let newTask = {...task};

    if (input.index !== undefined) {
      newTask.index = input.index;
    }

    if (input.smartPromptEnabled !== undefined) {
      newTask.smartPromptEnabled = input.smartPromptEnabled;
    }
    
    if (input.chatMode !== undefined) {
      newTask.chatMode = input.chatMode;
    }

    if (typeof input.taskPrompt === "string" && newTask.task) {
      newTask.task = {
        ...newTask.task,
        taskPrompt: input.taskPrompt
      }
    }

    return newTask;
  });

  return newTasks;
}
