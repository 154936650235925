import {gql} from "@apollo/client";

/**
 * Add or deduct credits from a workspace
 */
export const ADD_WORKSPACE_CREDIT = gql`
  mutation AddWorkspaceCredit($input: AddWorkspaceCreditInput!) {
    addWorkspaceCredit(input: $input) {
      id
      workspaceId
      amount
      note
      changeReason
      createdAt
    }
  }
`;

/**
 * Update credit amount and note for a single response
 */
export const UPDATE_RESPONSE_CREDIT = gql`
  mutation UpdateResponseCredit($input: UpdateResponseCreditInput!) {
    updateResponseCredit(input: $input) {
      id
      creditAmount
      creditNote
    }
  }
`;

/**
 * Update credit amount and note for multiple responses at once
 */
export const BULK_UPDATE_RESPONSE_CREDIT = gql`
  mutation BulkUpdateResponseCredit($input: BulkUpdateResponseCreditInput!) {
    bulkUpdateResponseCredit(input: $input) {
      id
      creditAmount
      creditNote
    }
  }
`;

/**
 * Batch update credits for multiple workspaces at once
 */
export const BATCH_UPDATE_WORKSPACE_CREDIT = gql`
  mutation BatchUpdateWorkspaceCredit($input: BatchUpdateWorkspaceCreditInput!) {
    batchUpdateWorkspaceCredit(input: $input) {
      id
      workspaceId
      amount
      note
      changeReason
      createdAt
    }
  }
`;
