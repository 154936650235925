import React, {ReactElement} from "react";
import {useNavigate} from "react-router";

import {AlignText} from "@/shared/typography/align-text";
import {BinDeleteIcon, DotsIcon, SparkAiStarsIcon, TagLabelIcon} from "@/icons";
import {Dropdown, DropdownItem} from "@/shared/v2";
import {isTrainingSetFile} from "@/shared";
import {TrainingSet, TrainingSetMedia} from "@/models/ai-model";

import styles from "./file-actions.module.scss";

export interface FileActionsProps {
  file: TrainingSetMedia;
  setFileForEditingLabels: (file: TrainingSetMedia) => void;
  setIsManageLabelsModalOpen: (isOpen: boolean) => void;
  setItemSelected: (file: TrainingSetMedia) => void;
}

export const FileActions = ({file, setFileForEditingLabels, setIsManageLabelsModalOpen, setItemSelected}: FileActionsProps): ReactElement => {
  const navigate = useNavigate();

  return <AlignText align="right">
    <Dropdown
      trigger={<DotsIcon className={styles.trigger} />}
      items={
        [
          {
				    color: "primary",
            label: "Conversation",
            icon: <SparkAiStarsIcon />,
            onClick: () => {
              if (file.embeddingsGenerationStatus === "SUCCESS") {
                navigate("/workflow/conversation", {state: {
                  chatConversation: {
                    chat: isTrainingSetFile(file) ? {
                      files: [file],
                    } : {
                      videos: [file],
                    }
                  }
                }});
              }
            },
            disabled: file.embeddingsGenerationStatus !== "SUCCESS",
            tooltip: file.embeddingsGenerationStatus !== "SUCCESS" ?
              "You can only start conversations once the file has been processed by the AI and has the status \"success\"." :
              undefined
          },
          {
            label: "Edit Labels",
            icon: <TagLabelIcon />,
            onClick: () => {
              setFileForEditingLabels(file);
              setIsManageLabelsModalOpen(true);
            }
          },
          {
            label: "Delete",
            onClick: () => setItemSelected(file),
            color: "danger",
            icon: <BinDeleteIcon />,
          },

        ].filter(Boolean) as DropdownItem[]
      }
      position="bottom-start"
    />
  </AlignText>
}
