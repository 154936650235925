/* eslint-disable react/prop-types */
import classNames from "classnames/bind";
import React, {Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import {
	Column,
	HeaderGroup, Row, useExpanded,
	useFlexLayout,
	useGlobalFilter,
	useGroupBy,
	useMountedLayoutEffect,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable
} from "react-table";

import {Caption, Checkbox, Spinner} from "..";
import {useThemeMode} from "../../../context/theme-mode-context";
import {ArrowDownIcon, ArrowUpIcon} from "@/icons";
import {GenericPage} from "@/models/generic";
import {NetworkStatus} from "@apollo/client";

import styles from "./table.module.scss";
import {TablePagination} from "@/shared/components/table/table-pagination";

const bStyles = classNames.bind(styles);

export interface SelectionStateRecord<T extends object & {id: string}> {
	page: T[];
	selected: T[];
}

export type PaginatedTableProps<T extends object & {id: string}> ={
	columns: Column<T>[];
	page: GenericPage<T>;
	selectedValues?: T[];
	onAddSelectedItems?: (items: T[]) => void;
	onRemoveSelectedItems?: (items: T[]) => void;
	handleFetchMore: (limit: number) => void;
	onSort?: (column: HeaderGroup<T>) => void;
	pageSize: number;
	isAllSelected?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	sortBy?: string;
	tableClassName?: string;
	onPageSizeChange?: (value: number) => void;
	networkStatus: NetworkStatus;
	fetchMoreFragment?: ReactElement;
	loadingFragment?: ReactElement;
}

const PaginatedTable = <T extends object & {id: string}>({
	columns,
	page,
	onAddSelectedItems,
	onRemoveSelectedItems,
	handleFetchMore,
	onSort,
	selectedValues,
	pageSize,
	tableClassName,
	onPageSizeChange,
	networkStatus,
	fetchMoreFragment = (
		<tr className={styles.sorting}>
			<td><Spinner /></td>
		</tr>
	),
	loadingFragment = (
		<Spinner />
	),
	...rest
}: PaginatedTableProps<T>): ReactElement => {
	const [currentPage, setCurrentPage] = useState(0);

	const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
	const isSorting = networkStatus === NetworkStatus.setVariables;
	const isIdle = networkStatus === NetworkStatus.ready;

	const {isDarkMode} = useThemeMode();

	const handleSelectionChange = useCallback((items: T[], isChecked: boolean): void => {
		isChecked ? onAddSelectedItems?.(items) : onRemoveSelectedItems?.(items);
	}, [onAddSelectedItems, onRemoveSelectedItems]);

	const handleCheck = useCallback((e: React.ChangeEvent<HTMLInputElement>, items: T[], f?: ((e: React.ChangeEvent<HTMLInputElement>) => void)): void => {
		const isChecked = e.target.checked;
		handleSelectionChange(items, isChecked);
		f?.(e);
	}, [handleSelectionChange]);

	const {items: cachedData, remaining} = page;

	const allDataLength = cachedData.length + remaining;
	const displayedData = useMemo(() => (
		cachedData.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
	), [cachedData, currentPage, pageSize]);

	/**
	 * Reset page every time user is outside of the table
	 */
	useEffect(() => {
		if (displayedData.length === 0 && networkStatus === NetworkStatus.ready) {
			setCurrentPage(0);
		}
	}, [displayedData, networkStatus]);

	const {
		getTableProps,
		headerGroups,
		rows,
		prepareRow,
		pageCount,
		toggleAllRowsSelected,
		toggleRowSelected,
		selectedFlatRows,
	} = useTable(
		{
			columns,
			manualSortBy: Boolean(onSort),
			data: displayedData,
			initialState: {
				pageSize,
				selectedRowIds: displayedData?.reduce((acc, item, index) => {
					const isSelected = selectedValues?.some(value => value.id === item.id);
					if (isSelected) {
						acc[index] = true;
					} else {
						acc[index] = false;
					}
					return acc;
				}, {} as Record<string, boolean>),
			},
			manualPagination: true,
			pageCount: Math.ceil(allDataLength / pageSize),
		},
		useFlexLayout,
		useGlobalFilter,
		useGroupBy,
		useSortBy,
		useExpanded,
		usePagination,
		useRowSelect,
		hooks => {
			if (selectedValues) {
				hooks.visibleColumns.push(allColumns => [
					{
						id: "selection",
						width: 25,
						Header: ({getToggleAllRowsSelectedProps}) => {
							const {indeterminate, ...props} = getToggleAllRowsSelectedProps();
							const {onChange, checked, ...rest} = props;
							return (
								<Checkbox
									size="s"
									checked={Boolean(checked)}
									{...rest}
									className={styles.checkbox}
									onChange={e => handleCheck(e, displayedData, onChange)}
								/>
							);
						},
						Cell: ({row}) => {
							const {indeterminate, ...props} = row.getToggleRowSelectedProps();
							const {onChange, checked, ...rest} = props;
							return (
								<Checkbox
									size="s"
									checked={Boolean(checked)}
									{...rest}
									className={styles.checkbox}
									onChange={e => handleCheck(e, [row.original], onChange)}
								/>
							);
						},
					},
					...allColumns,
				]);
			}
		},
	);

	useEffect(() => {
		const selectedIdsInTable = selectedFlatRows.map(row => row.original.id);
		const selectedIdsInSelectedValues = selectedValues?.map(value => value.id) || [];

		if (selectedIdsInTable.length > 0 && selectedIdsInSelectedValues.length === 0) {
			toggleAllRowsSelected(false);
		}

		const toToggleOn = selectedIdsInSelectedValues.filter(id => !selectedIdsInTable.includes(id));
		const toToggleOff = selectedIdsInTable.filter(id => !selectedIdsInSelectedValues.includes(id));

		toToggleOn.forEach(id => toggleRowSelected(id, true));
		toToggleOff.forEach(id => toggleRowSelected(id, false));
	}, [selectedValues, toggleAllRowsSelected, toggleRowSelected]);

	const startingRow = currentPage === 0 ? currentPage + 1 : (currentPage * pageSize) + 1;
	const endRow = ((currentPage + 1) * pageSize) > allDataLength
		? allDataLength : (currentPage + 1) * pageSize;

	const calculateDataToFetch = (nextPage: number): number => {
		const totalDataNeeded = (nextPage + 1) * pageSize;
		return Math.max(totalDataNeeded - cachedData.length, 0);
	}

	const handleNext = (): void => {
		const newPage = currentPage + 1;
		const toFetch = calculateDataToFetch(newPage);
		if (toFetch > 0) handleFetchMore(toFetch);
		setCurrentPage(newPage);
	};
	// Can't think of a time we'd need to fetch more when clicking the back arrow
	const handlePrev = (): void => {
		setCurrentPage(prev => prev - 1);
	};
	// If the new page is higher than the current, we'll need to fetch some more values
	const gotoPage = (newPage: number): void => {
		const toFetch = calculateDataToFetch(newPage);
		if (toFetch > 0) handleFetchMore(toFetch);
		setCurrentPage(newPage);
	};

	const handleSort = (c: HeaderGroup<T>): void => {
		onSort?.(c);
		c.toggleSortBy();
		setCurrentPage(0);
	};

	return (
		<div className={styles.mainContainer}>
			<div className={styles.tableContainer}>
				<table {...getTableProps()} className={bStyles("table", {tableClassName}, {isDarkMode})}>
					<thead className={styles.header}>
						{headerGroups.map((headerGroup, i) => (
							<tr {...headerGroup.getHeaderGroupProps()} key={i}>
								{headerGroup.headers.map((column, j) => (
									<th
										{...column.getHeaderProps(column.getSortByToggleProps())}
										onClick={column.canSort ? () => handleSort(column) : undefined}
										key={j}
										className={column.canSort ? styles.sortable : undefined}
									>
										<Caption type="medium" color="gray-modern-600" className={styles.tableThText}>
										{column.render("Header")}
										{column.isSorted ?
											column.isSortedDesc ?
												<ArrowDownIcon className={styles.arrowIcon} /> :
												<ArrowUpIcon className={styles.arrowIcon}/>
												: null
										}
										</Caption>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{isSorting || isFetchingMore ? fetchMoreFragment : (
							rows.map((row, i) => {
								prepareRow(row);
								return (
									<tr {...row.getRowProps()} key={i}>
										{row.cells.map((cell, j) => (
											<td {...cell.getCellProps()} key={j}>
												{cell.isGrouped ? (<>
													<span {...row.getToggleRowExpandedProps()}>
														{row.isExpanded ? "V" : ">"}
													</span>
													{cell.render("Cell")} ({row.subRows.length})
												</>
												) : cell.isAggregated ? (
													<span>
														{cell.render("Aggregated")}
													</span>
												) : cell.isPlaceholder ? null : (
													cell.render("Cell", {})
												)}
											</td>
										))}
									</tr>
								);
							})
						)}
					</tbody>
				</table>
			</div>
			<div className={styles.footer}>
				<TablePagination
					startingRow={startingRow}
					endRow={endRow}
					totalCount={allDataLength}
					currentPage={currentPage}
					pageCount={pageCount}
					handleNext={handleNext}
					handlePrev={handlePrev}
					gotoPage={gotoPage}
					disablePagination={!isIdle}
					onPageSizeChange={onPageSizeChange}
					pageSize={pageSize}
				/>
			</div>
		</div>
	);
};

export {PaginatedTable};
