import {TrainingSetMediaCriteria} from "@/models/ai-model";
import {isTrainingSetMediaEmbeddingsStatus, isTrainingSetMediaTypeAlias} from "./type-guards";

export const getTrainingSetMediaCriteriaId = (criteria: TrainingSetMediaCriteria): string => {
  if (isTrainingSetMediaEmbeddingsStatus(criteria)) {
    return criteria.embeddingsStatus;
  }

  if (isTrainingSetMediaTypeAlias(criteria)) {
    return criteria.mimetypeAlias;
  }

  return criteria.id;
}
