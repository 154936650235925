import {gql} from "@apollo/client";
import {CHAT_CONVERSATION_FRAGMENT, GROUNDING_FRAGMENT} from "../fragments/chat";
import {AI_ORCHESTRATION_FRAGMENT} from "../fragments/workflow";

export const CREATE_TRAINING_SET = gql`
  mutation CreateTrainingSet($name: String!, $description: String, $workspaceId: GUID!) {
    createTrainingSet(name: $name, description: $description, workspaceId: $workspaceId) {
      id
      name
      description
    }
  }
`;

export const TRAINING_SET_UPDATE = gql`
  mutation UpdateTrainingSet($trainingSetId: GUID!, $changes: TrainingSetChanges) {
    updateTrainingSet(trainingSetId: $trainingSetId, changes: $changes) {
      id
      alias
      description
    }
  }
`;

export const CREATE_TRAINING_SET_FILE = gql`
  mutation CreateTrainingSetFile($trainingSetId: GUID!, $uploadItemId: GUID!) {
    createTrainingSetFile(trainingSetId: $trainingSetId, uploadItemId: $uploadItemId) {
      id
      originalFilename
      originalMimetype
    }
  }
`;

export const RETRY_FAILED_FILE_TRANSCODING = gql`
  mutation RetryFailedFileTranscoding($trainingSetId: GUID!, $videoId: GUID, $fileId: GUID) {
    retryFailedFileTranscoding(trainingSetId: $trainingSetId, videoId: $videoId, fileId: $fileId) {
      ... on File {
        id
        embeddingsGenerationStatus
      }
      ... on TrainingSetVideo {
        id
        embeddingsGenerationStatus
      }
    }
  }
`;

export const CREATE_TRAINING_SET_VIDEO = gql`
  mutation CreateTrainingSetVideo($trainingSetId: GUID!, $uploadItemId: GUID!) {
    createTrainingSetVideo(trainingSetId: $trainingSetId, uploadItemId: $uploadItemId) {
      id
      originalFilename
      originalMimetype
    }
  }
`;

export const ADD_FILE_TO_TRAINING_SET = gql`
  mutation AddFileToTrainingSet($fileId: String!, $trainingSetId: GUID!) {
    addFileToTrainingSet(fileId: $fileId, trainingSetId: $trainingSetId) {
      id
      name
    }
  }
`;

export const ADD_VIDEO_TO_TRAINING_SET = gql`
  mutation AddVideoToTrainingSet($videoId: String!, $trainingSetId: GUID!) {
    addVideoToTrainingSet(videoId: $videoId, trainingSetId: $trainingSetId) {
      id
      name
    }
  }
`;

export const DELETE_TRAINING_SET_VIDEO = gql`
  mutation DeleteTrainingSetVideoV2($videoId: GUID!) {
    deleteTrainingSetVideoV2(videoId: $videoId) {
      id
    }
  }
`;

export const DELETE_TRAINING_SET_FILE = gql`
  mutation DeleteTrainingSetFileV2($fileId: GUID!) {
    deleteTrainingSetFileV2(fileId: $fileId) {
      id
    }
  }
`;

export const DELETE_TRAINING_SET = gql`
  mutation DeleteTrainingSet($trainingSetId: GUID!) {
    deleteTrainingSet(trainingSetId: $trainingSetId)
  }
`;

export const REMOVE_FILE_FROM_TRAINING_SET = gql`
  mutation RemoveFileFromTrainingSet($fileId: String!, $trainingSetId: GUID!) {
    removeFileFromTrainingSet(fileId: $fileId, trainingSetId: $trainingSetId)
  }
`;

export const REMOVE_VIDEO_FROM_TRAINING_SET = gql`
  mutation RemoveVideoFromTrainingSet($videoId: String!, $trainingSetId: GUID!) {
    removeVideoFromTrainingSet(videoId: $videoId, trainingSetId: $trainingSetId)
  }
`;

export const CHAT_CREATE_CONVERSATION = gql`
  mutation ChatCreateConversation(
    $workspaceId: GUID!
    $name: String!
    $trainingSetIds: [String!]
    $fileIds: [GUID!]
    $videoIds: [GUID!]
    $questionIds: [GUID!]
    $aiPersonaId: GUID
    $surveyIds: [GUID!]
    $isPreview: Boolean
  ) {
    chatCreateConversation(
      workspaceId: $workspaceId
      name: $name
      trainingSetIds: $trainingSetIds
      fileIds: $fileIds
      videoIds: $videoIds
      questionIds: $questionIds
      aiPersonaId: $aiPersonaId
      surveyIds: $surveyIds
      isPreview: $isPreview
    ) {
      ...ChatConversationFields
    }
  }
  ${CHAT_CONVERSATION_FRAGMENT}
`;

export const CHAT_UPDATE_CONVERSATION = gql`
  mutation ChatUpdateConversation(
    $conversationId: GUID!
    $changes: UpdateChatConversationChanges!
  ) {
    chatUpdateConversation(conversationId: $conversationId, changes: $changes) {
      ...ChatConversationFields
    }
  }

  ${CHAT_CONVERSATION_FRAGMENT}
`;

export const CHAT_SEND_QUESTION = gql`
	mutation ChatSendQuestion(
		$workspaceId: GUID!
		$question: String!
		$conversationId: GUID!
		$surveyIds: [GUID!]
		$instructions: String
		$image: String
		$documentId: GUID
		$systemAgentId: GUID
		$messageId: GUID
		$smartPromptEnabled: Boolean
		$personaId: GUID
		$chatMode: ChatMode
	) {
		chatSendQuestion(
			workspaceId: $workspaceId
			question: $question
			conversationId: $conversationId
			surveyIds: $surveyIds
			instructions: $instructions
			image: $image
			documentId: $documentId
			systemAgentId: $systemAgentId
			messageId: $messageId
			smartPromptEnabled: $smartPromptEnabled
			personaId: $personaId
			chatMode: $chatMode
		) {
			id
			content
			conversationId
			persona {
				id
				name
				description
				voiceId
				voiceName
				personaStatus
				personaCategory {
					name
				}
				picture {
					id
					url(version: THUMBNAIL)
				}
			}
			role
			type
			position
			suggestedFollowUps
			groundingData {
				...GroundingFields
			}
		}
	}
	${GROUNDING_FRAGMENT}
`;

export const CREATE_PERSONA_TASK = gql`
  mutation CreatePersonaTask($input: AiPersonaTaskInputCreate!) {
    createPersonaTask(input: $input) {
      id
      smartPromptEnabled
      chatMode
    }
  }
`;

export const UPDATE_PERSONA_TASK = gql`
  mutation UpdatePersonaTask($input: AiPersonaTaskInputUpdate!) {
    updatePersonaTask(input: $input) {
      id
      smartPromptEnabled
      chatMode
    }
  }
`;

export const DELETE_PERSONA_TASK = gql`
  mutation DeletePersonaTask($id: GUID!) {
    deletePersonaTask(id: $id) {
      id
      personaId
    }
  }
`;

export const CREATE_AI_ORCHESTRATION = gql`
	mutation CreateAiOrchestration(
		$workspaceId: String!
		$name: String!
		$description: String
		$inputParameters: String
		$instructions: String
		$outputTypeId: String
    $surveyIds: [String]
    $trainingSetIds: [String]
    $fileIds: [GUID!]
    $videoIds: [GUID!]
    $questionIds: [GUID!]
		$autoWorkflow: Boolean
		$orchestrationId: GUID
	) {
		createAiOrchestration(
			workspaceId: $workspaceId
			name: $name
			description: $description
			inputParameters: $inputParameters
			instructions: $instructions
			outputTypeId: $outputTypeId
			autoWorkflow: $autoWorkflow
			surveyIds: $surveyIds
			trainingSetIds: $trainingSetIds
      fileIds: $fileIds
      videoIds: $videoIds
      questionIds: $questionIds
			orchestrationId: $orchestrationId
		) {
			...AiOrchestrationFields
		}
	}
  ${AI_ORCHESTRATION_FRAGMENT}
`;

export const UPDATE_AI_ORCHESTRATION = gql`
  mutation UpdateAiOrchestration(
    $id: String!
    $name: String
    $description: String
    $inputParameters: String
    $instructions: String
    $outputTypeId: String
    $surveyIds: [String]
    $trainingSetIds: [String]
    $fileIds: [GUID!]
    $videoIds: [GUID!]
    $questionIds: [GUID!]
  ) {
    updateAiOrchestration(
      id: $id
      name: $name
      description: $description
      inputParameters: $inputParameters
      instructions: $instructions
      outputTypeId: $outputTypeId
      surveyIds: $surveyIds
      trainingSetIds: $trainingSetIds
      fileIds: $fileIds
      videoIds: $videoIds
      questionIds: $questionIds
    ) {
      ...AiOrchestrationFields
    }
  }
  ${AI_ORCHESTRATION_FRAGMENT}
`;

export const DELETE_AI_ORCHESTRATION = gql`
  mutation DeleteAiOrchestration($id: String!) {
    deleteAiOrchestration(id: $id) {
      id
    }
  }
`;

export const CHAT_LIKE_MESSAGE = gql`
  mutation ChatLikeMessage($messageId: GUID!) {
    chatLikeMessage(messageId: $messageId) {
      id
      likedByUsers {
        id
      }
      dislikedByUsers {
        id
      }
    }
  }
`;

export const CHAT_DISLIKE_MESSAGE = gql`
  mutation ChatDislikeMessage($messageId: GUID!) {
    chatDislikeMessage(messageId: $messageId) {
      id
      likedByUsers {
        id
      }
      dislikedByUsers {
        id
      }
    }
  }
`;

export const CHAT_REMOVE_MESSAGE_REACTION = gql`
  mutation ChatRemoveMessageReaction($messageId: GUID!) {
    chatRemoveMessageReaction(messageId: $messageId) {
      id
      likedByUsers {
        id
      }
      dislikedByUsers {
        id
      }
    }
  }
`;

export const CHAT_DELETE_MESSAGE_PAIR = gql`
  mutation ChatDeleteMessagePair($messageId: GUID!) {
    chatDeleteMessagePair(messageId: $messageId) {
      id
      content
      role
      deletedAt
    }
  }
`;

export const CHAT_DELETE_CONVERSATION = gql`
  mutation ChatDeleteConversation($id: GUID!) {
    chatDeleteConversation(id: $id)
  }
`;

export const TEST_CONVERSATION_SUBSCRIPTION = gql`
  mutation TestConversationSubscription($conversationId: GUID!) {
    testConversationSubscription(conversationId: $conversationId)
  }
`;

export const RUN_WORKFLOW = gql`
  mutation RunWorkflow($orchestrationId: GUID!) {
    runWorkflow(orchestrationId: $orchestrationId)
  }
`;

export const SET_TAGS_TO_TRAINING_SET_MEDIA = gql`
  mutation SetTagsToTrainingSetMedia($trainingSetId: GUID!, $media: TrainingSetMediaInput!, $tags: [TrainingSetMediaTagInput!]!) {
    trainingSetMediaUpdateTags(trainingSetId: $trainingSetId, media: $media, tags: $tags) {
      files {
        id
        tags {
          id
          key
          value
        }
      }
      videos {
        id
        tags {
          id
          key
          value
        }
      }
    }
  }
`;


export const FILE_TAG_KEY_CREATE_BATCH = gql`
  mutation FileTagKeyCreateBatch($workspaceId: GUID!, $values: [String!]!) {
    fileTagKeyCreateBatch(workspaceId: $workspaceId, values: $values) {
      id
      value
    }
  }
`

export const FILE_TAG_KEY_DELETE_BATCH = gql`
  mutation FileTagKeyDeleteBatch($ids: [GUID!]!) {
    fileTagKeyDeleteBatch(ids: $ids)
  }
`

export const FILE_TAG_KEY_UPDATE = gql`
  mutation FileTagKeyUpdate($id: GUID!, $value: String!) {
    fileTagKeyUpdate(id: $id, value: $value) {
      id
      value
    }
  }
`;

export const CANCEL_WORKFLOW = gql`
  mutation CancelAiOrchestration($historyId: GUID!) {
    cancelAiOrchestration(historyId: $historyId) {
      id
      status
      processState
    }
  }
`;
