import React, { ReactElement, useState } from "react";

import { AddTrainingSetModal } from "../../../datasets/modals";
import { DatasetCard } from "../../../datasets/components";
import { TrainingSet } from "../../../models/ai-model";
import { useWorkspaceContext } from "../../../context/workspace-context";
import { DatasetList } from "../../components/dataset-list";
import { CreateDatasetCard } from "@/datasets/components/create-dataset-card";

export const AllDatasetsPage = (): ReactElement => {
  const {
    workspace: { id: workspaceId },
  } = useWorkspaceContext();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const renderCreateCard = (
    <CreateDatasetCard
      onClick={() => setIsAddModalOpen(true)}
      text='Create Dataset'
    />
  );

  const renderDatasetCard = (trainingSet: TrainingSet) => (
    <DatasetCard key={trainingSet.id} trainingSet={trainingSet} />
  );

  return (
    <>
      <DatasetList
        workspaceId={workspaceId}
        renderCreateCard={renderCreateCard}
        renderDatasetCard={renderDatasetCard}
      />

      <AddTrainingSetModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      />
    </>
  );
};
