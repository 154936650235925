import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToastContext } from '../../context/toast-context';
import { CssSpinner } from '../../shared/components/css-spinner';

// Define the path to redirect back to after handling the callback
const INTEGRATIONS_PAGE_PATH = '/settings/integrations';

export const ComposioCallbackHandler: React.FC = () => {
  const [status, setStatus] = useState<'processing' | 'success' | 'error'>('processing');
  const [message, setMessage] = useState<string>('Processing your authentication...');
  const location = useLocation();
  const navigate = useNavigate();
  const { updateToast } = useToastContext();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const composioStatus = params.get('composio_status'); // Status from Composio redirect
    const msgParam = params.get('message'); // Optional message from Composio
    const toolParam = params.get('tool'); // Optional tool identifier
    const authSchemeParam = params.get('auth_scheme'); // Optional auth scheme used

    let redirectTimer: NodeJS.Timeout;

    if (composioStatus === 'success') {
      setStatus('success');
      
      // Add auth method to the success message if provided
      let authMethodText = '';
      if (authSchemeParam) {
        authMethodText = {
          'OAUTH2': ' using OAuth',
          'API_KEY': ' using API Key',
          'BEARER_TOKEN': ' using Bearer Token'
        }[authSchemeParam] || '';
      }
      
      const successMsg = `Successfully connected ${toolParam || 'service'}${authMethodText}.`;
      setMessage(successMsg);
      
      // Show toast notification
      updateToast({ 
        description: successMsg, 
        type: 'success' 
      });
    } else {
      setStatus('error');
      
      // Add auth method to the error message if provided
      let authMethodText = '';
      if (authSchemeParam) {
        authMethodText = {
          'OAUTH2': ' with OAuth',
          'API_KEY': ' with API Key',
          'BEARER_TOKEN': ' with Bearer Token'
        }[authSchemeParam] || '';
      }
      
      const errorMsg = msgParam || `Failed to connect ${toolParam || 'service'}${authMethodText}. Please try again.`;
      setMessage(errorMsg);
      
      // Show toast notification
      updateToast({ 
        description: errorMsg, 
        type: 'failure' 
      });
    }

    // Redirect back to the integrations page after a delay
    redirectTimer = setTimeout(() => {
      navigate(INTEGRATIONS_PAGE_PATH, { replace: true }); // Use replace to avoid callback URL in history
    }, 3000); // 3-second delay

    // Cleanup timer on component unmount
    return () => clearTimeout(redirectTimer);

    // Removed updateToast from dependencies to prevent infinite loop
  }, [location, navigate]);

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      {status === 'processing' && <CssSpinner text={message} />}
      {status === 'success' && (
        <div>
          <h2 style={{ color: 'green' }}>Connection Successful!</h2>
          <p>{message}</p>
          <p>Redirecting you back shortly...</p>
          {/* Optionally add a manual redirect link */}
          {/* <a href={INTEGRATIONS_PAGE_PATH}>Go back now</a> */}
        </div>
      )}
      {status === 'error' && (
        <div>
          <h2 style={{ color: 'red' }}>Connection Failed</h2>
          <p>{message}</p>
          <p>Redirecting you back shortly...</p>
          {/* <a href={INTEGRATIONS_PAGE_PATH}>Go back now</a> */}
        </div>
      )}
    </div>
  );
};

// Optional: Export default if preferred
// export default ComposioCallbackHandler;
