import classNames from "classnames/bind";
import React, { ReactElement, useEffect, useMemo, useState } from "react";

import { Avatar, Body, SearchInput, Modal } from "../../../../../shared/v2";
import { useThemeMode } from "../../../../../context/theme-mode-context";
import { useWorkspaceContext } from "../../../../../context/workspace-context";
import { Workspace } from "../../../../../models/workspace";
import { normalizeText } from "../../../../../shared/utility/normalizeText";

import styles from "./workspace-picker-modal.module.scss";

const cx = classNames.bind(styles);

export interface WorkspacePickerModalProps {
  isOpen: boolean;
  workspaces: Workspace[];
  onClose: () => void;
  onSubmit: (workspace: Workspace) => void;
}

export const WorkspacePickerModal = ({
  workspaces,
  isOpen,
  onClose,
  onSubmit,
}: WorkspacePickerModalProps): ReactElement => {
  const { workspace: currentWorkspace } = useWorkspaceContext();
  const [query, setQuery] = useState<string>("");
  const [activeWorkspace, setActiveWorkspace] = useState<Workspace | null>(
    currentWorkspace
  );
  const { isDarkMode } = useThemeMode();

  useEffect(() => {
    setActiveWorkspace(currentWorkspace);
  }, [currentWorkspace]);

  const handleClose = () => {
    setActiveWorkspace(currentWorkspace);
    setQuery("");
    onClose();
  };

  const filteredWorkspaces = useMemo(() => {
    const normalizedQuery = normalizeText(query);
    return workspaces.filter((workspace) =>
      normalizeText(workspace.name).includes(normalizedQuery)
    );
  }, [workspaces, query]);

  return (
    <Modal
      title='Switch workspace'
      isOpen={isOpen}
      onClose={handleClose}
      className={cx("modal", { isDarkMode })}>
      <SearchInput
        className={styles.input}
        value={query}
        onChange={setQuery}
        autoFocus={true}
      />

      <div className={styles.workspacesList}>
        {filteredWorkspaces.map((workspace) => (
          <div key={workspace.id} className={styles.workspaceContainer}>
            <button
              className={cx("workspace", {
                isActive: activeWorkspace?.id === workspace.id,
              })}
              onClick={() => {
                onSubmit(workspace);
                handleClose();
              }}>
              <Avatar
                size='xs'
                firstName={workspace.name}
                url={workspace.logo?.badge || undefined}
              />
              <Body color='text-secondary'>{workspace.name}</Body>
            </button>
          </div>
        ))}
      </div>
    </Modal>
  );
};
