import React, { ReactElement } from "react";

import { Button } from "../../../shared/v2";
import { useWorkspaceContext } from "../../../context/workspace-context";
import { GET_CHAT_CONVERSATIONS_WITH_PREVIEW } from "../../../graphql/queries/ai-models-queries";
import { ChatConversation } from "../../../models/ai-model";
import { useNavigate } from "../../../route";
import { PlusSmallIcon } from "../../../icons";
import { CreateCard } from "../../../shared/v2/cards";
import { ConversationCard } from "./conversation-card";
import { ConversationsSort } from "./conversations-sort";
import { PaginatedList } from "@/shared/v2/paginated-list";

import styles from "./conversations-page.module.scss";

export const ConversationsPage = (): ReactElement => {
  const {
    workspace: { id: workspaceId },
  } = useWorkspaceContext();
  const navigate = useNavigate();

  const startNewChat = (): void => {
    navigate("/workflow/conversation");
  };

  return (
    <PaginatedList<ChatConversation>
      query={GET_CHAT_CONVERSATIONS_WITH_PREVIEW}
      queryName='paginatedChatConversations'
      workspaceId={workspaceId}
      gridClassName={styles.canvasList}
      renderCreateCard={
        <CreateCard wrapperClassname={styles.cardWrapper}>
          <Button leftIcon={<PlusSmallIcon />} onClick={startNewChat}>
            Create new
          </Button>
        </CreateCard>
      }
      renderItem={(conversation) => (
        <ConversationCard key={conversation.id} conversation={conversation} />
      )}
      renderSortComponent={({ value, onChange, className }) => (
        <ConversationsSort
          value={value}
          onChange={onChange}
          className={className}
        />
      )}
      containerClassName={styles.fullWidth}
      searchPlaceholder='Search conversations'
    />
  );
};
