import extend from "extend";

export interface Config {
  apiHost: string;
  pubsubHost: string;
  responder: string;
  creatorHost: string;
  logRocketEnvironmentId: string;
  bugsnagApiKey: string;
  bugsnagReleaseStage: string;
  embedHost: string;
  intercomAppId: string;
  featureToggles: {
    smartPrompt: boolean;
    chatDocuments: boolean;
    recruit: boolean;
  };
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  googleApiConfig: {
    clientId: string;
    developerKey: string;
  };
}

export type Environment = "local" | "test" | "staging" | "prod";

const base: Partial<Config> = {
  apiHost: "https://localhost:3000",
  pubsubHost: "wss://localhost:3000",
  responder: "http://localhost:4040",
  creatorHost: "https://localhost:4080",
  logRocketEnvironmentId: "vurvey/vurvey-builder-dev",
  bugsnagApiKey: "e8199269f3e32d96ca87b245672afba6",
  bugsnagReleaseStage: "development",
  embedHost: "vurvey-development-public",
  intercomAppId: "ismzpdxk",
  featureToggles: {
    smartPrompt: true,
    chatDocuments: true,
    recruit: true,
  },
  firebaseConfig: {
    apiKey: "AIzaSyBo3WI_YKn6yr0H6Dl_8me9N9u6_Wciskg",
    authDomain: "auth.vurvey.dev",
    projectId: "fir-auth-cdeac",
    storageBucket: "fir-auth-cdeac.appspot.com",
    messagingSenderId: "1046730303135",
    appId: "1:1046730303135:web:d0c03cca3e99bba26564aa",
    measurementId: "G-KSMR3GDJS3",
  },
  googleApiConfig: {
    clientId: "1046730303135-ifcl5dt71a2eljri85hu091fhb8i0s2n.apps.googleusercontent.com",
    developerKey: "AIzaSyBo3WI_YKn6yr0H6Dl_8me9N9u6_Wciskg",
  },
};

const envs: {[key in Environment]: Partial<Config>} = {
  local: {
  },
  test: {
    apiHost: "https://api2.vurvey.dev",
    pubsubHost: "wss://api2.vurvey.dev",
    responder: "https://respond.vurvey.dev",
    creatorHost: "https://creator.vurvey.dev",
    bugsnagReleaseStage: "testing",
    embedHost: "vurvey-development-public",
  },
  staging: {
    apiHost: "https://api-staging.vurvey.dev",
    pubsubHost: "wss://api-staging.vurvey.dev",
    responder: "https://respond.staging.vurvey.dev",
    creatorHost: "https://creator.staging.vurvey.dev/",
    bugsnagReleaseStage: "staging",
    embedHost: "vurvey-development-public",
    featureToggles: {
      smartPrompt: true,
      chatDocuments: true,
      recruit: true,
    },
  },
  prod: {
    apiHost: "https://api.vurvey.app",
    pubsubHost: "wss://api.vurvey.app",
    responder: "https://respond.vurvey.app",
    creatorHost: "https://vurveyreviews.com",
    logRocketEnvironmentId: "vurvey/vurvey-builder-prod",
    bugsnagReleaseStage: "production",
    embedHost: "vurvey-production-public",
    intercomAppId: "ismzpdxk",
    featureToggles: {
      smartPrompt: false,
      chatDocuments: false,
      recruit: false,
    },
    firebaseConfig: {
      apiKey: "AIzaSyAq2XOtJ4KBoxSUeQ8pQLRUNq2NDr2fO0U",
      authDomain: "auth.vurvey.app",
      projectId: "fir-prod-290cd",
      storageBucket: "fir-prod-290cd.appspot.com",
      messagingSenderId: "536194458678",
      appId: "1:536194458678:web:89f091633750ced8886497",
      measurementId: "G-NCMJ36GX6C",
    },
    googleApiConfig: {
      clientId: "536194458678-cl34ig1a8d6ckk7e9j9155ihmo3u2ev1.apps.googleusercontent.com",
      developerKey: "AIzaSyAq2XOtJ4KBoxSUeQ8pQLRUNq2NDr2fO0U",
    },
  },
};

const env = envs[import.meta.env.REACT_APP_ENV || "local"];
const config: Config = extend({}, base, env);
export default config;
export {config};
