import React, {ReactElement} from "react";
import {Route, Routes} from "react-router-dom";

import {AccountPage, PersonalProfile, TOS} from "./account/containers";
import {
	AdminPage,
	AgentsAdminPage,
	BrandManagement,
	CampaignTemplateManagement,
	MetabaseDashboard,
	SkillsPage,
	WorkspaceManagement
} from "./admin/containers";
import {AgentsPage, AssistantsPage} from "./agents/containers";
import {AppErrorBoundary} from "./error-boundaries";
import {MagicSegmentsPage} from "./audience/containers";
import {BrandingNavigation, BrandQuestions, BrandReels, BrandSettingsPage, ReviewsPage} from "./branding/containers";
import {CampaignsPage, MagicReelsPage, TemplatePage, UsagePage} from "./campaigns/containers";
import {CanvasPage} from "./canvas";
import {
	AttributeValuePage,
	ContactsPage,
	CRMLandingPage,
	ListAndSegments,
	PropertiesPage,
} from "./contacts/containers/";
import {ThemeModeProvider} from "./context/theme-mode-context";
import {ToastContextProvider} from "./context/toast-context";
import {UserContextProvider} from "./context/user-context";
import {WorkspaceContextProvider} from "./context/workspace-context";
import {
	DatasetsPage,
	AllDatasetsPage,
	MagicSummariesPage,
	DatasetPage
} from "./datasets/containers";
import {Layout} from "./hoc/layout/layout";
import {useAuthentication} from "./hooks/user/useAuthentication";
import {MagicTopicsPage, MentionsPage} from "./mentions/containers";
import {ReelPage} from "./reels/containers";
import {RewardsPage} from "./rewards/containers/rewards";
import {SharedReelPage} from "./shared-reel";
import {SharedSummary} from "./shared-summary";
import {
	OutputsPage,
	WorkflowPage,
	FlowsPage,
	ConversationsPage,
	FlowPage,
} from "./workflow/containers";
import {SurveyDashboard, SurveyPage} from "./survey/containers";
import {AiModelsPage, GeneralSettings, WorkspaceMembersPage, WorkspaceSettings, ManageKeys} from "./workspace-settings/containers";
import {AgentsCreatePage} from "./agents/containers/create-page";
import {AgentCreationContextProvider} from "./context/agent-creation-context";
import {PortalContextProvider} from "./context/portal-context";
import {ViewportPortal} from "./portals/viewport-portal";
import {LoadingPage} from "./shared/v2";
import {Login} from "./login";
import {ChatContextsAndHandlers} from "./canvas/chat-contexts-and-handlers";
import {SignUpModalContextProvider} from "./context/sign-up-modal.context";
import {FlowBuilder} from "./workflow/containers/flow-page/flow-builder";
import {FlowHistory} from "./workflow/containers/flow-page/flow-history";
import {HashRedirect} from "./route/hashRedirect";
import {RecruitPage} from "./campaign/containers/Recruit";
import {SSOProvidersPage} from "./admin/containers/sso-providers-page";
// Import the new integration components
import { ComposioCallbackHandler } from "./integrations/components/ComposioCallbackHandler";
import { IntegrationManagementPage } from "./integrations/components/IntegrationManagementPage";


const App = (): ReactElement => {
	const {user, loading} = useAuthentication();

	// When firebaseUser is not loaded yet, show loading page
	let content = <LoadingPage />

	// APP routes for unauthenticated users
	if (!loading && !user) {
		content = <Routes>
			<Route path="share/:reelId" element={<SharedReelPage />} />
			<Route path="summary/:surveyId" element={<SharedSummary />} />
			<Route path="*" element={<SignUpModalContextProvider><Login /></SignUpModalContextProvider>} />
		</Routes>
	}

	// APP routes for authenticated users
	if (user?.email) {
		content = <UserContextProvider>
			<Routes>
				{/* Add the callback route here, outside the main layout but within user context */}
				<Route path="/integrations/callback/composio" element={<ComposioCallbackHandler />} />

				<Route path="share/:reelId" element={<SharedReelPage />} />
				<Route path="summary/:surveyId" element={<SharedSummary />} />
				<Route
					path="/"
					element={
						<WorkspaceContextProvider>
							<Layout />
						</WorkspaceContextProvider>
					}
				>
					<Route path="survey/:surveyId/*" element={<SurveyPage />} />
					<Route path="survey/:surveyId/recruit" element={<RecruitPage />} />
					<Route path="branding/*" element={<BrandingNavigation />}>
						<Route path="" element={<BrandSettingsPage />} />
						<Route path="reviews" element={<ReviewsPage />} />
						<Route path="reels" element={<BrandReels />} />
						<Route path = "questions" element={<BrandQuestions />} />
					</Route>

					<Route path="agents" element={<AgentsPage />}>
						<Route path="" element={<AssistantsPage />} />
					</Route>

					<Route path="agents/builder" element={
						<AgentCreationContextProvider>
							<AgentsCreatePage />
						</AgentCreationContextProvider>
					} />

					<Route path="mentions" element={<MentionsPage />}>
						<Route path="" element={<ReviewsPage />} />
						<Route path="magic-topics" element={<MagicTopicsPage />} />
					</Route>

					<Route path="datasets" element={<DatasetsPage />}>
						<Route path="" element={<AllDatasetsPage />} />
						<Route path="magic-summaries" element={<MagicSummariesPage />} />
					</Route>

					<Route path="datasets/dataset/:trainingSetId" element={<DatasetPage />} />

					<Route path="audience" element={<CRMLandingPage />}>
						<Route path="" element={<ContactsPage />} />
						<Route path="lists" element={<ListAndSegments />} />
						<Route path="lists/:segmentId" element={<ContactsPage isSegment/>} />
						<Route path="segment/:segmentId" element={<ContactsPage isDynamicSegment/>} />
						<Route path="properties" element={<PropertiesPage />} />
						<Route path="properties/:attrId" element={<AttributeValuePage />} />
						<Route path="magic-segments" element={<MagicSegmentsPage />} />
					</Route>

					<Route path="workflow" element={<WorkflowPage />}>
						<Route path="flows" element={<FlowsPage />} />
						<Route path="conversations" element={<ConversationsPage />} />
						<Route path="outputs" element={<OutputsPage />} />
					</Route>
					<Route path="workflow/conversation" element={
						<ChatContextsAndHandlers>
							<CanvasPage />
						</ChatContextsAndHandlers>
					} />


					<Route path="workflow/flows/:workflowId" element={<FlowPage />}>
						<Route path="" element={<FlowBuilder />} />
						<Route path="history" element={<FlowHistory />}/>
					</Route>

					<Route path="campaigns" element={<CampaignsPage />}>
						<Route path="" element={<SurveyDashboard />} />
						<Route path="templates" element={<TemplatePage />} />
						<Route path="usage" element={<UsagePage />} />
						<Route path="magic-reels" element={<MagicReelsPage />} />
					</Route>

					<Route index element={
						<ChatContextsAndHandlers>
							<CanvasPage />
						</ChatContextsAndHandlers>
					} />

					<Route path="rewards" element={<RewardsPage />} />
					<Route path="me" element={<AccountPage />}>
						<Route path="" element={<PersonalProfile />} />
						<Route path="terms-of-service" element={<TOS />} />
					</Route>
					<Route path="admin" element={<AdminPage />}>
						<Route path="" element={<MetabaseDashboard />} />
						<Route path="workspaces" element={<WorkspaceManagement />} />
						<Route path="brands" element={<BrandManagement />} />
						<Route
							path="campaign-templates"
							element={<CampaignTemplateManagement />}
						/>
						<Route path="tags" element={<SkillsPage />} />
						<Route path="agents" element={<AgentsAdminPage />} />
						<Route path="sso-providers" element={<SSOProvidersPage />} />
					</Route>
					<Route path="workspace">
						<Route path="members" element={<WorkspaceMembersPage />} />
						<Route path="keys" element={<ManageKeys />} />
						<Route path="settings" element={<WorkspaceSettings />}>
							<Route path="" element={<GeneralSettings />} />
							<Route path="ai-models" element={<AiModelsPage />} />
						</Route>
					</Route>
					{/* Add route for the Integrations Management Page */}
					<Route path="settings/integrations" element={<IntegrationManagementPage />} />
					<Route path="reel/:reelId" element={<ReelPage />} />
				</Route>
			</Routes>
		</UserContextProvider>
	}

	return  (
		<ThemeModeProvider>
			<AppErrorBoundary>
				<ToastContextProvider>
					<PortalContextProvider>
						<ViewportPortal />
						<HashRedirect />
						{content}
					</PortalContextProvider>
				</ToastContextProvider>
			</AppErrorBoundary>
		</ThemeModeProvider>
	)
};

export {App};
