import { gql } from '@apollo/client';

// Query to get the list of tools supported by the Composio integration
export const GET_SUPPORTED_TOOLS = gql`
  query GetSupportedTools {
    composioSupportedTools {
      id
      name
      description
      logoUrl
      categories # Added categories
    }
  }
`;

// Query to get the current user's active Composio connections
export const GET_USER_CONNECTIONS = gql`
  query GetUserConnections {
    composioConnections {
      id # This is the Vurvey DB ID, needed for deletion
      connectionId # This is the Composio Connection ID
      toolId
      toolName
      toolLogoUrl
      status
      authScheme # Authentication method used (OAUTH2, API_KEY, BEARER_TOKEN)
      createdAt
    }
  }
`;

// Mutation to get the connect URL for a specific tool with optional auth scheme
export const GET_CONNECT_URL = gql`
  mutation GetConnectUrl($toolId: String!, $authScheme: ComposioAuthScheme) { # Changed String to ComposioAuthScheme
    composioGetConnectUrl(toolId: $toolId, authScheme: $authScheme)
  }
`;

// Mutation to delete/disconnect a specific Composio connection
export const DELETE_CONNECTION = gql`
  mutation DeleteConnection($connectionId: String!) {
    # Note: We use the Vurvey DB ID (returned as 'id' from GET_USER_CONNECTIONS)
    # for deletion, not the Composio connectionId.
    # The backend resolver handles mapping this Vurvey ID to the correct Composio connection.
    composioDeleteConnection(connectionId: $connectionId)
  }
`;
