import React, {ReactElement, useState, useEffect, useMemo} from 'react';
import {useQuery} from '@apollo/client';
import {format, subMonths} from 'date-fns';
import {
  Card,
  LoadingContainer,
  Button,
  Input
} from '../../../shared/v2';
import {DatetimeInput} from '../../../shared';
import {GET_WORKSPACE_CREDITS, GET_WORKSPACE_CREDIT_BALANCE} from '../../../graphql/queries/credit-queries';
import {GET_ALL_SURVEY_NAMES} from '../../../graphql/queries/survey-queries';
import {useWorkspaceContext} from '../../../context/workspace-context';
import {WorkspaceCredit} from '../../../models/workspace';
import styles from './credit-usage.module.scss';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend} from 'recharts';

interface CreditUsageProps {
  className?: string;
}

// Define sort direction type
type SortDirection = 'asc' | 'desc';

// Define sort field type
type SortField = 'date' | 'amount' | 'note' | 'user' | 'campaign';

// Define filter state type
interface FilterState {
  date: string;
  amount: string;
  note: string;
  user: string;
  campaign: string;
}

// Helper function to prepare data for the chart
function prepareChartData(credits: WorkspaceCredit[] | undefined | null) {
  // Ensure credits is defined and has items
  const creditItems = credits || [];
  if (!creditItems.length) return [];
  
  // Sort credits by date
  const sortedCredits = [...creditItems].sort((a, b) => 
    new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );
  
  // For large datasets, we need to ensure the performance remains good
  const maxDataPoints = 500; // Limit the number of data points for performance
  let dataPoints = sortedCredits;
  
  if (sortedCredits.length > maxDataPoints) {
    // If there are too many data points, sample them
    // But always keep the first and last points
    const step = Math.floor(sortedCredits.length / maxDataPoints);
    const firstItem = sortedCredits[0];
    const lastItem = sortedCredits[sortedCredits.length - 1];
    
    // Sample the data, prioritizing larger transactions
    dataPoints = [firstItem];
    
    // Sort by absolute amount to prioritize larger transactions
    const middleItems = sortedCredits.slice(1, sortedCredits.length - 1)
      .sort((a, b) => Math.abs(b.amount) - Math.abs(a.amount));
    
    // Take the top transactions by amount
    dataPoints = dataPoints.concat(middleItems.slice(0, maxDataPoints - 2));
    
    // Add the last item
    if (dataPoints[dataPoints.length - 1].id !== lastItem.id) {
      dataPoints.push(lastItem);
    }
    
    // Re-sort by date
    dataPoints.sort((a, b) => 
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
  }
  
  // Create a data point for each transaction with running balance
  // First calculate the initial balance by summing all credits before the first displayed point
  let initialBalance = 0;
  const firstDisplayedTime = new Date(dataPoints[0].createdAt).getTime();
  
  sortedCredits.forEach(credit => {
    const creditTime = new Date(credit.createdAt).getTime();
    if (creditTime < firstDisplayedTime) {
      initialBalance += credit.amount;
    }
  });
  
  let runningBalance = initialBalance;
  
  return dataPoints.map(credit => {
    // Update running balance
    runningBalance += credit.amount;
    
    // Format the date/time for display
    const dateStr = format(new Date(credit.createdAt), 'MMM d, yyyy h:mm a');
    const userName = credit.user ? `${credit.user.firstName} ${credit.user.lastName}` : 'System';
    
    return {
      date: dateStr,
      timestamp: new Date(credit.createdAt).getTime(),
      amount: credit.amount,
      balance: runningBalance,
      id: credit.id,
      note: credit.note,
      user: userName,
      transactionDetails: [{
        id: credit.id,
        amount: credit.amount,
        note: credit.note,
        user: userName,
        time: format(new Date(credit.createdAt), 'h:mm a')
      }]
    };
  });
}

export const CreditUsage = ({className}: CreditUsageProps): ReactElement => {
  // Context
  const {workspace} = useWorkspaceContext();
  const workspaceId = workspace?.id;
  
  // Date range state
  const [startDate, setStartDate] = useState<Date | undefined>(subMonths(new Date(), 3));
  const [endDate, setEndDate] = useState<Date | undefined>(new Date());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  // Sorting and filtering state
  const [sortField, setSortField] = useState<SortField>('date');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [filters, setFilters] = useState<FilterState>({
    date: '',
    amount: '',
    note: '',
    user: '',
    campaign: ''
  });
  
  // Survey name mapping state
  const [surveyNameMap, setSurveyNameMap] = useState<Record<string, string>>({});

  // GraphQL queries
  const balanceQuery = useQuery(GET_WORKSPACE_CREDIT_BALANCE, {
    variables: {workspaceId},
    skip: !workspaceId,
    fetchPolicy: 'network-only', // Always fetch from network to ensure up-to-date balance
    nextFetchPolicy: 'network-only', // Ensure subsequent fetches also bypass cache
  });

  const creditsQuery = useQuery(GET_WORKSPACE_CREDITS, {
    variables: {
      workspaceId,
      filter: {
        startDate: startDate ?? undefined,
        endDate: endDate ?? undefined,
      },
      limit: 100,
    },
    skip: !workspaceId,
  });
  
  const surveyQuery = useQuery(GET_ALL_SURVEY_NAMES, {
    variables: {workspaceId},
    skip: !workspaceId,
  });
  
  // Extract data and loading states
  const balanceData = balanceQuery.data;
  const creditsData = creditsQuery.data;
  const surveyData = surveyQuery.data;
  const balanceLoading = balanceQuery.loading;
  const creditsLoading = creditsQuery.loading;
  const surveyLoading = surveyQuery.loading;
  
  // Create a mapping of survey IDs to survey names
  useEffect(() => {
    if (surveyData?.workspaceSurveys?.items) {
      const nameMap: Record<string, string> = {};
      surveyData.workspaceSurveys.items.forEach((survey: any) => {
        nameMap[survey.id] = survey.name;
      });
      setSurveyNameMap(nameMap);
    }
  }, [surveyData]);

  // Set default date range on initial load
  useEffect(() => {
    if (workspaceId && !startDate) {
      setStartDate(subMonths(new Date(), 3));
      setEndDate(new Date());
    }
  }, [workspaceId, startDate]);

  // Extract credits and balance
  const credits = useMemo(() => creditsData?.workspaceCredits?.items || [], [creditsData]);
  const balance = useMemo(() => balanceData?.workspaceCreditBalance || 0, [balanceData]);

  // Apply sorting and filtering to credits
  const filteredAndSortedCredits = useMemo(() => {
    // First apply filters
    let result = [...credits];
    
    if (filters.date) {
      const lowerCaseFilter = filters.date.toLowerCase();
      result = result.filter(credit => 
        format(new Date(credit.createdAt), 'MMM d, yyyy h:mm a').toLowerCase().includes(lowerCaseFilter)
      );
    }
    
    if (filters.amount) {
      const lowerCaseFilter = filters.amount.toLowerCase();
      result = result.filter(credit => 
        credit.amount.toString().toLowerCase().includes(lowerCaseFilter)
      );
    }
    
    if (filters.note) {
      const lowerCaseFilter = filters.note.toLowerCase();
      result = result.filter(credit => 
        (credit.note || '').toLowerCase().includes(lowerCaseFilter)
      );
    }
    
    if (filters.user) {
      const lowerCaseFilter = filters.user.toLowerCase();
      result = result.filter(credit => 
        credit.user ? 
          `${credit.user.firstName} ${credit.user.lastName}`.toLowerCase().includes(lowerCaseFilter) : 
          false
      );
    }
    
    if (filters.campaign) {
      const lowerCaseFilter = filters.campaign.toLowerCase();
      result = result.filter(credit => 
        credit.response?.surveyId ? 
          `${surveyNameMap[credit.response.surveyId] || 'Campaign'} (${credit.response.surveyId})`.toLowerCase().includes(lowerCaseFilter) : 
          false
      );
    }
    
    // Then apply sorting
    result.sort((a, b) => {
      let comparison = 0;
      
      switch (sortField) {
        case 'date':
          comparison = new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
          break;
        case 'amount':
          comparison = a.amount - b.amount;
          break;
        case 'note':
          comparison = (a.note || '').localeCompare(b.note || '');
          break;
        case 'user':
          const aUser = a.user ? `${a.user.firstName} ${a.user.lastName}` : '';
          const bUser = b.user ? `${b.user.firstName} ${b.user.lastName}` : '';
          comparison = aUser.localeCompare(bUser);
          break;
        case 'campaign':
          const aCampaign = a.response?.surveyId ? 
            `${surveyNameMap[a.response.surveyId] || 'Campaign'} (${a.response.surveyId})` : '';
          const bCampaign = b.response?.surveyId ? 
            `${surveyNameMap[b.response.surveyId] || 'Campaign'} (${b.response.surveyId})` : '';
          comparison = aCampaign.localeCompare(bCampaign);
          break;
      }
      
      return sortDirection === 'asc' ? comparison : -comparison;
    });
    
    return result;
  }, [credits, filters, sortField, sortDirection, surveyNameMap]);

  // Calculate total credits used in the selected period
  const totalCreditsUsed = useMemo(() => {
    if (!credits.length) return 0;
    
    // Sum all credit amounts (negative values are credits used)
    const totalUsed = credits.reduce((total, credit) => {
      // Only count negative values (credits used)
      return credit.amount < 0 ? total + Math.abs(credit.amount) : total;
    }, 0);
    
    return totalUsed;
  }, [credits]);

  // Prepare data for chart
  const chartData = useMemo(() => prepareChartData(credits), [credits]);

  // Handle applying date filter
  const handleApplyFilter = async () => {
    if (!workspaceId) return;
    
    setIsLoading(true);
    try {
      await creditsQuery.refetch({
        workspaceId,
        filter: {
          startDate: startDate ?? undefined,
          endDate: endDate ?? undefined,
        },
        limit: 100,
      });
    } catch (error) {
      console.error('Error fetching credit data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle resetting date filter
  const handleResetFilter = () => {
    setStartDate(subMonths(new Date(), 3));
    setEndDate(new Date());
    handleApplyFilter();
  };

  // Handle sorting
  const handleSort = (field: SortField) => {
    if (sortField === field) {
      // Toggle direction if clicking the same field
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Set new field and default to descending
      setSortField(field);
      setSortDirection('desc');
    }
  };

  // Handle filter change
  const handleFilterChange = (field: keyof FilterState, value: any) => {
    // Check if value is an event or a string
    const newValue = typeof value === 'object' && value.target ? value.target.value : value;
    
    setFilters(prev => ({
      ...prev,
      [field]: newValue
    }));
  };

  // Reset all filters
  const resetFilters = () => {
    setFilters({
      date: '',
      amount: '',
      note: '',
      user: '',
      campaign: ''
    });
    setSortField('date');
    setSortDirection('desc');
  };

  // Show loading state
  if (balanceLoading || creditsLoading || surveyLoading || isLoading) {
    return <LoadingContainer />;
  }

  // Render component
  return (
    <Card className={`${styles.container} ${className}`}>
      <div className={styles.header}>
        <h2>Credit Usage</h2>
        <div className={styles.balanceContainer}>
          <div className={styles.balance}>
            <span className={styles.balanceLabel}>Current Balance:</span>
            <span className={styles.balanceValue}>{balance.toLocaleString()} credits</span>
          </div>
          <div className={styles.totalUsed}>
            <span className={styles.totalUsedLabel}>Credits Used (during selected period):</span>
            <span className={styles.totalUsedValue}>{totalCreditsUsed.toLocaleString()} credits</span>
          </div>
        </div>
      </div>

      <div className={styles.dateFilters}>
        <div className={styles.dateFilter}>
          <label>Start Date:</label>
          <DatetimeInput
            value={startDate}
            onChange={(value) => setStartDate(value ? new Date(value) : undefined)}
            className={styles.dateInput}
          />
        </div>
        <div className={styles.dateFilter}>
          <label>End Date:</label>
          <DatetimeInput
            value={endDate}
            onChange={(value) => setEndDate(value ? new Date(value) : undefined)}
            className={styles.dateInput}
          />
        </div>
        <div className={styles.filterActions}>
          <Button 
            onClick={handleApplyFilter}
            variant="filled"
            size="small"
          >
            Apply Filter
          </Button>
          <Button 
            onClick={handleResetFilter}
            variant="outlined"
            size="small"
          >
            Reset
          </Button>
        </div>
      </div>

      {chartData.length > 0 ? (
        <div className={styles.chart}>
          <h3>Credit Activity</h3>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={chartData}
              margin={{
                top: 10,
                right: 30,
                left: 20,
                bottom: 10,
              }}
            >
              <CartesianGrid 
                strokeDasharray="3 3" 
                stroke="var(--batterii-grid-color, #f0f0f0)" 
              />
              <XAxis 
                dataKey="timestamp" 
                type="number"
                domain={['dataMin', 'dataMax']}
                tickFormatter={(timestamp) => {
                  const date = new Date(timestamp);
                  return format(date, 'MM/dd h:mm a');
                }}
                tick={{ 
                  fill: 'var(--batterii-chart-text, #666)', 
                  fontSize: 14 
                }}
                tickLine={{ stroke: 'var(--batterii-chart-line, #ccc)' }}
                axisLine={{ stroke: 'var(--batterii-chart-line, #ccc)' }}
                scale="time"
                minTickGap={50}
              />
              <YAxis 
                tick={{ 
                  fill: 'var(--batterii-chart-text, #666)', 
                  fontSize: 14 
                }}
                tickLine={{ stroke: 'var(--batterii-chart-line, #ccc)' }}
                axisLine={{ stroke: 'var(--batterii-chart-line, #ccc)' }}
                tickFormatter={(value) => value.toFixed(0)}
              />
              <Tooltip 
                contentStyle={{ 
                  backgroundColor: 'var(--batterii-tooltip-bg, rgba(255, 255, 255, 0.95))',
                  border: '1px solid var(--batterii-border-color, #ddd)',
                  borderRadius: '4px',
                  boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                  color: 'var(--batterii-text, #333)',
                  fontSize: '14px'
                }}
                content={({ active, payload, label }) => {
                  if (!active || !payload || !payload.length) return null;
                  
                  const data = payload[0].payload;
                  const date = new Date(data.timestamp);
                  
                  return (
                    <div className={styles.customTooltip}>
                      <div className={styles.tooltipHeader}>
                        <strong>{format(date, 'MMM d, yyyy h:mm:ss a')}</strong>
                      </div>
                      <div className={styles.tooltipSummary}>
                        <div>Credit Change: {data.amount} credits</div>
                        <div>Running Balance: {data.balance} credits</div>
                      </div>
                      <div className={styles.transactionDetails}>
                        <div className={styles.transaction}>
                          <div className={data.amount >= 0 ? styles.positive : styles.negative}>
                            {data.amount >= 0 ? '+' : ''}{data.amount} credits
                          </div>
                          {data.note && <div className={styles.transactionNote}>{data.note}</div>}
                          <div className={styles.transactionMeta}>
                            By: {data.user}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
              <Legend 
                verticalAlign="top" 
                height={36} 
                wrapperStyle={{ 
                  color: 'var(--batterii-text, #333)',
                  fontSize: '16px',
                  fontWeight: 500
                }}
                formatter={(value) => {
                  if (value === 'Positive Transaction') return 'Credit (Add)';
                  if (value === 'Negative Transaction') return 'Debit (Subtract)';
                  return value;
                }}
              />
              <Line
                type="monotone"
                dataKey="balance"
                stroke="var(--batterii-blue, #0056b3)"
                strokeWidth={2}
                dot={{ 
                  stroke: 'var(--batterii-blue, #0056b3)', 
                  strokeWidth: 2, 
                  r: 3, 
                  fill: 'var(--batterii-card-bg, white)' 
                }}
                activeDot={{ 
                  r: 5, 
                  stroke: 'var(--batterii-blue, #0056b3)', 
                  strokeWidth: 2, 
                  fill: 'var(--batterii-card-bg, white)' 
                }}
                name="Balance"
                connectNulls={true}
              />
              <Line
                type="monotone"
                data={chartData.filter(item => item.amount >= 0)}
                dataKey="amount"
                stroke="transparent"
                strokeWidth={0}
                dot={{ 
                  stroke: 'var(--batterii-green, #28a745)', 
                  strokeWidth: 2, 
                  r: 4, 
                  fill: 'var(--batterii-green, #28a745)' 
                }}
                activeDot={{ 
                  r: 8, 
                  stroke: 'var(--batterii-green, #28a745)', 
                  strokeWidth: 2, 
                  fill: 'var(--batterii-card-bg, white)' 
                }}
                name="Positive Transaction"
                isAnimationActive={false}
              />
              <Line
                type="monotone"
                data={chartData.filter(item => item.amount < 0)}
                dataKey="amount"
                stroke="transparent"
                strokeWidth={0}
                dot={{ 
                  stroke: 'var(--batterii-red, #dc3545)', 
                  strokeWidth: 2, 
                  r: 4, 
                  fill: 'var(--batterii-red, #dc3545)' 
                }}
                activeDot={{ 
                  r: 8, 
                  stroke: 'var(--batterii-red, #dc3545)', 
                  strokeWidth: 2, 
                  fill: 'var(--batterii-card-bg, white)' 
                }}
                name="Negative Transaction"
                isAnimationActive={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div className={styles.noData}>
          <p>No credit usage data available for the selected date range.</p>
        </div>
      )}

      <div className={styles.tableContainer}>
        <h3>Credit Transactions</h3>
        {credits.length > 0 ? (
          <>
            <div className={styles.tableFilters}>
              <input
                className={styles.filterInput}
                placeholder="Filter by date..."
                value={filters.date}
                onChange={(e) => handleFilterChange('date', e)}
              />
              <input
                className={styles.filterInput}
                placeholder="Filter by amount..."
                value={filters.amount}
                onChange={(e) => handleFilterChange('amount', e)}
              />
              <input
                className={styles.filterInput}
                placeholder="Filter by note..."
                value={filters.note}
                onChange={(e) => handleFilterChange('note', e)}
              />
              <input
                className={styles.filterInput}
                placeholder="Filter by user..."
                value={filters.user}
                onChange={(e) => handleFilterChange('user', e)}
              />
              <input
                className={styles.filterInput}
                placeholder="Filter by campaign..."
                value={filters.campaign}
                onChange={(e) => handleFilterChange('campaign', e)}
              />
              <Button
                className={styles.resetFiltersButton}
                variant="outlined"
                size="small"
                onClick={resetFilters}
              >
                Reset Filters
              </Button>
            </div>
            
            <table className={styles.table}>
              <thead>
                <tr>
                  <th 
                    className={`${styles.sortableHeader} ${sortField === 'date' ? styles.activeSort : ''}`}
                    onClick={() => handleSort('date')}
                  >
                    Date
                    {sortField === 'date' && (
                      <span className={`${styles.sortIcon} ${sortDirection === 'asc' ? styles.asc : ''}`}>
                        ▼
                      </span>
                    )}
                  </th>
                  <th 
                    className={`${styles.sortableHeader} ${sortField === 'amount' ? styles.activeSort : ''}`}
                    onClick={() => handleSort('amount')}
                  >
                    Amount
                    {sortField === 'amount' && (
                      <span className={`${styles.sortIcon} ${sortDirection === 'asc' ? styles.asc : ''}`}>
                        ▼
                      </span>
                    )}
                  </th>
                  <th 
                    className={`${styles.sortableHeader} ${sortField === 'note' ? styles.activeSort : ''}`}
                    onClick={() => handleSort('note')}
                  >
                    Note
                    {sortField === 'note' && (
                      <span className={`${styles.sortIcon} ${sortDirection === 'asc' ? styles.asc : ''}`}>
                        ▼
                      </span>
                    )}
                  </th>
                  <th 
                    className={`${styles.sortableHeader} ${sortField === 'user' ? styles.activeSort : ''}`}
                    onClick={() => handleSort('user')}
                  >
                    User
                    {sortField === 'user' && (
                      <span className={`${styles.sortIcon} ${sortDirection === 'asc' ? styles.asc : ''}`}>
                        ▼
                      </span>
                    )}
                  </th>
                  <th 
                    className={`${styles.sortableHeader} ${sortField === 'campaign' ? styles.activeSort : ''}`}
                    onClick={() => handleSort('campaign')}
                  >
                    Campaign
                    {sortField === 'campaign' && (
                      <span className={`${styles.sortIcon} ${sortDirection === 'asc' ? styles.asc : ''}`}>
                        ▼
                      </span>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredAndSortedCredits.length > 0 ? (
                  filteredAndSortedCredits.map((credit: WorkspaceCredit) => (
                    <tr key={credit.id}>
                      <td>{format(new Date(credit.createdAt), 'MMM d, yyyy h:mm a')}</td>
                      <td className={credit.amount >= 0 ? styles.positive : styles.negative}>
                        {credit.amount >= 0 ? '+' : ''}{credit.amount}
                      </td>
                      <td>{credit.note || '-'}</td>
                      <td>{credit.user ? `${credit.user.firstName} ${credit.user.lastName}` : '-'}</td>
                      <td>
                        {credit.response?.surveyId 
                          ? `${surveyNameMap[credit.response.surveyId] || 'Campaign'} (${credit.response.surveyId})` 
                          : '-'}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className={styles.noData}>
                      <p>No matching transactions found. Try adjusting your filters.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        ) : (
          <div className={styles.noData}>
            <p>No credit transactions found for the selected date range.</p>
          </div>
        )}
      </div>
    </Card>
  );
};
