import classNames from "classnames/bind";
import React, { ReactElement, useState } from "react";

import { Body, ButtonIcon, Spinner } from "../../../shared/v2";
import { useWorkflowContext } from "../../../context/workflow-contexts";
import { useThemeMode } from "../../../context/theme-mode-context";
import { ArrowLeftIcon, ArrowRightIcon } from "../../../icons";
import { HistoryItem } from "./history-item";

import styles from "./history-drawer.module.scss";

const bStyles = classNames.bind(styles);

export const HistoryDrawer = (): ReactElement => {
  const { isDarkMode } = useThemeMode();
  const [isExpanded, setIsExpanded] = useState(true);
  const { aiHistory, setCurrentHistory, currentHistory, isHistoryLoading } =
    useWorkflowContext();

  const renderCollapsedContent = () => {
    if (isHistoryLoading) {
      return;
    }

    return aiHistory.map((history, index) => {
      return (
        <div
          className={bStyles("entry", {
            active: currentHistory?.id === history.id,
          })}
          key={history.id}
          onClick={() => setCurrentHistory(history)}>
          <Body size='base' type='medium'>
            {aiHistory.length - index}
          </Body>
        </div>
      );
    });
  };

  const renderContent = () => {
    if (isHistoryLoading) {
      return (
        <div className={styles.loading}>
          <Spinner className={styles.spinner} />
        </div>
      );
    }

    return aiHistory.map((history, index) => (
      <HistoryItem
        key={history.id}
        history={history}
        currentHistory={currentHistory}
        setCurrentHistory={setCurrentHistory}
        historyLength={aiHistory.length}
        index={index}
      />
    ));
  };

  return (
    <div
      className={bStyles("historyDrawer", {
        isDarkMode,
        isExpanded,
        isCollapsed: !isExpanded,
      })}>
      <div className={styles.headerWrapper}>
        {isExpanded && (
          <>
            <Body className={styles.header} size='s' type='medium'>
              Workflow history
            </Body>

            <ButtonIcon
              onClick={() => setIsExpanded(!isExpanded)}
              icon={<ArrowLeftIcon />}
            />
          </>
        )}
        {!isExpanded && (
          <ButtonIcon
            onClick={() => setIsExpanded(!isExpanded)}
            icon={<ArrowRightIcon />}
          />
        )}
      </div>

      {isExpanded && (
        <div className={styles.entriesList}>{renderContent()}</div>
      )}
      {!isExpanded && (
        <div className={styles.entriesList}>{renderCollapsedContent()}</div>
      )}
    </div>
  );
};
