import React, {FormEvent, ReactElement, useCallback, useMemo, useState} from "react";
import classNames from "classnames/bind";

import {AiChipIcon, CheckmarkIcon, PaperclipIcon, PersonaIcon} from "@/icons";
import {AutoModeTooltip} from "../auto-mode-tooltip";
import {Body, Button, Separator, Switch} from "@/shared/v2";
import {CHAT_COMMANDS, CHAT_TOOLS_PLACEHOLDERS} from "@/shared/constants/constants";
import {KeyboardEventMiddlewareContextProvider} from "@/context/keyboard-event-middleware-context";
import {SelectChatSourcesModal} from "@/canvas/select-chat-sources-modal";
import {SelectPersonaModal} from "@/canvas/select-persona-modal";
import {SourcesSection} from "../sources-section";
import {SubmitButton} from "../submit-button";
import {UploadButton} from "../upload-button";
import {UploadedImageSection} from "../uploaded-image-section";
import {
  useChatConversationContext, useChatDrawerContext
} from "@/context/chat-contexts";
import {useModalActions, usePublishedAgents} from "@/hooks";
import {useThemeMode} from "@/context/theme-mode-context";
import {useWorkspaceContext} from "@/context/workspace-context";
import CommandTextArea from "../command-text-area";
import config from "@/config";
import {ChatConversationMode, ChatLayoutMode} from "@/reducer/chat-reducer";

import styles from "./input-bubble.module.scss";

const cx = classNames.bind(styles);

export interface InputBubbleProps {
  init?: string;
}

export const InputBubble = ({init = ""}: InputBubbleProps): ReactElement => {
  const {isDarkMode} = useThemeMode();
	const {
		conversation,
		update: updateConversation,
		send: sendQuestion,
		isSendingQuestion,
    isUpdating,
	} = useChatConversationContext();
	const {
    nextQuestion: {mention, image},
    tool,
    files,
    videos,
    trainingSets,
    surveys: campaigns,
    mode: conversationMode,
    layout,
  } = conversation;
	const {workspace} = useWorkspaceContext();
	const {isDrawerOpen, setIsDrawerOpen} = useChatDrawerContext();

	const [value, setValue] = useState(init);

  const isDisabled = useMemo(() => {
    const valueTrimmed = value.trim();
    const isOnlyMention = mention && valueTrimmed === `@${mention.name}`;
    
    return !workspace.id || (!valueTrimmed.length && !image) || isOnlyMention || isSendingQuestion || isUpdating;
  }, [workspace.id, value.length, image, isSendingQuestion, isUpdating, mention]);

	const {personas} = usePublishedAgents();

  const isUsingSmartTools = conversationMode === ChatConversationMode.SMART_TOOLS;
  const isUsingSmartSources = conversationMode === ChatConversationMode.SMART_SOURCES;

  const {
		value: isSelectPersonaModalOpen,
		open: openSelectPersonaModal,
		close: closeSelectPersonaModal,
	} = useModalActions();

  const {
    value: isSelectChatSourcesModalOpen,
    open: openSelectChatSourcesModal,
    close: closeSelectChatSourcesModal,
  } = useModalActions({search: "chat-sources"});

	const clearInput = () => {
		setValue("");
	};

	const handleResponse = useCallback(async (e?: FormEvent<HTMLFormElement>): Promise<void> => {
		e?.preventDefault();

		if (isDisabled) {
			return;
		}

		// Determine the mode based on *currently* selected toggles
		const calculatedMode = isUsingSmartTools
			? ChatConversationMode.SMART_TOOLS
			: isUsingSmartSources
				? ChatConversationMode.SMART_SOURCES
				: ChatConversationMode.CONVERSATION;

		const queryToSend = value;
		clearInput();

		// Pass the calculated mode directly to sendQuestion instead of updating the conversation first
		// This ensures the correct mode is used for the first message in a new conversation
		sendQuestion({
			query: queryToSend,
			mode: calculatedMode
		});

	}, [isDisabled, value, sendQuestion, isUsingSmartTools, isUsingSmartSources, setValue]); // Removed conversationMode and updateConversation from dependencies

  return (
    <>
      <div className={cx(
        "inputBubble",
        layout === ChatLayoutMode.CHAT ? "chat" : "home",
        {
          isDarkMode,
          drawer: isDrawerOpen,
        })
      }>
        <div className={styles.inputAndSendSection}>
          <KeyboardEventMiddlewareContextProvider>
            <CommandTextArea
              value={value}
              onChange={setValue}
              placeholder={tool ? CHAT_TOOLS_PLACEHOLDERS[tool] : "Ask anything..."}
              commands={CHAT_COMMANDS}
              personas={personas}
              handleSubmit={handleResponse}
              mention={mention}
            />
          </KeyboardEventMiddlewareContextProvider>

          <UploadedImageSection />

          <UploadButton />
          <SubmitButton onSubmit={handleResponse} isDisabled={isDisabled} />
        </div>

        <SourcesSection className={styles.attachmentsSection} hideIfEmpty />

        <Separator className={styles.separator} />

        <div className={styles.controlSection}>
					<Button
						aria-label="agent-button"
						style="toolkit"
						className={styles.toolkitButton}
						size="small"
						leftIcon={<PersonaIcon />}
						onClick={openSelectPersonaModal}
          >
            Agent
          </Button>

          <div className={cx("toolsContainer", {isDarkMode})}>
            <Button
              style="toolkit"
              className={cx("toolkitButton", "toolsButton", {isDarkMode})}
              size="small"
              disabled={tool !== null}
              onClick={openSelectChatSourcesModal}
              leftIcon={<PaperclipIcon />}
            >
              Sources
            </Button>

            {config.featureToggles.smartPrompt && (
              <div
                className={cx("autoToggleContainer", {isDarkMode})}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Switch
                  checked={isUsingSmartSources}
                  onChange={(checked) => {
                    // If checked, enable SMART_SOURCES mode and disable REASONING mode
                    // If unchecked, go to CONVERSATION mode
                    updateConversation({
                      mode: checked ? ChatConversationMode.SMART_SOURCES : ChatConversationMode.CONVERSATION
                    });
                  }}
                  className={cx("autoToggle")}
                />
                <AutoModeTooltip />
              </div>
            )}
          </div>

          <div className={cx("toolsContainer", {isDarkMode})}>
            <Button
              style="toolkit"
              className={cx("toolkitButton", "toolsButton", {isDarkMode})}
              size="small"
              leftIcon={<AiChipIcon />}
              onMouseDown={() => setIsDrawerOpen(current => !current)}
            >
              Tools
            </Button>

            {config.featureToggles.smartPrompt && (
              <div
                className={cx("autoToggleContainer", {isDarkMode})}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Switch
                  checked={isUsingSmartTools}
                  onChange={(checked) => {
                    // If checked, enable SMART_TOOLS mode and disable SMART_SOURCES mode
                    // If unchecked, go to CONVERSATION mode
                    updateConversation({
                      mode: checked ? ChatConversationMode.SMART_TOOLS : ChatConversationMode.CONVERSATION
                    });
                  }}
                  className={cx("autoToggle")}
                />
                <AutoModeTooltip />
              </div>
            )}
          </div>

          <div className={styles.controlsSpace} />
        </div>
      </div>

      <SelectPersonaModal
				isOpen={isSelectPersonaModalOpen}
				onClose={closeSelectPersonaModal}
			/>

      <SelectChatSourcesModal
        isOpen={isSelectChatSourcesModalOpen}
        onClose={closeSelectChatSourcesModal}
      />
    </>
  );
}
