/* eslint-disable react/prop-types */
import classNames from "classnames/bind";
import React, {ReactElement, useMemo, useState} from "react";

import {Body, Spinner} from "@/shared/v2";
import {Column, HeaderGroup} from "react-table";
import {FileLabels} from "@/datasets/components/dataset-page/file-labels";
import {FileStatus} from "@/datasets/components/dataset-page/file-status";
import {GET_TRAINING_SET_MEDIA} from "@/graphql/queries/ai-models-queries";
import {GetTrainingSetMediaResponse, GetTrainingSetMediaVariables, TrainingSet, TrainingSetMedia, TrainingSetMediaCriteria, TrainingSetMediaSort} from "@/models/ai-model";
import {isFileTag} from "@/shared/utility/type-guards/is-file-tag";
import {isFileTagKey} from "@/shared/utility/type-guards/is-file-tag-key";
import {isTrainingSet, isTrainingSetMediaTypeAlias} from "@/shared";
import {isTrainingSetMediaEmbeddingsStatus} from "@/shared/utility/type-guards/is-training-set-media-embeddings-status";
import {OverflowTooltip} from "@/shared/v2/tooltip/overflow-tooltip";
import {PaginatedTable} from "@/shared/v2/paginated-table";
import {useDebounceValue, useLoadingQuery} from "@/hooks";
import {useMediaCriteriaContext} from "@/context/media-criteria-context";
import {useWorkspaceContext} from "@/context/workspace-context";

import styles from "./media-table.module.scss";

const cx = classNames.bind(styles);

export interface TableProps {
	selected: (TrainingSet | TrainingSetMedia)[];
	select: (items: TrainingSetMedia[]) => void;
	unselect: (items: TrainingSetMedia[]) => void;
	search?: string;
	trainingSetId?: string;
	className?: string;
	renderColumns?: (columns: Column<TrainingSetMedia>[]) => Column<TrainingSetMedia>[];
}
const PAGE_SIZE = 20;

export const MediaTable = ({
	trainingSetId,
	className,
	search,
	selected,
	select,
	unselect,
	renderColumns = (columns) => columns,
}: TableProps): ReactElement => {
	const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {criteria} = useMediaCriteriaContext();
	const [sortBy, setSortBy] = useState<TrainingSetMediaSort>(TrainingSetMediaSort.CREATED_AT_DESC);

  const [debouncedSearch] = useDebounceValue(search, 500);

	const selectedTagIds = useMemo(() => {
		return criteria.filter(c => isFileTag(c)).map(c => c.id);
	}, [criteria]);

	const selectedMimetypeAliases = useMemo(() => {
		return criteria.filter(c => isTrainingSetMediaTypeAlias(c)).map(c => c.mimetypeAlias);
	}, [criteria]);

	const selectedTagKeyIds = useMemo(() => {
		return criteria.filter(c => isFileTagKey(c)).map(c => c.id);
	}, [criteria]);

	const selectedEmbeddingsStatuses = useMemo(() => {
		return criteria.filter(c => isTrainingSetMediaEmbeddingsStatus(c)).map(c => c.embeddingsStatus);
	}, [criteria]);

  const {
		data: mediaData,
		networkStatus,
		isFetchingMore,
		handleFetchMore,
	} = useLoadingQuery<GetTrainingSetMediaResponse, GetTrainingSetMediaVariables>(GET_TRAINING_SET_MEDIA, {
    variables: {
      workspaceId,
      limit: pageSize,
			sort: sortBy,
      filter: {
        originalFilename: debouncedSearch,
				trainingSetId,
				tagIds: selectedTagIds.length > 0 ? selectedTagIds : undefined,
				tagKeyIds: selectedTagKeyIds.length > 0 ? selectedTagKeyIds : undefined,
				embeddingsStatuses: selectedEmbeddingsStatuses.length > 0 ? selectedEmbeddingsStatuses : undefined,
				mimetypeAliases: selectedMimetypeAliases.length > 0 ? selectedMimetypeAliases : undefined,
      },
    },
    skip: !workspaceId,
		fetchPolicy: "network-only",
		nextFetchPolicy: "cache-first",
		notifyOnNetworkStatusChange: true,
  });

	const selectedMedia = useMemo(() => {
		const items = mediaData?.trainingSetMedia.items || [];
		const trainingSetIds = selected.filter(s => isTrainingSet(s)).map(s => s.id);
		const mediaSelectedInTrainingSets = items.filter(m => trainingSetIds.includes(m.trainingSetId)) || [];

		return [...mediaSelectedInTrainingSets, ...selected.filter(s => !isTrainingSet(s))];
	}, [selected, mediaData]);

	const handleSort = (column: HeaderGroup<TrainingSetMedia>): void => {
		if (!column.canSort) return;

		switch (column.Header) {
			case "Name":
				if (column.isSorted === false && column.isSortedDesc === undefined) return setSortBy(TrainingSetMediaSort.NAME_ASC);
				if (column.isSorted === true && column.isSortedDesc === false) return setSortBy(TrainingSetMediaSort.NAME_DESC);
				setSortBy(TrainingSetMediaSort.DEFAULT);
				break;
			case "Type":
				if (column.isSorted === false && column.isSortedDesc === undefined) return setSortBy(TrainingSetMediaSort.TYPE_ASC);
				if (column.isSorted === true && column.isSortedDesc === false) return setSortBy(TrainingSetMediaSort.TYPE_DESC);
				setSortBy(TrainingSetMediaSort.DEFAULT);
				break;
			case "Status":
				if (column.isSorted === false && column.isSortedDesc === undefined) return setSortBy(TrainingSetMediaSort.STATUS_ASC);
				if (column.isSorted === true && column.isSortedDesc === false) return setSortBy(TrainingSetMediaSort.STATUS_DESC);
				setSortBy(TrainingSetMediaSort.DEFAULT);
				break;
			default:
				setSortBy(TrainingSetMediaSort.DEFAULT);
		}
	}

  const columns: Column<TrainingSetMedia>[] = renderColumns([
		{
			Header: "Name",
			accessor: "originalFilename",
			Cell: data => {
				const file = data.row.original;
				return (
					<a href={file.url} target="_blank" className={styles.fileLink} rel="noreferrer">
						<OverflowTooltip>
							<Body size="s" type="medium" className={styles.overflow}>
								{file.originalFilename}
							</Body>
						</OverflowTooltip>
					</a>
				);
			},
		},
		{
			Header: "Type",
			accessor: "mimetypeAlias",
			Cell: data =>
				data.value && (
					<Body size="s" className={styles.overflow} color="text-tertiary">
						{data.value}
					</Body>
				),
		},
		{
			Header: "Status",
			accessor: "embeddingsGenerationStatus",
			Cell: data => {
				const file = data.row.original;

				return <FileStatus file={file} />;
			},
		},
		{
			Header: "Labels",
			Cell: data => {
				const row = data.row.original;
				const tags = row.tags;

				return (
					<FileLabels
						tags={tags}
						max={-1}
					/>
				)
			}
		},
	]);

	const fetchMoreFragment: ReactElement | undefined = useMemo(() => {
		return isFetchingMore ? <Spinner className={styles.spinner} /> : undefined;
	}, [isFetchingMore]);

	const handlePerPageChange = (newPageSize: number): void => {
		setPageSize(newPageSize);
	}

	const renderTable = (): ReactElement => {
		if (!mediaData) {
			return (
				<Spinner className={styles.spinner} />
			);
		}

		return (
			<PaginatedTable<TrainingSetMedia>
				columns={columns}
				page={mediaData.trainingSetMedia}
				onSort={handleSort}
				pageSize={pageSize}
				handleFetchMore={handleFetchMore}
				onPageSizeChange={handlePerPageChange}
				fetchMoreFragment={fetchMoreFragment}
				selectedValues={selectedMedia}
				onAddSelectedItems={select}
				onRemoveSelectedItems={unselect}
				networkStatus={networkStatus}
			/>
		)
	}

	return (
		<div className={cx("mainContainer", className)}>
			{renderTable()}
		</div>
	);
};
