import { NetworkStatus } from "@apollo/client";
import React, { ReactElement, useMemo, useState } from "react";
import { DocumentNode } from "graphql";

import { useLoadingQuery } from "@/hooks";
import { TablePagination } from "@/shared/components/table/table-pagination";
import { DebounceSearch, LoadingContainer } from "@/shared/v2";
import { SearchIcon } from "@/icons";
import classNames from "classnames/bind";

import styles from "./paginated-list.module.scss";

const cx = classNames.bind(styles);

const PAGE_SIZE = 20;

export interface PaginatedListProps<T> {
  // Query information
  query: DocumentNode;
  queryName: string; // The name of the query result field (e.g., 'trainingSetsPage', 'workspaceSurveys')
  workspaceId: string;
  filterField?: string;

  // Rendering
  renderCreateCard: ReactElement;
  renderItem: (item: T) => ReactElement;
  renderSortComponent: (props: {
    value: string;
    onChange: (value: string) => void;
    className?: string;
  }) => ReactElement;

  // Customization
  containerClassName?: string;
  gridClassName?: string;
  loadingComponent?: ReactElement;
  searchPlaceholder?: string;
  defaultSortValue?: string;
}

export function PaginatedList<T>({
  query,
  queryName,
  workspaceId,
  renderCreateCard,
  renderItem,
  renderSortComponent,
  containerClassName,
  gridClassName,
  loadingComponent,
  filterField = "name",
  searchPlaceholder = "Search",
  defaultSortValue = "UPDATED_AT_DESC",
}: PaginatedListProps<T>): ReactElement {
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [searchValue, setSearchValue] = useState<string>("");
  const [sortValue, setSortValue] = useState(defaultSortValue);
  const [currentPage, setCurrentPage] = useState(0);

  const { data, handleFetchMore, networkStatus, fragment } = useLoadingQuery(
    query,
    {
      skip: !workspaceId,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
      errorPolicy: "all",
      variables: {
        workspaceId,
        limit: pageSize,
        filter: {
          [filterField]: searchValue,
        },
        sort: sortValue,
      },
    }
  );

  const handleChangeSearch = (value: string): void => {
    setSearchValue(value);
    setCurrentPage(0);
  };

  const handleSortChange = (value: string): void => {
    setSortValue(value);
    setCurrentPage(0);
  };

  const queryData = data?.[queryName];

  const totalCount = useMemo(() => {
    if (!queryData) return 0;
    return queryData.items?.length + queryData.remaining;
  }, [queryData]);

  const startingRow =
    currentPage === 0 ? currentPage + 1 : currentPage * pageSize + 1;
  const endRow =
    (currentPage + 1) * pageSize > totalCount
      ? totalCount
      : (currentPage + 1) * pageSize;

  const pageCount = useMemo(() => {
    return Math.ceil(totalCount / pageSize);
  }, [totalCount, pageSize]);

  const dataLength = useMemo(() => {
    if (!queryData) return 0;
    return queryData.items?.length;
  }, [queryData]);

  const handlePageSizeChange = (newPageSize: number): void => {
    setPageSize(newPageSize);
    setCurrentPage(0);
  };

  const handleNext = (): void => {
    if ((currentPage + 2) * pageSize > dataLength) handleFetchMore(pageSize);
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrev = (): void => {
    setCurrentPage((prev) => prev - 1);
  };

  const gotoPage = (newPage: number): void => {
    if (currentPage < newPage && (newPage + 1) * pageSize > dataLength) {
      handleFetchMore((newPage - currentPage) * pageSize);
    }
    setCurrentPage(newPage);
  };

  const currentData = useMemo(() => {
    if (!queryData) return [];
    return queryData.items.slice(
      currentPage * pageSize,
      (currentPage + 1) * pageSize
    );
  }, [queryData, currentPage, pageSize]);

  const isLoadingData =
    networkStatus !== NetworkStatus.ready && !queryData?.items.length;

  const renderContent = (): ReactElement => {
    if (isLoadingData) {
      return loadingComponent || <LoadingContainer />;
    }

    return (
      <>
        {networkStatus === NetworkStatus.fetchMore ||
        networkStatus === NetworkStatus.setVariables ? (
          loadingComponent || <LoadingContainer />
        ) : (
          <div className={cx("grid", gridClassName)}>
            {renderCreateCard}
            {(!data && fragment) ||
              (data &&
                currentData &&
                currentData.map((item: T, index: number) => (
                  <React.Fragment key={index}>
                    {renderItem(item)}
                  </React.Fragment>
                )))}
          </div>
        )}
        <div className={styles.paginationContainer}>
          <TablePagination
            currentPage={currentPage}
            startingRow={startingRow}
            endRow={endRow}
            totalCount={totalCount}
            onPageSizeChange={handlePageSizeChange}
            handleNext={handleNext}
            handlePrev={handlePrev}
            gotoPage={gotoPage}
            pageCount={pageCount}
            pageSize={pageSize}
            disablePagination={networkStatus === NetworkStatus.fetchMore}
          />
        </div>
      </>
    );
  };

  return (
    <div className={containerClassName}>
      <div className={styles.searchWrapper}>
        <DebounceSearch
          leftIcon={<SearchIcon />}
          size='small'
          placeholder={searchPlaceholder}
          value={searchValue}
          onChange={handleChangeSearch}
          className={styles.searchInput}
        />

        {renderSortComponent({
          value: sortValue,
          onChange: handleSortChange,
          className: styles.sort,
        })}
      </div>

      {renderContent()}
    </div>
  );
}
