import React, {ReactElement, useMemo} from "react";

import {ButtonIcon, MediaCriteria, MediaTable, Subheader} from "@/shared/v2";
import {ChevronLeftIcon} from "@/icons";
import {OverflowTooltip} from "@/shared/v2/tooltip/overflow-tooltip";
import {SelectSourcesPage, useNavigationContext} from "../../../contexts/navigation-context";
import {useSearchContext} from "@/modals/select-ai-sources-modal/contexts/search-context";
import {useSelectedSourcesContext} from "@/modals/select-ai-sources-modal/contexts/selected-sources-context";

import styles from "./select-dataset-media-tab.module.scss";

export const SelectDatasetMediaTab = (): ReactElement => {
  const {dataset, setCurrentPage} = useNavigationContext();
  const {search} = useSearchContext();
  const {selected, add: select, remove: unselect} = useSelectedSourcesContext();

  const selectedMedia = useMemo(() => [...selected.files, ...selected.videos, ...selected.trainingSets], [selected]);

  return (
    <div className={styles.selectDatasetMediaTab}>
      <div className={styles.controls}>
        <ButtonIcon
          filledIcon
          icon={<ChevronLeftIcon />}
          color="text-secondary"
          onClick={() => setCurrentPage(SelectSourcesPage.DATASETS)}
        />

        <OverflowTooltip>
          <Subheader
            type="semibold"
            color="text-body"
            size="l"
            className={styles.trainingSetName}
          >
            {dataset ? dataset.alias : "Dataset"}
          </Subheader>
        </OverflowTooltip>
      </div>

      <MediaCriteria />

      <MediaTable
        search={search}
        select={select}
        selected={selectedMedia}
        trainingSetId={dataset?.id}
        unselect={unselect}
      />
    </div>
  );
}
