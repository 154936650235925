import {
	ANSWER_VIDEO_FRAGMENT,
	ANSWER_WITH_TRANSCRIPT_FRAG,
	ATTRIBUTE_FRAGMENT,
	CLIP_FRAGMENT,
	QUESTION_FRAGMENT,
	SURVEY_DASHBOARD_FRAGMENT,
	SURVEY_FRAGMENT,
	TRANSCRIPT_FRAGMENT,
	UPLOAD_FILE_FRAGMENT,
} from "../fragments/fragments";
import {gql} from "@apollo/client";

// Just a reminder to include IDs in everything so the cache can identify stuff

/**
 * Get all surveys in the context of a workspace
 */
export const GET_ALL_SURVEYS = gql`
	query GetAllSurveys(
		$workspaceId: GUID!
		$filter: SurveysFilter
		$limit: PositiveInt
		$cursor: String
		$sort: SurveySort
	) {
		workspaceSurveys(workspaceId: $workspaceId filter: $filter limit: $limit cursor: $cursor sort: $sort) {
			items {
				...SurveyPageFields
			}
			cursor
			remaining
		}
	}
	${SURVEY_DASHBOARD_FRAGMENT}
`;

export const GET_SURVEY = gql`
	query GetSurvey($id: GUID! $workspaceId: GUID!) {
		survey(id: $id) {
			...SurveyFields
			offlineAssist
			requiresPassword
			password
			creator {
				id
				name
			}
			introImage {
				id
				small:url(version:SMALL)
			}
			introVideo {
				id
				small:url(version:SMALL_STILL)
				video:url(version:VP8_VORBIS)
				mp4:url(version:H264_AAC)
			}
			disclaimer
			incentiveAmount
			incentiveCurrency
			creditAmount
			estimatedCompletionTime
			responseCount
		}
		workspace(id: $workspaceId) {
			id
			responseCount
			plan
			workspaceCreditBalance
		}
		attributes(workspaceId: $workspaceId) {
			items {
				...AttributeFields
			}
		}
		questions(surveyId: $id) {
			items {
				...QuestionFields
			}
		}
	}
	${SURVEY_FRAGMENT},
	${QUESTION_FRAGMENT},
	${ATTRIBUTE_FRAGMENT}
`;

export const GET_FEEDBACK_SURVEY = gql`
	query GetFeedbackSurvey($brandId: GUID!) {
		feedbackSurvey(brandId: $brandId) {
			id
			title
		}
	}
`;

/**
 * Currently only using this to get the questions specifically for the feedback survey
 */
export const GET_FEEDBACK_QUESTIONS = gql`
	query GetFeedbackQuestions ($id: GUID!) {
		questions (surveyId: $id) {
			items {
				id
				answerCount
				text
				type
				videoResponse
				timeLimit
				points
				index
			}
		}
	}
`;

export const GET_SURVEY_INCENTIVE = gql`
	query GetSurveyIncentive($id: GUID!) {
		survey(id: $id) {
			id
			incentiveAmount
			incentiveCurrency
		}
	}
`;

/**
 * Gets all the questions in the format we want for the summary page
 * Might also return other info in near future.
 */
export const GET_SUMMARY = gql`
	query GetSummary($surveyId: GUID! $password: String) {
		survey(id: $surveyId password: $password) {
			id
			surveyInsights {
				id
				summary
				objective
				insights
				headlines
				pros
				cons
				ideas
				edited
			}
			insightsProcessing
		}
	}
`;

export const GET_QUESTION_SUMMARY = gql`
	query GetQuestionSummary($questionId: GUID!) {
		question(id: $questionId) {
			id
			text
			questionInsights {
				summary
			}
		}
	}
`;

export const GET_ALL_RESPONSES = gql`
	query GetResponses(
		$surveyId: GUID!
		$limit: PositiveInt
		$cursor: String
		$filter: ResponsesFilter
		$sort: ResponsesSort
	) {
		responses(surveyId: $surveyId limit: $limit cursor: $cursor filter: $filter sort: $sort) {
			cursor
			remaining
			items {
				id
				completedAt
				user {
					id
					firstName
					lastInitial
					creatorTag
					picture {
						url(version: THUMBNAIL)
					}
				}
				answers {
					id
					questionId
					skipped
					choices {
						id
						index
						text
					}
					barcodes {
						product {
							barcodeNumber
							title
							images
						}
					}
					numberAnswer
					text
					video {
						id
						badge: url(version: BADGE)
						duration
						transcript {
							...TranscriptFields
							englishTranslation
							text
						}
					}
					uploadedImages {
						originalFilename
						thumbnail: url(version: THUMBNAIL)
						full: url(version: ORIGINAL)
					}
				}
			}
		}
	}
	${TRANSCRIPT_FRAGMENT}
`;

export const GET_QUESTION = gql`
	query GetQuestion(
		$id: GUID!
		$filter: ResponsesFilter
	) {
		question(id: $id) {
			id
			surveyId
			videoResponse
			type
			index
			text
			imageId
			required
			image {
				...QuestionFileFields
			}
			video {
				id
				transcodingStatus
				small:url(version:SMALL_STILL)
				video:url(version:VP8_VORBIS)
				mp4:url(version:H264_AAC)
			}
			arModel {
				id
				glbUrl:url(version:GLB)
				usdzUrl:url(version:USDZ)
			}
			followUp
			highlightCount
			shuffle
			timeLimit
			min
			max
			na
			rank
			step
			subtype
			leftLabel
			rightLabel
			numericCounts(filter: $filter) {
				answer
				count
			}
			numericAverage(filter: $filter)
			textWordCounts(filter: $filter) {
				word
				count
			}
			answerCount(filter: $filter)
			choices {
				id
				index
				text
				answerCount(filter: $filter)
				frequency(filter: $filter)
			}
		}
	}
	${UPLOAD_FILE_FRAGMENT}
`;

export const GET_AUTO_REEL = gql`
	query GetAutoReel($id: GUID!) {
		question(id: $id) {
			id
			index
			questionInsights {
				id
				summary
				topics
				isSummary
				answerText
				sentiment
				meta
				insights
				responseCount
			}
			sensemakeReel {
				id
				name
				description
				videoStatus
				createdAt
				duration
				video {
					id
					transcodingStatus
					video:url(version:VP8_VORBIS)
					mp4:url(version:H264_AAC)
					thumbnail:url(version:THUMBNAIL)
				}
				clip {
					...ClipFields
				}
			}
		}
	} ${CLIP_FRAGMENT}
`;

export const GET_QUESTION_ZIP = gql`
	query GetQuestionZip($id: GUID!) {
		question(id: $id) {
			id
			zipFile {
				id
				url
				uploadStatus
				originalFilename
			}
		}
	}
`;

export const GET_STATS = gql`
	query GetStats (
		$id: GUID!
		$filter: ResponsesFilter
	) {
		survey(id: $id) {
			id
			completedResponseCount(filter: $filter)
			medianCompletionTime(filter: $filter)
			totalVideoDuration(filter: $filter)
			responseCount(filter: $filter)
		}
	}
`;

export const GET_QUESTION_IMAGE = gql`
	query GetQuestionImage ($id: GUID!) {
		questionImage(id: $id) {
			id
			transcodingStatus
		}
	}
`;

export const GET_PUBLISH_INFO = gql`
	query GetInvitees ($surveyId: GUID!) {
		surveyMembers (surveyId: $surveyId) {
			items {
				userId
			}
		}
		surveyLanguages {
			id
			name
		}
	}
`;

/**
 * Gets question video but for anyone
 */
export const GET_QUESTION_VIDEO = gql`
	query GetQuestionVideo ($id: GUID!) {
		question(id: $id) {
			id
			video {
				id
				video:url(version:VP8_VORBIS)
			}
		}
	}
`;
/**
 * Gets the video for a question
 */
export const GET_QUESTION_VIDEO_STATUS = gql`
	query GetQuestionVideoStatus ($id: GUID!) {
		surveyVideo(id: $id) {
			id
			transcodingStatus
		}
	}
`;

export const GET_INTRO_IMAGE = gql`
	query GetIntroImage ($id: GUID!) {
		introImage(id: $id) {
			id
			transcodingStatus
		}
	}
`;

export const GET_SURVEY_PROMOTION = gql`
	# Says String! in the API but probably should be GUID! (?)
	query GetSurveyPromotion ($id: String!) {
		fetchSurveyPromotion(id: $id) {
			id
			status
			numberOfMatches
		}
	}
`;

export const GET_SURVEY_MEMBERS = gql`
	query GetSurveyMembers (
		$surveyId: GUID!
		$limit: PositiveInt
		$cursor: String
		$filter: SurveyMembersFilter
		$sort: SurveyMembersSort
	) {
		surveyMembers(
			surveyId: $surveyId
			limit: $limit
			cursor: $cursor
			filter: $filter
			sort: $sort
		) {
			items {
				userId
				user {
					id
					firstName
					lastInitial
					creatorTag
					picture {
						id
						badge:url(version:BADGE)
					}
				}
				status
				emails {
					#No id since we only care for this on the SurveyMember
					template
					createdAt
				}
			}
			cursor
			remaining
		}
	}
`;

/**
 * Standard get all answers. Does not include the
 * transcript lines
 */
export const GET_ALL_ANSWERS = gql`
	query GetAllAnswers (
		$questionId: GUID!
		$filter: AnswersFilter
		$limit: PositiveInt
		$cursor: String
		$sort: AnswersSort
	) {
		answers (
			questionId: $questionId
			filter: $filter
			limit: $limit
			cursor: $cursor
			sort: $sort
		) {
			items {
				...AnswerVideoTranscript
				text
				skipped
				reviewed
				numberAnswer
				uploadedImages {
					transcodingStatus
					thumbnail:url(version: THUMBNAIL)
					original:url(version: ORIGINAL)
					originalFilename
				}
				uploadedPdfs {
					id
					uploadStatus
					originalFilename
					url
				}
				barcodes {
					id
					barcode
					product {
						title
						images
					}
				}
				user {
					id
					firstName
					lastInitial
					creatorTag
				}
			}
			cursor
			remaining
		}
	}
	${ANSWER_WITH_TRANSCRIPT_FRAG}
`;

/**
 * Requirement to separate the table for the text responses from the
 * videos. So here is the query for it.
 */
export const GET_TEXT_ANSWERS = gql`
	query GetTextAnswers (
		$questionId: GUID!
		$limit: PositiveInt
		$cursor: String
	) {
		answers (questionId: $questionId limit: $limit cursor: $cursor) {
			items {
				id
				text
				numberAnswer
				user {
					id
					firstName
					lastInitial
				}
			}
		}
	}
`;

export const GET_VIDEO_ANSWERS = gql`
	query GetVideoAnswers (
		$filter: AnswersSearchFilter
		$workspaceId: GUID!
		$limit: PositiveInt
		$cursor: String
		$sort: AnswersSort
	) {
		searchAnswers (
			filter: $filter
			workspaceId: $workspaceId
			limit: $limit
			cursor: $cursor
			sort: $sort
		) {
			items {
				...AnswerVideoTranscript
				user {
					id
					firstName
					lastInitial
					creatorTag
				}
				question {
					id
					survey {
						name
						id
					}
					index
					text
				}
				text
				numberAnswer
			}
			cursor
			remaining
		}
	}
	${ANSWER_WITH_TRANSCRIPT_FRAG}
`;

export const GET_ALL_SURVEY_NAMES = gql`
	query GetAllSurveys($workspaceId: GUID!) {
		workspaceSurveys(workspaceId: $workspaceId) {
			items {
				id
				name
			}
		}
	}
`;

/**
 * Right now this is basically the same as answer video,
 * but we will use this to also get the transcript of the videos' answer
 * when that becomes available.
 */
export const GET_ANSWER_BY_ID = gql`
	query GetAnswer($id: GUID! $langCode: String) {
		answer (id: $id) {
			id
			video {
				id
				duration
				video:url(version:VP8_VORBIS)
				mp4:url(version:H264_AAC)
				insight:url(version:INSIGHTS)
				transcript {
					...TranscriptFields
				}
				subtitles(langCode: $langCode) {
					id
					fileUrl
					langCode
				}
			}
			text
			user {
				id
				firstName
				lastInitial
				creatorTag
			}
			likeCount
			likedAt
			reviewed
			reviewer {
				id
				name
			}
			highlightCount
			question {
				id
				text
				followUp
			}
		}
	}
	${TRANSCRIPT_FRAGMENT}
`;

export const GET_VIDEO_TRANSCRIPT = gql`
	query GetTranscript ($id: GUID!) {
		videoTranscript (videoId: $id) {
			id
			words {
				word
				startAt
				endAt
			}
			highlights {
				id
				transcriptId
				firstWord
				lastWord
			}
			corrections {
				id
				transcriptId
				firstWord
				lastWord
				correction
			}
		}
	}
`;

/**
 * Used to get a new URL for the mp4 video specifically
 */
export const GET_UPDATED_MP4 = gql`
	query GetMP4($id: GUID!) {
		answer(id: $id) {
			id
			video {
				id
				mp4:url(version:H264_AAC isDownload:true)
			}
		}
	}
`;

export const GET_RESPONSES = gql`
	query GetResponses (
		$surveyId: GUID!
		$limit: PositiveInt
		$cursor: String
		$filter: ResponsesFilter
		$sort: ResponsesSort
	) {
		responses (surveyId: $surveyId limit: $limit cursor: $cursor filter: $filter sort: $sort) {
			items {
				id
				completedAt
				submittedFrom {
					city
					countryName
				}
				creditAmount
				creditNote
				user {
					id
					creatorTag
					firstName
					lastName
					name
					picture {
						id
						badge:url (version:BADGE)
					}
				}
			}
			cursor
			remaining
		}
	}
`;
/**
 * Takes the id of the answer and only gives the video back.
 * VORBIS version
 */
export const GET_ANSWER_VIDEO = gql`
	query GetAnswerVideo ($id: GUID!) {
		answer (id: $id) {
			id
			video {
				id
				video:url(version:VP8_VORBIS)
				mp4:url(version:H264_AAC)
			subtitles {
					id
					fileUrl
					langCode
				}
			}
		}
	}
`;

// This will only be called in SurveyIntro, so I *think* it is okay
// to read only from the client
export const GET_INTRODUCTION = gql`
	query GetIntro ($id: GUID!) {
		survey(id: $id) {
			introduction @client
			instructions @client
			title @client
			startText @client
			introImage @client{
				id
				small:url(version:SMALL)
			}
			introVideo @client {
				id
				small:url(version:SMALL_STILL)
				video:url(version:VP8_VORBIS)
				mp4:url(version:H264_AAC)
			}
			disclaimer @client
			createdAt @client
			updatedAt @client
		}
	}
`;

/**
 * Query used to ping for the current status of an answer
 */
export const PING_ANSWER_STATUS = gql`
	query PingAnswer ($id: GUID!) {
		answer(id: $id) {
			id
			video {
				...AnswerVideoFields
			}
		}
	}
	${ANSWER_VIDEO_FRAGMENT}
`;

export const GET_WORKSPACE_STATS = gql`
	query getStats($workspaceId: GUID!) {
		workspaceHistory(id: $workspaceId) {
			answerDuration {
				...fields
			}
			contactCount {
				...fields
			}
			answerCount {
				...fields
			}
		}
	}
	fragment fields on Historical {
		older
		lastMonth
		thisMonth
  }
`;

export const GET_WORKSPACE_LOCATION_DATA = gql`
	query getWorkspaceLocationData($workspaceId: GUID!) {
		workspaceStats(id: $workspaceId) {
			usa: responseLocation(ids: "US") {
				region {
					key
					total
					percentage
				}
			}
			world: responseLocation {
				key
				total
				percentage
			}
		}
	}
`;

export const GET_SURVEY_BASICS = gql`
	query GetSurveyBasics($id: GUID!) {
		survey(id: $id) {
			...SurveyFields
		}
	}
	${SURVEY_FRAGMENT},
`;

export const GET_ALL_SURVEY_QUESTIONS = gql`
  query GetAllSurveyQuestions(
    $surveyId: GUID!
    $filter: QuestionsFilter
  ) {
    questions(
      surveyId: $surveyId
      filter: $filter
    ) {
      items {
				...QuestionFields
			}
    }
  }
  ${QUESTION_FRAGMENT}
`;
