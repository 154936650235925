import {ChatActionTypes, ChatLayoutMode, ChatState, RevertActionPayload} from "@/reducer/chat-reducer";
import { isChatFakeResponseMessage } from "../type-guards"; // Import the type guard

export const handleRevertAction = (state: ChatState, payload: RevertActionPayload): ChatState => {
  console.log(`[handleRevertAction] Reverting action: ${payload.action}`); // Add log
  if (payload.action === ChatActionTypes.SEND_QUESTION) {
    // Remove the fake query and fake response messages added optimistically
    const newMessages = state.current.messages.filter(msg => !isChatFakeResponseMessage(msg)); 
    // Potentially also remove the fake query message if needed, but let's start with response
    // const newMessages = state.current.messages.filter(msg => !isChatFakeResponseMessage(msg) && !isChatFakeQueryMessage(msg));
    
    const newCurrent = {
      ...state.current,
      messages: newMessages,
    }
    const newActions = {
      ...state.actions,
      isSendingQuestion: false, // Ensure this is set to false
      error: state.actions.error || 'Reverted send question action', // Keep existing error or set a generic one
    }
    console.log('[handleRevertAction] After reverting SEND_QUESTION', { isSendingAfter: newActions.isSendingQuestion, messagesCount: newCurrent.messages.length });
    return {
      ...state,
      actions: newActions,
      current: newCurrent,
    }
  }

  if (payload.action === ChatActionTypes.CREATE_CONVERSATION) {
    const newActions = {
      ...state.actions,
      isCreating: false,
    }
    const newCurrent = {
      ...state.current,
      layout: ChatLayoutMode.HOME,
    }

    return {
      ...state,
      actions: newActions,
      current: newCurrent,
    };
  }

  if (payload.action === ChatActionTypes.UPDATE_CONVERSATION) {
    const newActions = {
      ...state.actions,
      isUpdating: false,
    }

    return {
      ...state,
      actions: newActions,
    };
  }

  return state;
}
