import React, { useState } from 'react';
import styles from './ConnectionListItem.module.scss'; // Import the SCSS module
import classnames from 'classnames'; // Import classnames utility

// Define the enum at the module level
enum ComposioAuthScheme {
  OAUTH2 = 'OAUTH2',
  API_KEY = 'API_KEY',
  BEARER_TOKEN = 'BEARER_TOKEN',
}

// Reusing placeholder types from IntegrationManagementPage
// Ideally, these would come from a shared types file or generated types
interface SupportedTool {
  id: string;
  name: string;
  description?: string;
  logoUrl?: string;
}

interface UserConnection {
  id: string; // Vurvey DB ID
  connectionId: string; // Composio Connection ID
  toolId: string;
  toolName: string;
  toolLogoUrl?: string;
  status: string;
  authScheme?: ComposioAuthScheme; // Updated type
  createdAt: string;
}

interface ConnectionListItemProps {
  tool: SupportedTool;
  connection: UserConnection | null; // Null if not connected
  onConnect: (toolId: string, authScheme?: ComposioAuthScheme) => void; // Updated type
  onDisconnect: (connectionId: string, toolId: string) => void; // Callback for disconnect action
  isConnectLoading: boolean; // Loading state for connect action
  isDisconnectLoading: boolean; // Loading state for disconnect action
}

export const ConnectionListItem: React.FC<ConnectionListItemProps> = ({
  tool,
  connection,
  onConnect,
  onDisconnect,
  isConnectLoading,
  isDisconnectLoading,
}) => {
  const isConnected = !!connection;

  // State for showing auth method selector
  const [showAuthOptions, setShowAuthOptions] = useState(false);
  
  // Connect with default OAuth2 flow
  const handleConnectClick = () => {
    if (!isConnectLoading) {
      // Show authentication options menu instead of immediately connecting
      setShowAuthOptions(true);
    }
  };

  // Handler for selecting authentication method - updated parameter type
  const handleAuthMethodSelect = (authScheme: ComposioAuthScheme) => {
    if (!isConnectLoading) {
      // Hide the auth options menu
      setShowAuthOptions(false);
      // Initialize connection with selected auth scheme
      onConnect(tool.id, authScheme);
    }
  };

  const handleDisconnectClick = () => {
    if (!isDisconnectLoading && connection) {
      onDisconnect(connection.id, tool.id); // Pass both the connection ID and tool ID
    }
  };

  return (
    <div className={styles.itemContainer}>
      <div className={styles.toolInfo}>
        <img
          src={tool.logoUrl || '/placeholder-logo.png'} // Consider a local placeholder
          alt={`${tool.name} logo`}
          className={styles.toolLogo}
        />
        <div className={styles.toolDetails}>
          <span className={styles.toolName}>{tool.name}</span>
          <p className={styles.toolDescription}>
            {tool.description || 'No description available.'}
          </p>
        </div>
      </div>
      <div className={styles.connectionStatus}>
        {isConnected ? (
          <>
            <div className={styles.connectionDetails}>
              <span className={classnames(styles.statusText, styles.connected)}>✓ Connected</span>
              {connection?.authScheme && (
                <span className={styles.authSchemeLabel}>
                  via {connection.authScheme === 'OAUTH2' ? 'OAuth' : 
                       connection.authScheme === 'API_KEY' ? 'API Key' : 
                       connection.authScheme === 'BEARER_TOKEN' ? 'Bearer Token' : 
                       connection.authScheme}
                </span>
              )}
            </div>
            <button
              className={classnames(styles.actionButton, styles.disconnectButton)}
              onClick={handleDisconnectClick}
              disabled={isDisconnectLoading}
            >
              {isDisconnectLoading ? 'Disconnecting...' : 'Disconnect'}
            </button>
          </>
        ) : (
          <>
            <span className={classnames(styles.statusText, styles.notConnected)}>Not Connected</span>
            {showAuthOptions ? (
              <div className={styles.authOptionsContainer}>
                <div className={styles.authOptionsTitle}>Choose Authentication Method:</div>
                <div className={styles.authOptions}>
                  <button
                    className={classnames(styles.authOptionButton, styles.oauthButton)}
                    onClick={() => handleAuthMethodSelect(ComposioAuthScheme.OAUTH2)} // Use enum
                    disabled={isConnectLoading}
                  >
                    OAuth (Login)
                  </button>
                  <button
                    className={classnames(styles.authOptionButton, styles.apiKeyButton)}
                    onClick={() => handleAuthMethodSelect(ComposioAuthScheme.API_KEY)} // Use enum
                    disabled={isConnectLoading}
                  >
                    API Key
                  </button>
                  <button
                    className={classnames(styles.authOptionButton, styles.bearerTokenButton)}
                    onClick={() => handleAuthMethodSelect(ComposioAuthScheme.BEARER_TOKEN)} // Use enum
                    disabled={isConnectLoading}
                  >
                    Bearer Token
                  </button>
                  <button
                    className={classnames(styles.authOptionButton, styles.cancelButton)}
                    onClick={() => setShowAuthOptions(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <button
                className={classnames(styles.actionButton, styles.connectButton)}
                onClick={handleConnectClick}
                disabled={isConnectLoading}
              >
                {isConnectLoading ? 'Connecting...' : 'Connect'}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

// Optional: Export default if preferred
// export default ConnectionListItem;
