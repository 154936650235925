import React, { ReactElement, useEffect, useState } from "react";
import { BaseModalProps, Modal } from "../../../shared/v2/modals/modal/";
import { Body, Button, Input, Switch, TextArea } from "../../../shared/v2";
import { useValidation } from "../../../hooks/useValidation";
import { SsoProvider } from "../../containers/sso-providers-page";

import styles from "./sso-modal.module.scss";

export type SsoProviderChanges = Omit<SsoProvider, "id">;
export interface SSOModalProps extends BaseModalProps {
  provider: SsoProvider | null;
  onSave: (changes: SsoProviderChanges) => void;
}

export const SSOModal = ({
  isOpen,
  onClose,
  provider,
  onSave,
}: SSOModalProps): ReactElement => {
  const [changes, setChanges] = useState({
    providerName: "",
    domain: "",
    active: true,
    config: "",
  });

  useEffect(() => {
    if (provider) {
      setChanges({
        providerName: provider.providerName,
        domain: provider.domain,
        active: provider.active,
        config: provider.config,
      });
    } else {
      setChanges({
        providerName: "",
        domain: "",
        active: true,
        config: "",
      });
    }
  }, [provider, isOpen]);

  const {
    results: validation,
    validate,
    clearErrors,
  } = useValidation(changes, {
    providerName: {
      ruleset: {
        required: true,
      },
      customErrorMessage: {
        required: () => "Provider Name is required",
      },
    },
    domain: {
      ruleset: {
        required: true,
      },
    },
  });

  const handleClose = (): void => {
    onClose();
    setChanges({
      providerName: "",
      domain: "",
      active: true,
      config: "",
    });
    clearErrors();
  };

  const handleSave = (): void => {
    if (!validate()) {
      return;
    }
    onSave(changes);
    handleClose();
  };

  const handleChangeField = (value: string | boolean, field: string): void => {
    setChanges({
      ...changes,
      [field]: value,
    });
  };

  return (
    <Modal
      size='medium'
      isOpen={isOpen}
      onClose={handleClose}
      title={provider ? "Edit SSO Provider" : "Create SSO Provider"}>
      <div className={styles.form}>
        <Input
          label='Provider Name *'
          value={changes.providerName}
          onChange={(value: string) => handleChangeField(value, "providerName")}
          placeholder='Provider Name'
          validation={validation.providerName}
        />
        <Input
          label='Domain *'
          value={changes.domain}
          onChange={(value: string) => handleChangeField(value, "domain")}
          placeholder='Domain'
          validation={validation.domain}
        />
        <TextArea
          label='Config'
          value={changes.config}
          onChange={(value: string) => handleChangeField(value, "config")}
          placeholder='Config'
        />
        <div className={styles.switchContainer}>
          <Body size='s' color='text-secondary'>
            Active
          </Body>
          <Switch
            checked={changes.active}
            onChange={(value) => handleChangeField(value, "active")}
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </div>
    </Modal>
  );
};
