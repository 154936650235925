import React, {ReactElement, useMemo} from "react";

import {SearchInput} from "./search-input";
import {SearchMediaInput} from "@/shared/v2/inputs";
import {SelectSourcesPage, useNavigationContext} from "../../contexts/navigation-context";
import {useSearchContext} from "../../contexts/search-context";

export const SearchSourcesInput = (): ReactElement => {
  const {currentPage, dataset} = useNavigationContext();
  const {
    search,
    setSearch,
  } = useSearchContext();

  const component = useMemo(() => {
    switch (currentPage) {
      case SelectSourcesPage.MEDIA:
      case SelectSourcesPage.DATASET_MEDIA:
        return (
          <SearchMediaInput
            search={search}
            setSearch={setSearch}
            trainingSetId={dataset?.id}
          />
        );
      default:
        return <SearchInput />;
    }
  }, [currentPage, dataset?.id, search, setSearch]);

  return component;
}

