import React, {Dispatch, ReactElement, ReactNode, SetStateAction, createContext, useContext, useState} from "react";

import {TrainingSetMediaCriteria} from "@/models/ai-model";
import {isFileTag, isFileTagKey, isTrainingSetMediaEmbeddingsStatus, isTrainingSetMediaTypeAlias} from "@/shared";

export interface MediaCriteriaContextProps {
  children?: ReactNode;
}

export interface MediaCriteriaContextValue {
  criteria: TrainingSetMediaCriteria[];
  add: (criteria: TrainingSetMediaCriteria) => void;
  remove: (criteria: TrainingSetMediaCriteria) => void;
  set: Dispatch<SetStateAction<TrainingSetMediaCriteria[]>>
  clear: () => void;
}

export const MediaCriteriaContext =
  createContext<MediaCriteriaContextValue | undefined>(undefined);

export const MediaCriteriaContextProvider = (
  {children}: MediaCriteriaContextProps,
): ReactElement => {
  const [criteria, setCriteria] = useState<TrainingSetMediaCriteria[]>([]);

  const add = (criteria: TrainingSetMediaCriteria) => {
    setCriteria(prev => Array.from(new Set([...prev, criteria])));
  };

  const remove = (criteria: TrainingSetMediaCriteria) => {
    setCriteria(prev => {
      if (isTrainingSetMediaEmbeddingsStatus(criteria)) {
        return prev.filter(c => isTrainingSetMediaEmbeddingsStatus(c) ? c.embeddingsStatus !== criteria.embeddingsStatus : true);
      }

      if (isTrainingSetMediaTypeAlias(criteria)) {
        return prev.filter(c => isTrainingSetMediaTypeAlias(c) ? c.mimetypeAlias !== criteria.mimetypeAlias : true);
      }

      return prev.filter(c => isFileTagKey(c) || isFileTag(c) ? c.id !== criteria.id : true);
    });
  }

  const clear = () => {
    setCriteria([]);
  }

  return (
    <MediaCriteriaContext.Provider value={{criteria, add, remove, set: setCriteria, clear}}>
      {children}
    </MediaCriteriaContext.Provider>
  );
};

export const useMediaCriteriaContext = (): MediaCriteriaContextValue => {
  const context = useContext(MediaCriteriaContext);

  if (context === undefined) {
    throw new Error(
      "useMediaCriteriaContext must be used within a MediaCriteriaContextProvider",
    );
  }

  return context;
};
