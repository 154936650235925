/* eslint-disable */
import React, {ReactElement} from "react";

import {SearchIcon} from "../../../../icons";
import {Input} from "../input";

export const SearchInput = ({value, onChange, placeholder = "Search", ...props}):ReactElement => {
	return <Input
		leftIcon={<SearchIcon />}
		placeholder={placeholder}
		size="small"
		value={value}
		onChange={onChange}
		{...props}
	/>
}
