import React, { ReactElement, useState } from "react";
import { useNavigate } from "react-router";
import classNames from "classnames/bind";
import { useMutation } from "@apollo/client";

import { Badge } from "../../../workspace-settings/components/model-card/badge";
import {
  ButtonGroup,
  ButtonGroupElement,
} from "../../../shared/components/navigation";
import { ButtonIcon, Subheader, Button } from "../../../shared/v2";
import { CANCEL_WORKFLOW } from "../../../graphql/mutations/ai-mutations";
import {
  ChevronLeftIcon,
  CloudSaveUploadIcon,
  LightingBoltStrikeIcon,
  PenEditIcon,
  PauseStopSoundMusicIcon,
} from "../../../icons";
import { ConfirmActionModal } from "../../../shared/v2/modals/confirm-action-modal";
import { useConfirmationBlocker } from "@/hooks";
import { useRunWorkflowContext } from "../../../context/workflow-contexts/run-workflow-context";
import { useThemeMode } from "../../../context/theme-mode-context";
import {
  useWorkflowContext,
  useWorkflowModalsContext,
} from "../../../context/workflow-contexts";
import { useWorkflowStateContext } from "@/context/workflow-contexts/workflow-state-context";

import styles from "./top-bar.module.scss";

const bStyles = classNames.bind(styles);

export const TopBar = () => {
  const { setIsEditWorkflowModalOpen } = useWorkflowModalsContext();
  const navigate = useNavigate();
  const { isDarkMode } = useThemeMode();
  const {
    workflow,
    isUpdatingDisabled: isWorkflowUpdatingDisabled,
    isDirty: isWorkflowDirty,
    save: handleSaveWorkflow,
  } = useWorkflowStateContext();
  const {
    isOnHistoryTab,
    isWorkflowRunning,
    currentHistoryRun,
    refetchHistory,
  } = useWorkflowContext();

  const [cancelWorkflow] = useMutation(CANCEL_WORKFLOW);

  const { isWorkflowValid, handleRunWorkflow } = useRunWorkflowContext();

  const [isCloneRunModalOpen, setIsCloneRunModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const unsavedChangesModal = useConfirmationBlocker({
    shouldBlock: isWorkflowDirty,
    renderPrompt: ({ cancel, proceed, isOpen }) => (
      <ConfirmActionModal
        title='Unsaved Changes'
        description='You have unsaved changes in your workflow. Are you sure you want to leave without saving?'
        isOpen={isOpen}
        onClose={cancel}
        onConfirm={proceed}
        confirmText='Exit Without Saving'
        cancelText='Continue Editing'
        size='small'
      />
    ),
  });

  const handleBack = () => {
    navigate("/workflow/flows");
  };

  const renderButton = (): ReactElement | null => {
    if (isOnHistoryTab) {
      return null;
      // TODO: bring back when cloning is implemented
      // return <Button
      // 	className={styles.actionButton}
      // 	disabled={isSaving}
      // 	onClick={() => setIsCloneRunModalOpen(true)}
      // 	size="small"
      // 	leftIcon={<PlusSmallIcon />}
      // >
      // 	Clone
      // </Button>
    }

    if (isWorkflowRunning) {
      return (
        <Button
          className={styles.actionButton}
          onClick={() => setIsCancelModalOpen(true)}
          size='small'
          style='danger'
          filledIcon
          leftIcon={<PauseStopSoundMusicIcon />}>
          Cancel
        </Button>
      );
    }

    if (isWorkflowDirty) {
      return (
        <Button
          className={styles.actionButton}
          disabled={isWorkflowUpdatingDisabled}
          onClick={(e) => {
            e.preventDefault();
            handleSaveWorkflow();
          }}
          size='small'
          leftIcon={<CloudSaveUploadIcon />}>
          Save
        </Button>
      );
    }
    return (
      <Button
        className={styles.actionButton}
        onClick={handleRunWorkflow}
        disabled={isWorkflowRunning || !isWorkflowValid}
        size='small'
        style='ai'
        leftIcon={<LightingBoltStrikeIcon />}>
        Run Workflow
      </Button>
    );
  };

  return (
    <>
      <div className={bStyles("topBar", { isDarkMode })}>
        <div className={styles.leftSide}>
          <ButtonIcon
            onClick={handleBack}
            filledIcon
            icon={<ChevronLeftIcon />}
          />

          <ButtonGroup>
            <ButtonGroupElement size='small' text='Build' to='' end />
            <ButtonGroupElement size='small' text='History' to='history' />
          </ButtonGroup>
        </div>

        <div className={styles.centerSide}>
          {workflow && (
            <>
              <div className={styles.titleWrapper}>
                <Subheader type='medium'>{workflow?.name || ""}</Subheader>
                <Badge
                  text='beta'
                  tooltip='This feature is in beta and may not work perfectly yet. We’d love your feedback to help us refine and improve it!'
                />
              </div>

              {!isOnHistoryTab && (
                <ButtonIcon
                  disabled={isWorkflowRunning}
                  onClick={() => setIsEditWorkflowModalOpen(true)}
                  icon={<PenEditIcon />}
                />
              )}
            </>
          )}
        </div>

        <div className={styles.rightSide}>
          {workflow && <>{renderButton()}</>}
        </div>
      </div>

      {unsavedChangesModal}

      <ConfirmActionModal
        title='Cancel Workflow'
        description='Are you sure you want to cancel this workflow? This action cannot be undone.'
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        onConfirm={async () => {
          try {
            if (currentHistoryRun?.id) {
              await cancelWorkflow({
                variables: {
                  historyId: currentHistoryRun.id,
                },
              });
              console.log("Workflow cancelled successfully");
              await refetchHistory();
              // reload the page to get the latest workflow data
              window.location.reload();
            } else {
              console.error("No current history run found to cancel");
            }
          } catch (error) {
            console.error("Error cancelling workflow:", error);
          }
          setIsCancelModalOpen(false);
        }}
        confirmText='Cancel Workflow'
        cancelText='Continue Running'
        confirmButtonStyle='danger'
        size='small'
      />

      <ConfirmActionModal
        title='Clone Workflow Run'
        description='This run will be copied, creating a new workflow based on it. You can then edit and adjust the workflow as needed.'
        isOpen={isCloneRunModalOpen}
        onClose={() => setIsCloneRunModalOpen(false)}
        onConfirm={() => setIsCloneRunModalOpen(false)}
        confirmText='Cancel'
        cancelText='Clone & Create'
        confirmButtonStyle='brand'
        size='x-small'
      />
    </>
  );
};
