import {gql} from "@apollo/client";

export const AI_ORCHESTRATION_FRAGMENT = gql`
  fragment AiOrchestrationFields on AiOrchestration {
    id
    workspaceId
    name
    description
    inputParameters
    instructions
    outputTypeId
    outputType {
      id
      code
      name
      vurveyOnly
      createdAt
      updatedAt
    }
    campaigns: surveys {
      id
      name
    }
    trainingSets {
      id
      alias
      files {
        id
        trainingSetId
      }
      videos {
        id
        trainingSetId
      }
    }
    questions {
      id
      text
      surveyId
    }
    videos {
      id
      originalFilename
      trainingSetId
    }
    files {
      id
      originalFilename
      trainingSetId
    }
    aiPersonaTasks {
      id
      name
      instructions
      index
      smartPromptEnabled
      chatMode
      persona {
        id
        name
        avatarUri
        picture {
          id
          url(version: THUMBNAIL)
        }
      }
      task {
          id
          taskPrompt
          output
      }
    }
  }
`;
