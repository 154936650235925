import {useMutation, useQuery} from "@apollo/client";
import React, {ReactElement, useContext, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {Column} from "react-table";

import {MediaCriteriaContextProvider} from "@/context/media-criteria-context";
import {FileActions} from "@/datasets/components/dataset-page/file-actions";
import {FileStatus} from "@/datasets/components/dataset-page/file-status";
import {ManageLabelsModal} from "@/datasets/components/manage-labels-modal";
import {Label} from "@/datasets/components/manage-labels-modal/labels-form";
import {ReplaceExistingLabelsModal} from "@/datasets/components/modals";
import {useMount} from "@/hooks/useMount";
import {isTrainingSetFile, isTrainingSetVideo} from "@/shared";
import {SearchMediaInput} from "@/shared/v2/inputs";
import {ToastContext} from "../../../context/toast-context";
import {
	DELETE_TRAINING_SET_FILE,
	DELETE_TRAINING_SET_VIDEO, RETRY_FAILED_FILE_TRANSCODING,
	SET_TAGS_TO_TRAINING_SET_MEDIA
} from "../../../graphql/mutations/ai-mutations";
import {GET_TRAINING_SET} from "../../../graphql/queries/ai-models-queries";
import {BinDeleteIcon, ChevronLeftIcon, SparkAiStarsIcon, TagLabelIcon} from "../../../icons";
import {FileEmbeddingsGenerationStatus, TrainingSetMedia} from "../../../models/ai-model";
import {NavLink} from "../../../route/components";
import {
	Body,
	Button,
	LoadingContainer,
	MediaCriteria,
	MediaTable,
	Separator,
	Subheader,
	Tooltip
} from "../../../shared/v2";
import {ConfirmActionModal} from "../../../shared/v2/modals/confirm-action-modal";
import {FileUploader} from "../../components";

import styles from "./dataset-page.module.scss";

export const POLL_INTERVAL = 30000;

export interface RetryFailedFileTranscodingResult {
	retryFailedFileTranscoding: {
		id: string;
		embeddingsGenerationStatus: FileEmbeddingsGenerationStatus;
	};
}

export const DatasetPage = (): ReactElement => {
	const {trainingSetId} = useParams();
	const navigate = useNavigate();
	const {updateToast} = useContext(ToastContext);
	const [itemSelected, setItemSelected] = useState<TrainingSetMedia | undefined>();
	const [showBulkDelete, setShowBulkDelete] = useState(false);
	const [shouldPoll, setShouldPoll] = useState(false);
	const [isManageLabelsModalOpen, setIsManageLabelsModalOpen] = useState(false);
	const [isReplacingLabelsWaringModalOpen, setIsReplacingLabelsWaringModalOpen] = useState(false);
	const [fileForEditingLabels, setFileForEditingLabels] = useState<TrainingSetMedia | undefined>();

	const [saveLabels] = useMutation(SET_TAGS_TO_TRAINING_SET_MEDIA, {
		onError: () => {
			updateToast({
				type: "failure",
				description: "Failed to save labels, please try again later",
			});
		}
	});

	const {
		data: {trainingSet} = {},
		loading,
		startPolling,
		stopPolling,
	} = useQuery(GET_TRAINING_SET, {
		variables: {trainingSetId},
		fetchPolicy: "network-only",
	});

	useMount(() => {document.title = "Vurvey - Datasets"})

	useEffect(() => {
		if (trainingSet) {
			if (allHasEmbeddings) {
				setShouldPoll(false);
				stopPolling();

				if (shouldPoll) {
					updateToast({
						description: "All files are ready. You can start a conversation now.",
						type: "success",
					});
				}
			}
		}
	}, [trainingSet, stopPolling]);

	const handleFileDeleteError = () => {
		updateToast({
			type: "failure",
			description: "Failed to delete, please try again later",
		});
	}

	const [deleteFile] = useMutation(DELETE_TRAINING_SET_FILE, {
		onError: handleFileDeleteError,
	});
	const [deleteVideo] = useMutation(DELETE_TRAINING_SET_VIDEO, {
		onError: handleFileDeleteError,
	});

	const [retryTranscoding] = useMutation<RetryFailedFileTranscodingResult>(RETRY_FAILED_FILE_TRANSCODING);

	const [searchValue, setSearchValue] = useState("");
	const [selectedFiles, setSelectedFiles] = useState<TrainingSetMedia[]>([]);

	const trainingSetFiles = useMemo(() => {
		return trainingSet?.files?.concat(trainingSet?.videos || []);
	}, [trainingSet?.files, trainingSet?.videos]);

	const allHasEmbeddings = useMemo(() => {
		return trainingSetFiles?.every(file => file.embeddingsGenerationStatus === FileEmbeddingsGenerationStatus.SUCCESS);
	}, [trainingSetFiles]);

	const handleDeleteFile = async (file?: TrainingSetMedia) => {
		if (!file) {
			return;
		}
		if (isTrainingSetVideo(file)) {
			await deleteVideo({variables: {videoId: file.id}});
		}
		if (isTrainingSetFile(file)) {
			await deleteFile({variables: {fileId: file.id}});
		}
		return setItemSelected(undefined);
	};

	const handleDeleteSelectedFiles = async (): Promise<void> => {
		await Promise.all(selectedFiles.map(file => handleDeleteFile(file)));
		setShowBulkDelete(false);
		setSelectedFiles([]);
	};

	const handleRetryTranscoding = async (file: TrainingSetMedia) => {
		await retryTranscoding({
			variables: {
				trainingSetId,
				...(isTrainingSetVideo(file) ? {videoId: file.id} : {fileId: file.id}),
			},
			onError: () => {
				updateToast({
					type: "failure",
					description: "Failed to retry transcoding, please try again later",
				});
			}
		});
	};

	const renderColumns = (columns: Column<TrainingSetMedia>[]): Column<TrainingSetMedia>[] => {
		const newStatusColumn: Column<TrainingSetMedia> = {
			Header: "Status",
			accessor: "embeddingsGenerationStatus",
			Cell: data => {
				const file = data.row.original;

				return <FileStatus file={file} handleRetryTranscoding={handleRetryTranscoding} />;
			},
		}
		const optionsColumn: Column<TrainingSetMedia> = {
			id: "options",
			Header: "",
			maxWidth: 50,
			disableSortBy: true,
			Cell: data => {
				const file = data.row.original;

				return <FileActions
					file={file}
					setFileForEditingLabels={setFileForEditingLabels}
					setIsManageLabelsModalOpen={setIsManageLabelsModalOpen}
					setItemSelected={setItemSelected}
				/>
			},
		} as Column<TrainingSetMedia>

		const updatedColumns = columns.map(column => {
			if (column.Header === "Status") {
				return newStatusColumn;
			}
			return column;
		})

		return [
			...updatedColumns,
			optionsColumn,
		]
	}

	const openInCopilot = (): void => {
		navigate("/workflow/conversation", {
			state: {
				chatConversation: {
					chat: {
						trainingSets: [trainingSet],
					}
				}
			}
		});
	};

	const handleStartPolling = (): void => {
		setShouldPoll(true);
		startPolling(POLL_INTERVAL);
	};

	const selectedTags = useMemo(() => {
		if (fileForEditingLabels) {
			return fileForEditingLabels.tags || [];
		}

		if (!selectedFiles.length) {
			return [];
		}
		if (selectedFiles.length === 1) {

			return selectedFiles[0].tags || [];
		}
		return [];
	}, [selectedFiles.length, fileForEditingLabels]);

	const handleAddLabel = () => {
		if (selectedFiles.length === 0) {
			return;
		}

		if (selectedFiles.length === 1) {
			return setIsManageLabelsModalOpen(true);
		}

		if (selectedFiles.some(file => file.tags?.length > 0)) {
			return setIsReplacingLabelsWaringModalOpen(true);
		}
		setIsManageLabelsModalOpen(true);
	}

	const closeLabelsModal = () => {
		setFileForEditingLabels(undefined);
		setIsManageLabelsModalOpen(false);
	}

	const handleSaveLabels = (labels: Label[]) => {
		if (fileForEditingLabels) {
			const isVideo = isTrainingSetVideo(fileForEditingLabels);

			if (isVideo) {
				saveLabels({
					variables: {
						trainingSetId: trainingSetId,
						media: {
							fileIds: [],
							videoIds: [fileForEditingLabels.id],
						},
						tags: labels.map(label => ({key: label.key, value: label.value})),
					}
				})
				closeLabelsModal();
				return;
			} else {
				saveLabels({
					variables: {
						trainingSetId: trainingSetId,
						media: {
							fileIds: [fileForEditingLabels.id],
							videoIds: [],
						},
						tags: labels.map(label => ({key: label.key, value: label.value})),
					}
				})
				closeLabelsModal();
				return;
			}
		}

		saveLabels({
			variables: {
				trainingSetId,
				media: {
					fileIds: selectedFiles.filter(file => isTrainingSetFile(file)).map(file => file.id),
					videoIds: selectedFiles.filter(file => isTrainingSetVideo(file)).map(video => video.id),
				},
				tags: labels.map(label => ({key: label.key, value: label.value})),
			}
		})
		closeLabelsModal();
	}

	const handleAddSelected = (media: TrainingSetMedia[]): void => {
		setSelectedFiles([...selectedFiles, ...media]);
	}

	const handleRemoveSelected = (media: TrainingSetMedia[]): void => {
		setSelectedFiles(selectedFiles.filter(file => !media.some(m => m.id === file.id)));
	}

	if (loading) {
		return <LoadingContainer />;
	}

	return (
		<>
			<div className={styles.container}>
				<NavLink to="/datasets" className={styles.backArrowWrapper} workspace omitSearch>
					<ChevronLeftIcon className={styles.backIcon} />
				</NavLink>
				<div className={styles.introSection}>
					<div className={styles.introLeftSection}>
						<Subheader size="xl" type="medium">
							{trainingSet?.alias}
						</Subheader>
						<Body className={styles.description} type="regular" color="text-secondary">
							{trainingSet?.description}
						</Body>
					</div>
					<div className={styles.introRightSection}>
						<Tooltip
							content={
								<Body size="xs">
									All files and videos must have been processed before starting conversation
								</Body>
							}
						>
							<Button
								disabled={!allHasEmbeddings || !trainingSetFiles?.length}
								style="ai"
								onClick={openInCopilot}
								leftIcon={<SparkAiStarsIcon />}
								size="small"
							>
								Conversation
							</Button>
						</Tooltip>
						<FileUploader
							trainingSet={trainingSet}
							refetchQuery={GET_TRAINING_SET}
							startPolling={handleStartPolling}
						/>
					</div>
				</div>

				<Separator color="workflow" />

				<MediaCriteriaContextProvider>
					<div className={styles.searchSection}>
						<div className={styles.tableActions}>
							<SearchMediaInput
								search={searchValue}
								setSearch={setSearchValue}
								trainingSetId={trainingSetId}
							/>
							<div className={styles.actionsWrapper}>
								{selectedFiles.length > 0 &&
									<div className={styles.actionButtons}>
										<Button
											size="small"
											variant="outlined"
											leftIcon={<TagLabelIcon />}
											onClick={handleAddLabel}
										>
											Add Label
										</Button>

										<Button
											size="small"
											style="danger"
											variant="outlined"
											leftIcon={<BinDeleteIcon />}
											onClick={() => setShowBulkDelete(true)}
										>
											Delete
										</Button>
									</div>
								}
							</div>
						</div>

						<MediaCriteria className={styles.mediaCriteria} />
					</div>

					<MediaTable
						renderColumns={renderColumns}
						trainingSetId={trainingSetId}
						search={searchValue}
						selected={selectedFiles}
						select={handleAddSelected}
						unselect={handleRemoveSelected}
					/>
				</MediaCriteriaContextProvider>
				<ConfirmActionModal
					title="Delete File"
					isOpen={Boolean(itemSelected)}
					onClose={() => setItemSelected(undefined)}
					onConfirm={() => handleDeleteFile(itemSelected)}
					description="Are you sure you want to delete this file?"
				/>
				<ConfirmActionModal
					title="Delete Files"
					isOpen={showBulkDelete}
					onClose={() => setShowBulkDelete(false)}
					onConfirm={() => handleDeleteSelectedFiles()}
					description="Are you sure you want to delete these files? (May take some time to fully delete)"
				/>

				{isManageLabelsModalOpen &&
					<ManageLabelsModal
						onClose={closeLabelsModal}
						isOpen={isManageLabelsModalOpen}
						tags={selectedTags}
						onSave={handleSaveLabels}
						file={fileForEditingLabels}
					/>
				}

				<ReplaceExistingLabelsModal
					onClose={() => setIsReplacingLabelsWaringModalOpen(false)}
					isOpen={isReplacingLabelsWaringModalOpen}
					onConfirm={() => {
						setIsReplacingLabelsWaringModalOpen(false);
						setIsManageLabelsModalOpen(true)
					}}
				/>
			</div>
		</>
	);
};
