import {Brand} from "./brand";
import {GenericPage, IdName} from "./generic";
import {Response} from "./response";
import {User, UserRole} from "./user";

export interface Workspace {
	id: string;
	name: string;
	plan?: WorkspacePlan;
	logoId?: string;
	logo?: WorkspaceLogo;
	myRole?: WorkspaceRole;
	responseCount?: number;
	responderCount?: number;
	totalVideoDuration?: number;
	totalAnswerDuration?: number;
	hasSubscription?: boolean;
	permissions?: string[];
	renewalDate?: Date;
	createdAt?: Date;
	lastActive?: Date;
	isTrialActive?: boolean;
	trialEnd?: Date;
	brand?: Brand;
	allowAnonymous: boolean;
	enableOfflineAssist: boolean;
	aiInsightsEnabled?: boolean;
	chatbotEnabled?: boolean;
    forceLogout?: boolean;
    forceLogoutPeriodMin?: number;
	workflowEnabled?: boolean;
	creditBalance?: number; // Current credit balance for the workspace
	workspaceCreditBalance?: number; // For GraphQL query result
}

export interface WorkspaceCredit {
	id: string;                  // Unique identifier for the credit transaction
	workspaceId: string;         // ID of the workspace this credit transaction belongs to
	workspace?: Workspace;       // The associated workspace
	amount: number;              // Amount of credits (positive for additions, negative for deductions)
	note?: string;               // Optional note about the transaction
	userId: string;              // ID of the user who performed the transaction
	user?: User;                 // The user who performed the transaction
	responseId?: string;         // ID of the associated response (if applicable)
	response?: Response;         // The associated response (if applicable)
	createdAt: Date;             // When the transaction occurred
	changeReason?: string;       // Reason for the credit change (ADMIN_ADJUSTMENT, SURVEY_RESPONSE, SYSTEM, OTHER)
	adminId?: string;            // ID of the admin who made the adjustment (if applicable)
	runningBalance?: number;     // Running total after this transaction (calculated client-side)
}

export interface WorkspaceCreditPage {
	items: WorkspaceCredit[];
	cursor: string;
	remaining: number;
}

export interface WorkspaceCreditsFilter {
	startDate?: Date;            // Filter transactions from this date
	endDate?: Date;              // Filter transactions until this date
}

export interface AddWorkspaceCreditInput {
	workspaceId: string;         // Workspace to add/deduct credits from
	amount: number;              // Amount to add (positive) or deduct (negative)
	note?: string;               // Optional note explaining the transaction
}

export interface UpdateResponseCreditInput {
	responseId: string;          // Response to update
	creditAmount: number;        // New credit amount for the response
	creditNote?: string;         // Optional note about the credit usage
}

export interface BulkUpdateResponseCreditInput {
	responseIds: string[];       // Multiple responses to update
	creditAmount: number;        // Credit amount to apply to all selected responses
	creditNote?: string;         // Optional note to apply to all selected responses
}

export enum WorkspaceMembersSort {
	DEFAULT = "DEFAULT",
	NAME_AZ = "NAME_AZ",
	NAME_ZA = "NAME_ZA",
	EMAIL_ASC = "EMAIL_ASC",
	EMAIL_DESC = "EMAIL_DESC",
}

export interface WorkspaceLogo {
	id: string;
	badge: string;
	thumbnail?: string;
	originalMimeType?: string;
	originalFilename?: string;
}
export enum WorkspacePlan {
	PENDING = "PENDING",
	TRIAL = "TRIAL",
	BASIC = "BASIC",
	PRO = "PRO",
	ENTERPRISE = "ENTERPRISE",
	ARCHIVED = "ARCHIVED"
}
export enum WorkspaceRole {
	OWNER = "OWNER",
	ADMINISTRATOR = "ADMINISTRATOR",
	MANAGER = "MANAGER",
	CREATOR = "CREATOR",
}

export interface WorkspaceCountData {
	workspace: {
		id: string;
		contactCount: number;
	}
}

export interface MyWorkspaceData {
	items: IdName[];
}

export interface MyWorkspacePageData {
	myWorkspaces: MyWorkspaceData;
}

export interface Historical {
	older: number;
	lastMonth: number;
	thisMonth: number[];
}

export interface WorkspacesPage {
	items: Workspace[];
	cursor: string;
	remaining: number;
}

export interface WorkspacesPageData {
	allWorkspaces: WorkspacesPage;
}

export interface Historical {
	older: number;
	lastMonth: number;
	thisMonth: number[];
}

export interface WorkspaceHistory {
	contactCount: Historical;
	responseCount: Historical;
	requestCount: Historical;
	answerDuration: Historical;
	answerCount: Historical;
}

export interface WorkspaceHistoryReturn {
	workspaceHistory: Pick<WorkspaceHistory, "answerDuration" | "contactCount" | "answerCount">;
}

export interface WorkspaceFilter {
	/**
	 * If provided, will filter to records for Workspaces with a name that begins with this string,
	 * ignoring case.
	 */
	name?: string;
	/**
	 * If provided, will filter to records for Workspaces with the aiInsightsEnabled value provided.
	 */
	aiInsightsEnabled?: boolean;
	/**
	 * If provided, will filter to records for Workspaces with the allowAnonymous value provided.
	 */
	allowAnonymous?: boolean;
	/**
	 * If provided, will filter to records for Workspaces with the enableOfflineAssist value provided.
	 */
	enableOfflineAssist?: boolean;
	/**
	 * If provided, will filter to records for Workspaces with one of the plans provided.
	 */
	plan?: WorkspacePlan[];

	chatbotEnabled?: boolean;

	workflowEnabled?: boolean;
}

export interface WorkspacesPageVars {
	filter?: WorkspaceFilter;
	limit?: number;
}

export interface WorkspaceChanges {
	/**
	 * If provided, will set aiInsightsEnabled to the value provided.
	 */
	aiInsightsEnabled?: boolean;
	/**
	 * If provided, will set allowAnonymous to the value provided.
	 */
	allowAnonymous?: boolean;
	/**
	 * If provided, will set enableOfflineAssist to the value provided.
	 */
	enableOfflineAssist?: boolean;
	/**
	 * If provided, will set the workspace plan to the value provided.
	 */
	plan?: WorkspacePlan;

	/**
	 * If provided, will set chatbot to show in workspace.
	 */
	chatbotEnabled?: boolean;

	/**
	 * If provided, will set workflowEnabled to the value provided.
	 */
	workflowEnabled?: boolean;

	/**
	 * If provided, will set the workspace credit balance to the value provided.
	 */
	workspaceCreditBalance?: number;
}


export interface WorkspacesData {
	manageWorkspaces: number;
}

export interface WorkspaceMember {
	id: string;
	email: string;
	name: string;
	lastInitial: string;
	workspaceRole: WorkspaceRole;
	role: UserRole;
}


export interface WorkspaceMemberPageData {
	workspaceMembers: GenericPage<WorkspaceMember>;
}
