import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import React, { ReactElement, useState } from "react";

import {
  AiOrchestration,
  AiOrchestrationDeleteData,
} from "../../../models/ai-orchestration";
import { Button } from "../../../shared/v2";
import { PlusSmallIcon } from "../../../icons";
import { DELETE_AI_ORCHESTRATION } from "../../../graphql/mutations/ai-mutations";
import { AI_ORCHESTRATIONS_PAGE } from "../../../graphql/queries/ai-orchestration-queries";
import { DeleteConfirmModal } from "../../../modals/delete-reel";
import { WorkflowCard } from "../../components/workflow-card";
import { WorkflowSort } from "../../components/workflow-sort";
import { useToastContext } from "../../../context/toast-context";
import { useWorkspaceContext } from "../../../context/workspace-context";
import { CreateWorkflowModal } from "../../modals/create-workflow-modal";
import { CreateCard } from "../../../shared/v2/cards";
import { FlowsDisabledState } from "./flows-disabled-state";
import { WorkflowCreationEventsProvider } from "../../../context/workflow-contexts/workflow-creation-events-context";
import { PaginatedList } from "@/shared/v2/paginated-list";

import styles from "./flows-page.module.scss";

const FlowsPage = (): ReactElement => {
  const { workspace } = useWorkspaceContext();
  const { updateToast } = useToastContext();
  const navigate = useNavigate();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState("");

  const [deleteOrchestration] = useMutation<AiOrchestrationDeleteData>(
    DELETE_AI_ORCHESTRATION
  );

  const openWorkflowPage = (id: string): void => {
    navigate(`/workflow/flows/${id}`);
  };

  const handleDeleteWorkflow = (): void => {
    deleteOrchestration({
      variables: { id: idForDelete },
      onCompleted: (data) => {
        updateToast({ description: "Deleted Workflow", type: "informational" });
        setIdForDelete("");
      },
      onError: () => {
        setIdForDelete("");
      },
      update: (cache) => {
        cache.modify({
          fields: {
            aiOrchestrationsPage(existingPage = {}, { readField }) {
              const newItems = existingPage.items.filter(
                (item) => readField("id", item) !== idForDelete
              );
              return {
                ...existingPage,
                items: newItems,
              };
            },
          },
        });
      },
    });
  };

  const handleAddWorkflow = (id: string): void => {
    setIsCreateModalOpen(false);
    openWorkflowPage(id);
  };

  if (!workspace.workflowEnabled) {
    return <FlowsDisabledState />;
  }

  return (
    <WorkflowCreationEventsProvider>
      <PaginatedList
        query={AI_ORCHESTRATIONS_PAGE}
        queryName='aiOrchestrationsPage'
        workspaceId={workspace.id}
        filterField='search'
        renderCreateCard={
          <CreateCard wrapperClassname={styles.cardWrapper}>
            <Button
              leftIcon={<PlusSmallIcon className={styles.plusIcon} />}
              color='purple'
              onClick={() => setIsCreateModalOpen(true)}>
              Create new
            </Button>
          </CreateCard>
        }
        renderItem={(workflow: AiOrchestration) => (
          <WorkflowCard
            key={workflow.id}
            workflow={workflow}
            onClick={openWorkflowPage}
            handleDelete={(id) => setIdForDelete(id)}
          />
        )}
        renderSortComponent={({ value, onChange, className }) => (
          <WorkflowSort
            value={value}
            onChange={onChange}
            className={className}
          />
        )}
        defaultSortValue='UPDATED_AT_DESC'
        searchPlaceholder='Search workflows'
        gridClassName={styles.cards}
        containerClassName={styles.container}
      />

      <DeleteConfirmModal
        isOpen={Boolean(idForDelete)}
        onClose={() => setIdForDelete("")}
        handleConfirm={handleDeleteWorkflow}
        warningText='Are you sure you want to delete this flow?'
      />

      <CreateWorkflowModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onAdd={handleAddWorkflow}
      />
    </WorkflowCreationEventsProvider>
  );
};

export { FlowsPage };
