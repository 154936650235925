import {gql} from "@apollo/client";

export const FILE_TAG_FRAGMENT = gql`
  fragment FileTagFields on FileTag {
    id
    value
    key
  }
`;

export const FILE_TAG_KEY_FRAGMENT = gql`
  fragment FileTagKeyFields on FileTagKey {
    id
    value
    workspace {
      id
    }
  }
`;
