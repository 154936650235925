import {SurveyAccessLevel} from "../shared/constants/constants";
import {QueryArgs} from "./constructors";
import {IdTitle} from "./generic";
import {TremendousCurrencyType} from "./rewards";
import {SurveyInsights} from "./survey-insights";

export interface SurveyNotification {
	id: string;
	hoursBefore: number;
}

export interface BrandSurvey {
	title: string;
	questionCount: number;
	status: SurveyStatus;
	completedResponseCount?: number;
	closeAt?: Date;
	closeAfterResponsesCount?: number;
}

export interface HeadlineParsed {
	headline: string;
	subheading: string;
}

export interface IdeaParsed {
	"Idea starter": string;
	"Next Steps": string;
}

export interface Survey {
	id: string;
	workspaceId: string;
	name: string;
	accessLevel: SurveyAccessLevel;
	answersPreview: AnswerPreview[];
	status: SurveyStatus;
	surveyInsights?: SurveyInsights[];
	startText: string;
	title?: string;
	introduction?: string;
	questionCount: number;
	responseUrl: string;
	responseCount: number;
	completedResponseCount: number;
	totalVideoDuration: number;
	instructions?: string;
	offlineAssist: boolean;
	requiresPassword: boolean; // Refers to a password required for viewing summary
	password?: string;
	disclaimer?: string; // Not defined at start
	// Creator is a user object but just the id and name of them
	creator: {id: string; firstName: string; lastName?: string; lastInitial?: string;};
	createdAt?: Date;
	updatedAt?: Date;
	highlightsCount?: number;
	multiResponse: boolean;
	closeAt?: Date;
	closedAt?: Date;
	firstOpenedAt?: Date;
	autoClosed?: boolean;
	closeAfterResponsesCount?: number;
	notifications: SurveyNotification[];
	introImage?: {id: string; small: string}
	introVideo?: {id: string; small: string; video: string; mp4: string}
	thankYouMessage?: string;
	language: {id: string, name: string};
	objectives?: string;
	incentiveAmount?: string;
	incentiveCurrency: TremendousCurrencyType;
	creditAmount?: number; // Credit amount per response for this campaign
	estimatedCompletionTime?: number;
	redirectUrl: string | null;
	answersPreviewCount: number;
}

export interface ObjectivesParsed {
	objective: string;
	questions: string[];
}

export interface InsightsParsed {
	insights: string;
}

export type SurveyName = Pick<Survey, "id" | "name">;
export type SurveyIncentive = Pick<Survey, "id" | "incentiveAmount" | "incentiveCurrency">;

export interface SurveyNamePage {
	items: SurveyName[];
}

export interface SurveyNamePageData {
	workspaceSurveys: SurveyNamePage;
}

export interface SurveysFilter {
	/**
	 * If set, will filter to Surveys with the provided status.
	 */
	status?: SurveyStatus[];
	type?: SurveyType;
	name?: string;
	withResponsesOnly?: boolean;
}

export enum SurveyType {
	CUSTOM = 'CUSTOM',
	BRAND_SCREENER = 'BRAND_SCREENER',
	BRAND_RANKING = 'BRAND_RANKING',
	TOPIC = 'TOPIC',
	FORM = 'FORM',
}

export enum SurveySort {
	DEFAULT = 'DEFAULT',
	NAME_ASC = 'NAME_ASC',
	NAME_DESC = 'NAME_DESC',
	OPEN_AT_ASC = 'OPEN_AT_ASC',
	OPEN_AT_DESC = 'OPEN_AT_DESC',
	CLOSED_AT_ASC = 'CLOSED_AT_ASC',
	CLOSED_AT_DESC = 'CLOSED_AT_DESC',
	UPDATED_AT_ASC = 'UPDATED_AT_ASC',
	UPDATED_AT_DESC = 'UPDATED_AT_DESC',
	QUESTIONS_CNT_ASC = 'QUESTIONS_CNT_ASC',
	QUESTIONS_CNT_DESC = 'QUESTIONS_CNT_DESC',
	RESPONSES_CNT_ASC = 'RESPONSES_CNT_ASC',
	RESPONSES_CNT_DESC = 'RESPONSES_CNT_DESC',
	HIGHLIGHT_CNT_ASC = 'HIGHLIGHT_CNT_ASC',
	HIGHLIGHT_CNT_DESC = 'HIGHLIGHT_CNT_DESC',
	VIDEO_MINUTES_CNT_ASC = 'VIDEO_MINUTES_CNT_ASC',
	VIDEO_MINUTES_CNT_DESC = 'VIDEO_MINUTES_CNT_DESC',
};

export type SurveysQueryArgs = QueryArgs<SurveysFilter, SurveySort> & {
	workspaceId: string;
};

export enum SurveyStatus {
	DRAFT = "DRAFT",
	OPEN = "OPEN",
	BLOCKED = "BLOCKED",
	CLOSED = "CLOSED",
	ARCHIVED = "ARCHIVED",
}
export interface AnswerPreview {
	id: string;
	video: VideoBadge;
}
export interface VideoBadge {
	badge: string;
}

export interface SurveyData {
	survey: Survey;
}

export interface SurveyIncentiveData {
	survey: SurveyIncentive;
}

export interface SurveyPage {
	items: Survey[];
	cursor: string;
	remaining: number;
}

export interface SurveyPageData {
	workspaceSurveys: SurveyPage;
}

export interface SurveyTemplate {
	id: string;
	title?: string;
	templateHeadline?: string;
	templateDescription?: string;
	categoryId: string;
	questionCount?: number;
	incentiveAmount?: string;
	incentiveCurrency: TremendousCurrencyType;
	estimatedCompletionTime?: number;
}

export interface SurveyTemplateCategory {
	id: string;
	name: string;
	templates: SurveyTemplate[];
}

export interface SurveyTemplateCategoryGroup {
	id: string;
	name: string;
	categories: SurveyTemplateCategory[];
}

export interface SurveyTemplateCategoryGroupPage {
	items: SurveyTemplateCategoryGroup[];
}

export interface SurveyTemplateCategoryGroupsReturn {
	surveyTemplateCategoryGroups: SurveyTemplateCategoryGroupPage;
}

export interface RemoveSurveyMember {
	survey: {id: string};
	users: {id: string}[];
}

export interface NewSurvey {
	workspaceId: string;
	name: string;
	incentiveAmount: string;
	title?: string;
	introduction?: string;
	startText?: string;
	accessLevel?: SurveyAccessLevel;
	disclaimer?: string;
	languageId?: string;
	objectives?: string;
	incentiveCurrency?: TremendousCurrencyType;
	creditAmount?: number;
	estimatedCompletionTime?: number;
	projectName?: string;
}

export interface CreateSurveyReturn {
	createSurvey: Survey;
}

export interface CreateSurveyVars {
	input: NewSurvey;
}

export interface CopySurveyReturn {
	duplicateSurvey: Survey;
}

export interface CopySurveyVars {
	id: string;
}

export interface CreateSurveyFromTemplateReturn {
	createSurveyFromTemplate: Survey;
}

export interface CreateSurveyFromTemplateVars {
	id: string;
	workspaceId: string;
}

export interface DeleteSurveyReturn {
	deleteSurvey: Pick<Survey, "id" | "workspaceId" | "name">;
}

export interface DeleteSurveyVars {
	id: string;
}

export interface RemoveSurveyMemberData {
	removeSurveyMembers: RemoveSurveyMember;
}

export interface RemoveSurveyMemberVars {
	surveyId: string;
	userIds: string | string[];
}

export interface SendSurveyResult {
	sendSurveyToMembers: number;
}

export interface UpdateSurveyChanges {
	name?: string
	title?: string
	introduction?: string
	instructions?: string
	startText?: string
	introImageId?: string
	introVideoId?: string
	accessLevel?: SurveyAccessLevel
	multiResponse?: boolean
	disclaimer?: string
	closeAt?: Date
	closeAfterResponsesCount?: number
	thankYouMessage?: string | null;
	languageId?: string
	objectives?: string
	incentiveAmount?: string
	incentiveCurrency?: TremendousCurrencyType
	creditAmount?: number
	estimatedCompletionTime?: number
	projectName?: string
	offlineAssist?: boolean
	redirectUrl?: string | null;
	requiresPassword?: boolean
	password?: string
}

export interface UpdateSurveyDeletions {
	title?: boolean
	introduction?: boolean
	instructions?: boolean
	disclaimer?: boolean
	introImageId?: boolean
	introVideoId?: boolean
	closeAt?: boolean
	closeAfterResponsesCount?: boolean
	objectives?: boolean
	projectName?: boolean
	phase?: boolean
	offlineAssist?: boolean
}

export type UpdateSurveyKeys = keyof UpdateSurveyChanges;
export type UpdateSurveyDeleteKeys = keyof UpdateSurveyDeletions;

export interface UpdateSurveyReturn {
	updateSurvey: Survey;
}

export interface UpdateSurveyVars {
	id: string;
	changes?: UpdateSurveyChanges;
	deletions?: UpdateSurveyDeletions
}

export enum SurveyMembersSort {
	DEFAULT = "DEFAULT",
	NAME_ASC = "NAME_ASC",
	NAME_DESC = "NAME_DESC",
	NEWEST_INVITE_FIRST = "NEWEST_INVITE_FIRST",
	OLDEST_INVITE_FIRST = "OLDEST_INVITE_FIRST",
	STATUS_ASC = "STATUS_ASC",
	STATUS_DESC = "STATUS_DESC",
	EMAIL_ASC = "EMAIL_ASC",
	EMAIL_DESC = "EMAIL_DESC",
	CREATOR_TAG_ASC = "CREATOR_TAG_ASC",
	CREATOR_TAG_DESC = "CREATOR_TAG_DESC",
	LAST_EMAIL_SENT_ASC = "LAST_EMAIL_SENT_ASC",
	LAST_EMAIL_SENT_DESC = "LAST_EMAIL_SENT_DESC",
}

export enum PromoteGender {
	EMPTY = "EMPTY",
	FEMALE = "FEMALE",
	NONBINARY = "NONBINARY",
	MALE = "MALE",
	OTHER = "OTHER",
	TRANSGENDER_FEMALE = "TRANSGENDER_FEMALE",
	TRANSGENDER_MALE = "TRANSGENDER_MALE",
}
export enum LgbtqIdentifier {
	NULL = "-",
	STRAIGHT = "STRAIGHT",
	BISEXUAL_PANSEXUAL = "BISEXUAL OR PANSEXUAL",
	LESBIAN = "LESBIAN",
	GAY = "GAY",
	PREFER_NOT_TO_ANSWER = "PREFER NOT TO ANSWER",
	QUEER = "QUEER",
	NONCONFORMING = "NONBINARY,GENDERQUEER,GENDERFLUID,OR OTHERWISE GENDER NON-CONFORMING",
	ASEXUAL_AROMANTIC_DEMISEXUAL = "ASEXUAL,AROMANTIC,OR DEMISEXUAL",
	OTHER = "OTHER",
	TRANSGENDER = "TRANSGENDER",
	INTERSEX = "INTERSEX",
}
export enum ScoreLabel {
	A_PLUS = "A_PLUS",
	A = "A",
	A_MINUS = "A_MINUS",
	B_PLUS = "B_PLUS",
	B = "B",
	B_MINUS = "B_MINUS",
	C_PLUS = "C_PLUS",
	C = "C",
	C_MINUS = "C_MINUS",
	D = "D",
	F = "F",
}

export enum SurveyPromotionStatus {
	QUEUED = "QUEUED",
	PROCESSING = "PROCESSING",
	FETCHED = "FETCHED",
	FAILED = "FAILED",
	REJECTED = "REJECTED",
	APPROVED = "APPROVED",
	TIMED_OUT = "TIMED_OUT",
}

export interface SurveyPromotionFilter {
	gender: PromoteGender[];
	ethnicity: string[];
	country: string[];
	state: string[];
	lgbtqIdentifier: string[];
	childrenOrPregnant: boolean;
	childrenUnder18: boolean;
	scoreLabel: ScoreLabel[];
	hasntRepliedInWorkspace: boolean;
	all?: boolean;
}

// Not the full object we get back, but seems to be what we need right now.
export interface SurveyPromotion {
	id: string;
	status: SurveyPromotionStatus;
	numberOfMatches: number;
}

export interface PromoteSurveyReturn {
	promoteSurvey: SurveyPromotion;
}

export interface FetchPromotionReturn {
	fetchSurveyPromotion: SurveyPromotion;
}

export interface FeedbackSurveyReturn {
	feedbackSurvey: IdTitle;
}
