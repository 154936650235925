import classNames from "classnames/bind";
import React, { ReactElement } from "react";
import { GET_TRAINING_SETS_PAGE } from "@/graphql/queries/ai-models-queries";
import { TrainingSet } from "@/models/ai-model";
import { DatasetsSort } from "@/datasets/containers/all-datasets-page/datasets-sort";
import { PaginatedList } from "@/shared/v2/paginated-list";

import styles from "./dataset-list.module.scss";

const cx = classNames.bind(styles);

export interface DatasetListProps {
  workspaceId: string;
  renderCreateCard: ReactElement;
  renderDatasetCard: (dataset: TrainingSet) => ReactElement;
  containerClassName?: string;
  loadingComponent?: ReactElement;
}

export const DatasetList = ({
  workspaceId,
  renderCreateCard,
  renderDatasetCard,
  containerClassName,
  loadingComponent,
}: DatasetListProps): ReactElement => {
  return (
    <PaginatedList<TrainingSet>
      query={GET_TRAINING_SETS_PAGE}
      queryName='trainingSetsPage'
      workspaceId={workspaceId}
      renderCreateCard={renderCreateCard}
      renderItem={renderDatasetCard}
      renderSortComponent={({ value, onChange, className }) => (
        <DatasetsSort value={value} onChange={onChange} className={className} />
      )}
      loadingComponent={loadingComponent}
      filterField='alias'
      searchPlaceholder='Search datasets'
      gridClassName={styles.datasetGrid}
      containerClassName={cx("container", containerClassName)}
    />
  );
};
