import {gql} from "@apollo/client";

export const SSO_PROVIDERS = gql`
  query SsoProviders {
    ssoProviders {
      id
      providerName
      domain
      active
      config
    }
  }
`

export const SSO_PROVIDER_CHECK = gql`
  query SsoProviderCheck($email: String!) {
    ssoProviderCheck(email: $email) {
      providerName
    }
  }
`
