import React, {ReactElement, useMemo} from "react";

import {isFileTag, isFileTagKey, isTrainingSetMediaEmbeddingsStatus, isTrainingSetMediaTypeAlias} from "@/shared/utility/type-guards";
import {TrainingSetMediaCriteria} from "@/models/ai-model";
import {TrainingSetMediaCriteriaChipBase as Base} from "./base";
import {Badge} from "@/workspace-settings/components/model-card/badge";
import {Body} from "@/shared/v2";

export interface TrainingSetMediaCriteriaChipProps {
  criteria: TrainingSetMediaCriteria;
  onRemove?: () => void;
}

export const TrainingSetMediaCriteriaChip = ({criteria, onRemove}: TrainingSetMediaCriteriaChipProps): ReactElement => {
  const component: ReactElement = useMemo(() => {
    if (isFileTagKey(criteria)) {
      return (
        <Base onRemove={onRemove}>
          <Body size="s" type="medium">
            {criteria.value}
          </Body>
        </Base>
      );
    }
    if (isFileTag(criteria)) {
      return (
        <Base onRemove={onRemove}>
          <Body size="s" type="medium">
            {`${criteria.key}:${criteria.value}`}
          </Body>
        </Base>
      );
    }
    if (isTrainingSetMediaEmbeddingsStatus(criteria)) {
      return (
        <Base onRemove={onRemove}>
          <Body size="s" type="medium">
            Status:
          </Body>
          <Badge text={criteria.embeddingsStatus} />
        </Base>
      )
    }
    if (isTrainingSetMediaTypeAlias(criteria)) {
      return (
        <Base onRemove={onRemove}>
          <Body size="s" type="medium">
            Type: {criteria.mimetypeAlias}
          </Body>
        </Base>
      )
    }

    throw new Error(`Unknown criteria type: ${criteria}`);
  }, [criteria, onRemove]);

  return component;
}
