import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {getTrainingSetMediaCriteriaId} from "@/shared";
import {TrainingSetMediaCriteriaChip} from "../training-set-media-criteria-chip";
import {useMediaCriteriaContext} from "@/context/media-criteria-context";

import styles from "./media-criteria.module.scss";

const cx = classNames.bind(styles);

export interface MediaCriteriaProps {
  className?: string;
}

export const MediaCriteria = ({className}: MediaCriteriaProps): ReactElement => {
  const {criteria, remove} = useMediaCriteriaContext();

  if (!criteria.length) {
    return <></>;
  };

  return (
    <div className={cx("mediaCriteria", className)}>
      {criteria.map(criteria => (
        <TrainingSetMediaCriteriaChip
          key={getTrainingSetMediaCriteriaId(criteria)}
          criteria={criteria}
          onRemove={() => remove(criteria)}
        />
      ))}
    </div>
  );
}
