import {gql} from "@apollo/client";

export const SSO_PROVIDER_DELETE = gql`
  mutation SsoProviderDelete($id: GUID!) {
    ssoProviderDelete(id: $id) {
      id
    }
  }
`;

export const SSO_PROVIDER_CREATE = gql`
  mutation SsoProviderCreate($input: SsoProviderInput!) {
    ssoProviderCreate(input: $input) {
      id
      providerName
      domain
      config
      active
    }
  }
`;

export const SSO_PROVIDER_UPDATE = gql`
  mutation SsoProviderUpdate($id: GUID!, $input: SsoProviderInput!) {
    ssoProviderUpdate(id: $id, input: $input) {
      id
      providerName
      domain
      config
      active
    }
  }
`;
