import {deleteUser} from "firebase/auth";
import {useMutation} from "@apollo/client";
import React, {ReactElement, useState} from "react";

import {Avatar, Button, Separator, Switch} from "../../../shared/v2";
// Import a suitable icon for integrations
import {BinDeleteIcon, LayersThreeIcon, ChevronRightIcon} from "../../../icons";
import {CREATE_USER_PICTURE} from "../../../graphql/mutations/mutations";
import {DELETE_ME, UPDATE_ME} from "../../../graphql/mutations/account-mutations";
import {DeleteAccountModal} from "../../../modals/delete-account";
import {EditPictureModal} from "../../../shared/v2/modals/edit-picture-modal";
import {OptionRow} from "../../../shared/v2/option-row";
import {PersonalInformationForm} from "./personal-information-form";
import {Picture} from "../../../models";
import {useAuthentication} from "../../../hooks/user/useAuthentication";
import {useNavigate} from "../../../route";
import {useThemeMode} from "../../../context/theme-mode-context";
import {useUserContext} from "../../../context/user-context";

import styles from "./personal-profile.module.scss";

export const PersonalProfile = (): ReactElement => {
	const navigate = useNavigate();
	const {isDarkMode, toggleTheme} = useThemeMode();
	const {user, isEnterpriseManagerOrSupport} = useUserContext();
	const {user: account, logout} = useAuthentication();

	const [updateUser] = useMutation(UPDATE_ME);
	const [deleteMe] = useMutation(DELETE_ME, {
		onCompleted: () => navigate({search: "deleted=true"}),
	});

	const [isEditPictureModalOpen, setIsEditPictureModalOpen] = useState<boolean>(false);
	const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState<boolean>(false);

	const handleAvatarUpdate = (picture: Picture) => {
		updateUser({
			variables: {
				id: user.id,
				changes: {pictureId: picture.id},
			},
		});
	}

	const handleDelete = (): void => {
		deleteMe({
			update(cache) {
				cache.reset();
			},
		});
		deleteUser(account!).then(() => {
			console.log("user deleted");
		  }).catch(error => {
			console.log("error deleting user: ", error);
		  });
		setIsDeleteAccountModalOpen(false);
		logout();
		window.location.href = window.location.origin;
	};

	return (
		<>
			<div className={styles.personalProfileWrapper}>
				<OptionRow
					description="Customize your profile by uploading an image. This picture will represent you across the platform, helping others to recognize and connect with you."
					title="Profile Image"
				>
					<Avatar
						size="md"
						firstName={user?.firstName}
						url={user?.picture?.thumbnail}
						key={user?.picture?.thumbnail}
					/>

					<Button
						onClick={() => setIsEditPictureModalOpen(true)}
						variant="outlined"
					>
							Edit
					</Button>
				</OptionRow>

				<Separator />

				<OptionRow
					description="Update and manage your personal details to ensure your profile remains current and accessible."
					title="Personal Information"
					className={styles.personalInformation}
				>
					<PersonalInformationForm />
				</OptionRow>

				<Separator />

				<OptionRow
					description="Switch to Dark Mode to reduce eye strain and save energy on compatible devices. Enjoy a sleek, dark-themed interface that's easier on the eyes, especially in low-light conditions."
					title="Dark Mode"
				>
					<Switch checked={isDarkMode} onChange={toggleTheme} />
				</OptionRow>

				<Separator />

				{/* Add Integrations Option Row */}
				<OptionRow
					description="Connect your accounts from third-party services like Google, Slack, etc., to enable AI assistants to interact with them on your behalf."
					title="Third-Party Integrations"
				>
					<Button
						variant="outlined"
						rightIcon={<ChevronRightIcon />} // Add an arrow icon for navigation indication
						onClick={() => navigate("/settings/integrations")}
					>
						Manage Integrations
					</Button>
				</OptionRow>

				<Separator />

				<OptionRow
					description="Permanently remove your account and all associated data. This action cannot be undone, so please proceed with caution."
					title="Delete Your Account"
				>
					<Button
						style="danger"
						leftIcon={<BinDeleteIcon />}
						onClick={() => setIsDeleteAccountModalOpen(true)}
					>
							Delete my account
					</Button>
				</OptionRow>
			</div>

			<EditPictureModal
				isOpen={isEditPictureModalOpen}
				title="Profile Image"
				description="Customize your profile by uploading an image."
				imageUrl={user?.picture?.thumbnail || ""}
				createPictureMutation={CREATE_USER_PICTURE}
				onUpdate={handleAvatarUpdate}
				onClose={() => setIsEditPictureModalOpen(false)}
			/>

			<DeleteAccountModal
				isOpen={isDeleteAccountModalOpen}
				onClose={() => setIsDeleteAccountModalOpen(false)}
				confirm={handleDelete}
			/>
		</>
	);
};
