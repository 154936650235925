import React, { ReactElement } from "react";

import { CreateCard } from "@/shared/v2/cards";
import { Button, Caption } from "@/shared/v2";
import { PlusSmallIcon } from "@/icons";
import { Link } from "@/hoc/layout/navigation/link";

import styles from "./create-dataset-card.module.scss";

export interface CreateDatasetCardProps {
  onClick: () => void;
  text: string;
}

export const CreateDatasetCard = ({
  onClick,
  text,
}: CreateDatasetCardProps): ReactElement => {
  return (
    <CreateCard wrapperClassname={styles.cardWrapper}>
      <Button style='brand' leftIcon={<PlusSmallIcon />} onClick={onClick}>
        {text}
      </Button>
      <Caption color='text-secondary' type='regular'>
        We keep your{" "}
        <Link
          to='https://help.vurvey.com/en/collections/9210870-ai-data-security-compliance'
          type='underline'
          text='data safe'
        />
      </Caption>
    </CreateCard>
  );
};
