import {Persona, TrainingSet, ChatConversation} from "@/models";
import {
  TrainingSetFile,
  ChatConversationMessage,
  Campaign,
  TrainingSetVideo,
  UpdateChatConversationChanges,
  SystemAgent,
  ChatConversationMessageRole
} from "@/models/ai-model";
import {Question} from "@/models/questions";
import {ChatActionTypes} from ".";
import {ConversationNameChanged, ConversationPersonaChanged, ConversationResponseCreated, ConversationResponseErrorOccured, ConversationResponseStateChanged, ConversationSourcesChanged, ConversationStarted, ConversationUserMessageCreated} from "@/models/subscriptions";

export interface NextQuestionOptions {
  query: string | null;
  persona: Persona | null;
  mention: Persona | null;
  instructions: string | null;
  image: File | null;
  document: TrainingSetFile | null;
  systemAgent: SystemAgent | null;
  message: ChatQueryMessage | ChatResponseMessage | null;
}

export enum ChatConversationMessageSubtype {
  FAKE_RESPONSE = "FAKE_RESPONSE",
  FAKE_QUERY = "FAKE_QUERY",
  QUERY = "QUERY",
  RESPONSE = "RESPONSE",
}

export type ChatFakeConversationMessageBase =
  Pick<ChatConversationMessage, "__typename" | "id" | "content" | "position" | "type" | "groundingData"> &
  Partial<Pick<ChatConversationMessage,
    "conversationId" |
    "conversation" |
    "conversationId" |
    "persona" |
    "survey" |
    "surveyId" |
    "position"
  >>
export type ChatQueryMessage = Omit<ChatConversationMessage, "role"> & {
  role: ChatConversationMessageRole.USER;
  subtype: ChatConversationMessageSubtype.QUERY;
};
export type ChatResponseMessage = Omit<ChatConversationMessage, "role"> & {
  role: Omit<ChatConversationMessageRole, ChatConversationMessageRole.USER>;
  subtype: ChatConversationMessageSubtype.RESPONSE;
};
export type ChatFakeQueryMessage = ChatFakeConversationMessageBase & {
  role: ChatConversationMessageRole.USER;
  subtype: ChatConversationMessageSubtype.FAKE_QUERY;
  sentAt?: string;
};
export type ChatFakeResponseMessage = ChatFakeConversationMessageBase & {
  role: Omit<ChatConversationMessageRole, ChatConversationMessageRole.USER>;
  subtype: ChatConversationMessageSubtype.FAKE_RESPONSE;
  stateHistory: string[];
};

export type ReducedChatConversationMessage = ChatQueryMessage | ChatResponseMessage | ChatFakeQueryMessage | ChatFakeResponseMessage;

export interface ReducedChatConversation {
  id: string | null;
  createdAt: string | null;
  name: string | null;
  question: string | null;
  messages: ReducedChatConversationMessage[];
  mode: ChatConversationMode;
  isPreview: boolean;
  aiPersona: Persona | null;
  persona: Persona | null;
  surveys: Campaign[];
  files: TrainingSetFile[];
  questions: Question[];
  trainingSets: TrainingSet[];
  videos: TrainingSetVideo[];
  tool: ChatToolValue | null;
  layout: ChatLayoutMode;
  nextQuestion: NextQuestionOptions;
}

export interface ChatActionFlags {
  isSendingQuestion: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isInitialLoading: boolean;
  isLoading: boolean;
  error: string | null; // Add error field
}

export interface ChatState {
  original: ChatConversation | null;
  changes: UpdateChatConversationChanges;
  current: ReducedChatConversation;
  actions: ChatActionFlags;
}

export enum ChatTool {
  COMMERCE = "COMMERCE",
  SOCIAL = "SOCIAL",
  IMAGE = "IMAGE",
  WEB = "WEB",
  GATED = "GATED",
  AGENT="AGENT",
}

export enum ChatSocialTool {
  TIKTOK = "SOCIAL_TIKTOK",
  REDDIT = "SOCIAL_REDDIT",
  LINKEDIN = "SOCIAL_LINKEDIN",
  YOUTUBE = "SOCIAL_YOUTUBE",
  X_TWITTER = "SOCIAL_X_TWITTER",
  INSTAGRAM = "SOCIAL_INSTAGRAM",
}

export enum ChatCommerceTool {
  AMAZON = "COMMERCE_AMAZON",
}

export enum ChatImageTool {
  STABLE_DIFFUSION = "IMAGE_STABLE_DIFFUSION",
  DALLE = "IMAGE_DALLE",
  IMAGEN = "IMAGE_IMAGEN",
  ALL = "IMAGE_ALL",
}

export enum ChatWebTool {
  SCRAPE = "WEB_SCRAPE",
  SCRAPE_ADVANCED = "WEB_SCRAPE_ADVANCED",
  WEB = "WEB_WEB",
}

export enum ChatGatedTool {
  WORKSPACE = "GATED_WORKSPACE",
}

export enum ChatConversationMode {
  SMART_TOOLS = "smart_tools",
  SMART_SOURCES = "smart_sources",
  CONVERSATION = "conversation",
}

export enum ChatLayoutMode {
  HOME = "home",
  CHAT = "chat",
}

export type ChatToolValue = ChatSocialTool | ChatCommerceTool | ChatImageTool | ChatWebTool | ChatGatedTool;

export type UpdateNextQuestionOptionsPayload = Partial<Omit<NextQuestionOptions, "query">>;

export type UpdateConversationPayload = Partial<Pick<ReducedChatConversation,
  | "name"
  | "mode"
  | "tool"
  | "aiPersona"
  | "files"
  | "questions"
  | "trainingSets"
  | "videos"
  | "surveys"
  | "isPreview"
>> & {
  nextQuestion?: UpdateNextQuestionOptionsPayload;
}

export type CreateConversationPayload = Omit<UpdateConversationPayload, "nextQuestion">;

export type SendQuestionPayload = {
  query: string;
  createNewConversation?: boolean;
  conversationInput?: CreateConversationPayload;
  nextQuestionInput?: Omit<UpdateNextQuestionOptionsPayload, "query">;
  mode?: ChatConversationMode; // Add mode to allow passing directly to sendQuestion
}

export type RevertActionPayload = {
  action: ChatActionTypes.UPDATE_CONVERSATION | ChatActionTypes.SEND_QUESTION | ChatActionTypes.CREATE_CONVERSATION;
}

export type SyncLocationStatePayload = {
  chat: ReducedChatConversation;
  invokeSendQuestion: string;
}

export type HandleConversationEventPayload =
  ConversationNameChanged |
  ConversationSourcesChanged |
  ConversationPersonaChanged |
  ConversationResponseErrorOccured |
  ConversationResponseStateChanged |
  ConversationUserMessageCreated |
  ConversationResponseCreated |
  ConversationStarted;
