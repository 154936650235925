import React, { ReactElement } from "react";

import { CampaignCard } from "./campaign-card";
import { CreateCampaignCard } from "./create-campaign-card";
import { GET_ALL_SURVEYS } from "../../../graphql/queries/survey-queries";
import { Survey } from "../../../models/survey";
import { useWorkspaceContext } from "../../../context/workspace-context";
import { CampaingsSort } from "./campaigns-sort";
import { PaginatedList } from "@/shared/v2/paginated-list";

import styles from "./survey-dashboard.module.scss";

const SurveyDashboard = (): ReactElement => {
  const {
    workspace: { id: workspaceId },
  } = useWorkspaceContext();

  return (
    <PaginatedList<Survey>
      query={GET_ALL_SURVEYS}
      queryName='workspaceSurveys'
      workspaceId={workspaceId}
      renderCreateCard={<CreateCampaignCard />}
      renderItem={(campaign) => (
        <CampaignCard key={campaign.id} campaign={campaign} />
      )}
      renderSortComponent={({ value, onChange, className }) => (
        <CampaingsSort
          value={value}
          onChange={onChange}
          className={className}
        />
      )}
      containerClassName={styles.fullWidth}
      searchPlaceholder='Search campaigns'
    />
  );
};

export { SurveyDashboard };
