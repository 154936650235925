import React, { createRef, ReactElement } from "react";
import classNames from "classnames/bind";

import { useThemeMode } from "@/context/theme-mode-context";
import { TrainingSet } from "@/models";
import { Checkbox, Subheader, Body, Card, Separator } from "@/shared/v2";

import styles from "./training-card-select.module.scss";
import { OverflowTooltip } from "@/shared/v2/tooltip/overflow-tooltip";
import { FileCorrectIcon } from "@/icons";
import { FileUploader } from "@/datasets/components/file-uploader";

const cx = classNames.bind(styles);

export interface TrainingCardSelectProps {
  trainingSet: TrainingSet;
  onSelected: (trainingSet: TrainingSet) => void;
  isSelected: boolean;
}

export const TrainingCardSelect = ({
  trainingSet,
  onSelected,
  isSelected,
}: TrainingCardSelectProps): ReactElement => {
  const { isDarkMode } = useThemeMode();
  const subheaderRef = createRef<HTMLDivElement>();

  const filesCount = trainingSet.files.length + trainingSet.videos.length;

  return (
    <Card
      className={cx("set", { selected: isSelected, isDarkMode })}
      onClick={() => onSelected(trainingSet)}>
      <div className={styles.contentWrapper}>
        <OverflowTooltip>
          <Subheader
            ref={subheaderRef}
            type='semibold'
            className={styles.header}>
            {trainingSet.alias}
          </Subheader>
        </OverflowTooltip>
        <Body size='s' className={styles.description} color='text-secondary'>
          {trainingSet.description}
        </Body>
      </div>
      <div className={styles.bottomSection}>
        <Separator />
        <div className={styles.filesWrapper}>
          <div className={styles.filesSection}>
            <FileCorrectIcon className={styles.fileIcon} />
            <Body size='s' type='medium' className={styles.fileText}>
              {filesCount} {filesCount === 1 ? "file" : "files"}
            </Body>
          </div>
        </div>
      </div>
    </Card>
  );
};
