import React, {ReactElement, useMemo} from "react";

import {MediaCriteria, MediaTable} from "@/shared/v2";
import {useSearchContext} from "@/modals/select-ai-sources-modal/contexts/search-context";
import {useSelectedSourcesContext} from "@/modals/select-ai-sources-modal/contexts/selected-sources-context";

import styles from "./select-media-tab.module.scss";

export const SelectMediaTab = (): ReactElement => {
  const {search} = useSearchContext();
  const {selected, add: select, remove: unselect} = useSelectedSourcesContext();

  const selectedMedia = useMemo(() => [...selected.files, ...selected.videos, ...selected.trainingSets], [selected]);
  return (
    <div className={styles.selectMediaTab}>
      <MediaCriteria />

			<MediaTable
        search={search}
        selected={selectedMedia}
        select={select}
        unselect={unselect}
      />
    </div>
  );
}
