import { Button } from "@/shared/v2";
import React, { ReactElement, useMemo, useState } from "react";

import styles from "./sso-providers-page.module.scss";
import { Body, SearchInput, Spinner } from "@/shared/v2/";
import { Column } from "react-table";
import { Table } from "@/shared/components/table";
import { AlignText } from "@/shared/typography/align-text";
import { PenEditIcon } from "@/icons";
import { DotsIcon } from "@/icons";
import { Dropdown } from "@/shared/v2/dropdown";
import { BinDeleteIcon } from "@/icons";
import { SSO_PROVIDERS } from "@/graphql/queries/sso-providers-queries";
import { useMutation, useQuery } from "@apollo/client";
import {
  SSO_PROVIDER_CREATE,
  SSO_PROVIDER_DELETE,
  SSO_PROVIDER_UPDATE,
} from "@/graphql/mutations/sso-providers-mutation";
import { Badge } from "@/workspace-settings/components/model-card/badge";
import { ConfirmActionModal } from "@/shared/v2/modals/confirm-action-modal";
import { SSOModal, SsoProviderChanges } from "@/admin/components/sso-modal";
import { useToastContext } from "@/context/toast-context";

export interface SsoProvider {
  id: string;
  providerName: string;
  domain: string;
  active: boolean;
  config: string;
}

export const SSOProvidersPage = (): ReactElement => {
  const { updateToast } = useToastContext();

  const [searchValue, setSearchValue] = useState<string>("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [selectedProvider, setSelectedProvider] = useState<SsoProvider | null>(
    null
  );
  const [isSsoProviderModalOpen, setIsSsoProviderModalOpen] =
    useState<boolean>(false);
  const { data: ssoProvidersData, loading } = useQuery(SSO_PROVIDERS, {
    fetchPolicy: "cache-first",
  });

  const [deleteSsoProvider] = useMutation(SSO_PROVIDER_DELETE, {
    refetchQueries: [{ query: SSO_PROVIDERS }],
  });

  const [createSsoProvider] = useMutation(SSO_PROVIDER_CREATE, {
    refetchQueries: [{ query: SSO_PROVIDERS }],
  });

  const [updateSsoProvider] = useMutation(SSO_PROVIDER_UPDATE);

  const filteredSsoProviders = useMemo(() => {
    if (!ssoProvidersData?.ssoProviders) {
      return [];
    }

    return ssoProvidersData.ssoProviders.filter((provider: SsoProvider) => {
      return provider.providerName
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    });
  }, [ssoProvidersData, searchValue]);

  const columns: Column<SsoProvider>[] = [
    {
      Header: "Provider Name",
      accessor: "providerName",
      Cell: ({ value }: { value: string }) => <Body size='s'>{value}</Body>,
    },
    {
      Header: "Domain",
      accessor: "domain",
      Cell: ({ value }: { value: string }) => <Body size='s'>{value}</Body>,
    },
    {
      Header: "Active",
      accessor: "active",
      Cell: ({ value }: { value: boolean }) => (
        <Badge text={value ? "active" : "inactive"} />
      ),
    },
    {
      Header: "Config",
      accessor: "config",
      Cell: ({ value }: { value: string }) => <Body size='s'>{value}</Body>,
    },
    {
      id: "options",
      Header: "",
      maxWidth: 20,
      disableSortBy: true,
      Cell: (data) => {
        return (
          <AlignText align='right'>
            <Dropdown
              position='bottom-start'
              trigger={<DotsIcon className={styles.trigger} />}
              items={[
                {
                  label: "Edit",
                  onClick: () => {
                    setSelectedProvider(data.row.original);
                    setIsSsoProviderModalOpen(true);
                  },
                  icon: <PenEditIcon />,
                },
                {
                  label: "Delete",
                  onClick: () => {
                    setSelectedProvider(data.row.original);
                    setIsDeleteModalOpen(true);
                  },
                  color: "danger",
                  icon: <BinDeleteIcon />,
                },
              ]}
            />
          </AlignText>
        );
      },
    },
  ];

  const handleDelete = (provider: SsoProvider | null): void => {
    if (!provider) {
      return;
    }

    deleteSsoProvider({
      variables: {
        id: provider.id,
      },
    });

    setSelectedProvider(null);
    setIsDeleteModalOpen(false);
  };

  const closeSsoProviderModal = (): void => {
    setIsSsoProviderModalOpen(false);
    setSelectedProvider(null);
  };

  const handleSave = (changes: SsoProviderChanges): void => {
    const input = changes;
    input.config = JSON.stringify(changes.config);

    if (selectedProvider) {
      updateSsoProvider({
        variables: {
          id: selectedProvider.id,
          input,
        },
        onError: (error) => {
          updateToast({
            type: "failure",
            description: error.message,
          });
        },
      });
    } else {
      createSsoProvider({
        variables: {
          input,
        },
        onError: (error) => {
          updateToast({
            type: "failure",
            description: error.message,
          });
        },
      });
    }

    closeSsoProviderModal();
  };

  const renderTable = (): ReactElement => {
    if (loading) {
      return <Spinner />;
    }

    if (!ssoProvidersData?.ssoProviders) {
      return <Body>No SSO providers found</Body>;
    }

    return <Table columns={columns} data={filteredSsoProviders} />;
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.topSection}>
          <SearchInput
            placeholder='Search tags'
            value={searchValue}
            onChange={setSearchValue}
          />
          <Button
            onClick={() => {
              setIsSsoProviderModalOpen(true);
            }}>
            Add SSO Provider
          </Button>
        </div>
        {renderTable()}
      </div>

      <ConfirmActionModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() => handleDelete(selectedProvider)}
        title='Delete SSO Provider'
        description='Are you sure you want to delete this SSO provider?'
      />

      <SSOModal
        isOpen={isSsoProviderModalOpen}
        onClose={closeSsoProviderModal}
        onSave={handleSave}
        provider={selectedProvider}
      />
    </>
  );
};
