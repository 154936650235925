import React, { ReactElement, useState } from "react";
import { SAMLAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../utils/firebase";
import { Button, Input, Modal } from "@/shared/v2";
import { KeyLoginCircleIcon } from "@/icons";
import { useToastContext } from "@/context/toast-context";
import styles from "./Buttons.module.scss";
import { useLazyQuery } from "@apollo/client";
import { SSO_PROVIDER_CHECK } from "@/graphql/queries/sso-providers-queries";

export interface SsoSignInButtonProps {
  text?: string;
  id?: string;
}

export const SsoSignInButton = ({
  text = "Sign in with SSO",
  id,
}: SsoSignInButtonProps): ReactElement => {
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const { updateToast } = useToastContext();

  const [checkSsoProvider, { loading }] = useLazyQuery(SSO_PROVIDER_CHECK);

  const handleSignIn = async (event): Promise<void> => {
    event?.preventDefault?.();

    const providerData = await checkSsoProvider({
      variables: {
        email,
      },
      onError: (error) => {
        updateToast({
          type: "failure",
          description: error.message,
        });
      },
    });

    if (!providerData || !providerData.data.ssoProviderCheck.providerName) {
      updateToast({
        type: "failure",
        description: "There in no provider with this email",
      });
      return;
    }

    const provider = new SAMLAuthProvider(
      providerData.data.ssoProviderCheck.providerName
    );
    signInWithPopup(auth, provider);
  };

  const closeModal = (): void => {
    setIsEmailModalOpen(false);
    setEmail("");
  };

  return (
    <>
      <button
        id={id}
        onClick={() => setIsEmailModalOpen(true)}
        className={styles.authButton}>
        <KeyLoginCircleIcon />
        <span className={styles.authButtonText}>{text}</span>
      </button>

      <Modal
        title='Enter your work email address'
        isOpen={isEmailModalOpen}
        onClose={closeModal}>
        <form className={styles.form} onSubmit={handleSignIn}>
          <Input
            type='email'
            placeholder='Email'
            value={email}
            onChange={setEmail}
            onEnter={handleSignIn}
          />

          <Button type='submit' disabled={!email || loading}>
            Sign in with SSO
          </Button>
        </form>
      </Modal>
    </>
  );
};
