import {gql} from "@apollo/client";

export const LOGO_FRAGMENT = gql`
	fragment PictureFields on UserPicture {
		id
		badge:url (version:BADGE)
		thumbnail: url(version:THUMBNAIL)
	}
`;

export const USER_FRAGMENT = gql`
	fragment UserFields on User {
		id
		status
		firstName
		lastName
		lastInitial
		pictureId
		picture {
			...PictureFields
		}
		gender
		birthdate
		age
		city
		countryId
		country {
			id
			name
		}
	}
	${LOGO_FRAGMENT}
`;

export const SEGMENT_FRAGMENT = gql`
	fragment SegmentFields on Segment {
		id
		name
		workspaceId
		memberCount
		createdAt
	}
`;

/* Mostly used for update function for now */
export const PERSONA_FRAGMENT = gql`
	fragment PersonaFields on AiPersona {
		id
		name
		description
		voiceId
		voiceName
		personaCategory {
			id
			name
		}
		picture {
			id
			url(version: THUMBNAIL)
		}
	}
`;
export const ORCHRESTRA_FRAGMENT = gql`
	fragment OrchestrationFields on AiOrchestration {
		id
		workspaceId
		name
		description
		outputTypeId
	}
`;

export const TASK_FRAGMENT = gql`
	fragment TaskFields on AiPersonaTask {
		logs {
			id
			personaTaskId
			outputLog
			createdAt
			updatedAt
		}
		id
		name
		instructions
		index
		tools {
			id
			name
			code
		}
	}
`

export const TRANSCRIPT_FRAGMENT = gql`
	fragment TranscriptFields on Transcript {
		id
		words {
			word
			startAt
			endAt
		}
		highlights {
			id
			transcriptId
			firstWord
			lastWord
		}
		text
		englishTranslation
	}
`;

export const REWARD_FRAGMENT = gql`
	fragment RewardFields on Reward {
		id
		createdAt
		configuration
		response {
			id
		}
	}
`;

export const DYNAMIC_SEGMENT_FRAGMENT = gql`
	fragment DynamicSegmentFields on DynamicSegment {
		id
		name
		workspaceId
		matchType
		rules {
			id
			propertyId
			modelOwnProperty
			value
			comparator
		}
		memberCount
		createdAt
	}
`;

export const BRAND_FRAGMENT = gql`
	fragment BrandFields on Brand {
		id
		name
		description
		logoPicture {
			id
			medium:url(version: MEDIUM)
		}
		visible
		brandAbout
		bannerPicture {
			id
			original:url(version: ORIGINAL)
			# May not need FULL_SIZE
		}
		colors {
			color
			type
		}
		buttonUrl
		primaryCategory {
			id
			name
		}
		secondaryCategories {
			id
			name
		}
		benefits {
			id
			name
		}
		countryOfOriginId
		targetCountries {
			id
			name
		}
		activities {
			id
			name
		}
	}
`;
/**
 * Fragment for a clip on a reel
 */
export const CLIP_FRAGMENT = gql`
	fragment ClipFields on Clip {
		id
		index
		answer {
			id
			user {
				id
				firstName
				lastName
				lastInitial
				creatorTag
			}
			video {
				id
				duration
				thumbnail:url(version:THUMBNAIL)
				video:url(version:VP8_VORBIS)
				mp4:url(version:H264_AAC)
				transcript {
					id
					words {
						word
						startAt
						endAt
					}
					highlights {
						id
						transcriptId
						firstWord
						lastWord
					}
					corrections {
						id
						transcriptId
						firstWord
						lastWord
						correction
					}
				}
			}
			question {
				survey {
					name
				}
			}
		}
		sourceVideo {
			id
			duration
			originalFilename
			thumbnail:url(version:THUMBNAIL)
			video:url(version:VP8_VORBIS)
			mp4:url(version:H264_AAC)
			transcript {
					id
					words {
						word
						startAt
						endAt
					}
					highlights {
						id
						transcriptId
						firstWord
						lastWord
					}
					corrections {
						id
						transcriptId
						firstWord
						lastWord
						correction
					}
				}
		}
		reel {
			id
			name
			videoStatus
		}
		startTime
		endTime
	}
`;
export const CONTACT_FRAGMENT = gql`
	fragment ContactFields on User {
		id
		status
		lastActive
		firstName
		lastName
		lastInitial
		creatorTag
		picture {
			...PictureFields
		}
		birthdate
		age
		city
		country {
			id
			name
		}
	}
	${LOGO_FRAGMENT}
`;

export const SURVEY_FRAGMENT = gql`
	fragment SurveyFields on Survey {
		id
		workspaceId
		name
		title
		introduction
		instructions
		startText
		accessLevel
		status
		questionCount
		responseUrl
		createdAt
		updatedAt
		closeAt
		closedAt
		firstOpenedAt
		autoClosed
		closeAfterResponsesCount
		notifications {
			id
			hoursBefore
		}
		disclaimer
		multiResponse
		thankYouMessage
		language {
			id
			name
		}
		objectives
		redirectUrl
	}
`;
export const SURVEY_DASHBOARD_FRAGMENT = gql`
	fragment SurveyPageFields on Survey {
		id
		name
		status
		questionCount
		completedResponseCount
		responseCount
		surveyInsights {
			summary
		}
		answersPreview {
			id
			video {
				badge:url(version:BADGE)
			}
		}
		creator {
			id
			firstName
			lastName
		}
		totalVideoDuration
	}
`;

export const UPLOAD_FILE_FRAGMENT = gql`
	fragment QuestionFileFields on QuestionImage {
		id
		transcodingStatus
		full: url (version:FULL_SIZE)
		small: url (version:SMALL)
	}
`;

export const CHOICE_FRAGMENT = gql`
	fragment ChoiceFields on Choice {
		id
		index
		text
		answerCount
		frequency
	}
`;
/**
 * I may want to clean this up. Probably don't need to grab all of these fields
 * with every question query / mutation.
 */
export const QUESTION_FRAGMENT = gql`
	fragment QuestionFields on Question {
		id
		surveyId
		videoResponse
		required
		type
		index
		text
		imageId
		points
		image {
			...QuestionFileFields
		}
		video {
			id
			transcodingStatus
			small:url(version:SMALL_STILL)
			video:url(version:VP8_VORBIS)
			mp4:url(version:H264_AAC)
		}
		arModel {
			id
			glbUrl:url(version:GLB)
			usdzUrl:url(version:USDZ)
		}
		choices {
			...ChoiceFields
		}
		followUp
		highlightCount
		answerCount
		shuffle
		timeLimit
		min
		max
		na
		rank
		step
		subtype
		leftLabel
		rightLabel
	}
	${UPLOAD_FILE_FRAGMENT}, ${CHOICE_FRAGMENT}
`;

export const FEEDBACK_QUEST_FRAGMENT = gql`
	fragment FeedbackQuesFields on Question {
		id
		answerCount
		text
		type
		videoResponse
		timeLimit
	}
`;

export const QUESTION_TEMPLATE_FRAGMENT = gql`
	fragment QuestionTemplateFields on QuestionTemplate {
		id
		text
		useCount(workspaceId: $workspaceId)
		type
		max
		rank
		subtype
		workspaceId
	}
`;

export const ANSWER_VIDEO_FRAGMENT = gql`
	fragment AnswerVideoFields on AnswerVideo {
		id
		originalFilename
		transcodingStatus
		duration
		thumbnail: url(version:THUMBNAIL)
	}
`;

export const ANSWER_VIDEO_TRANSCRIPT_FRAGMENT = gql`
	fragment AnswerTranscript on AnswerVideo {
		id
		originalFilename
		transcodingStatus
		duration
		thumbnail: url(version:THUMBNAIL)
		transcript {
			...TranscriptFields
		}
	}
	${TRANSCRIPT_FRAGMENT}
`;

export const ANSWER_FRAGMENT = gql`
	fragment AnswerFields on Answer {
		id
		userId
		createdAt
		videoId
		video {
			...AnswerVideoFields
		}
		likeCount
		likedAt
		highlightCount
	}
	${ANSWER_VIDEO_FRAGMENT}
`;

export const ANSWER_WITH_TRANSCRIPT_FRAG = gql`
	fragment AnswerVideoTranscript on Answer {
			id
			userId
			createdAt
			videoId
			video {
				...AnswerTranscript
			}
			likeCount
			likedAt
			highlightCount
		}
		${ANSWER_VIDEO_TRANSCRIPT_FRAGMENT}
`;
/**
 * Mostly made to deal with mutation updates
 * Might be useful elsewhere, though
 */
export const REEL_FRAGMENT = gql`
	fragment ReelFields on Reel {
		id
		name
		description
		creator {
			id
			name
		}
		createdAt
		duration
	}
`;

export const ATTR_RULE_FRAGMENT = gql`
	fragment AttrRuleFields on AttributeRule {
		id
		action
		picked
		questionId
		attributeId
	}
`;

export const ATTR_CATEGORY_FRAGMENT = gql`
	fragment AttrCategoryFields on AttributeCategory {
		id
		workspaceId
		name
		isDefault
	}
`;

export const ATTRIBUTE_FRAGMENT = gql`
 fragment AttributeFields on Attribute {
	 id
	 name
	 type
	 creator {
		 name
	 }
	 rules {
		...AttrRuleFields
	 }
	 category {
		 ...AttrCategoryFields
	 }
	}
	${ATTR_RULE_FRAGMENT}
	${ATTR_CATEGORY_FRAGMENT}
`;
