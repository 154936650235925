import React, { ReactElement, useState } from "react";
import { useMutation } from "@apollo/client";
import classNames from "classnames/bind";
import { useNavigate } from "react-router";

import { CREATE_TRAINING_SET } from "../../../graphql/mutations/ai-mutations";
import { useWorkspaceContext } from "../../../context/workspace-context";
import { GET_TRAINING_SETS_PAGE } from "../../../graphql/queries/ai-models-queries";
import { useToastContext } from "../../../context/toast-context";
import { BaseModalProps, Button, Modal } from "../../../shared/v2";
import { useThemeMode } from "../../../context/theme-mode-context";
import { TrainingSetForm } from "../../../datasets/components";

import styles from "./add-training-set-modal.module.scss";

const bStyles = classNames.bind(styles);

export interface AddTrainingSetModalProps extends BaseModalProps {
  saveText?: string;
  onComplete?: (id: string) => void;
}

export const AddTrainingSetModal = (
  props: AddTrainingSetModalProps
): ReactElement => {
  const navigate = useNavigate();
  const { updateToast } = useToastContext();
  const { isDarkMode } = useThemeMode();

  const { isOpen, onClose, saveText = "Create dataset", onComplete } = props;
  const [createTrainingSet, { loading: isCreating }] = useMutation(
    CREATE_TRAINING_SET,
    {
      refetchQueries: [GET_TRAINING_SETS_PAGE],
    }
  );
  const {
    workspace: { id: workspaceId },
  } = useWorkspaceContext();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleClose = (): void => {
    setName("");
    setDescription("");
    onClose();
  };

  const openDatasetPage = (id: string): void => {
    navigate(`/datasets/dataset/${id}`);
  };

  const handleCreate = (): void => {
    createTrainingSet({
      variables: {
        name,
        workspaceId,
        description,
      },
      onCompleted: (data) => {
        setName("");
        setDescription("");
        onComplete
          ? onComplete(data.createTrainingSet.id)
          : openDatasetPage(data.createTrainingSet.id);
        onClose();
      },
      onError: (error) => {
        const errorMessage = error.message.includes(
          "trainingsets_workspaceid_name_unique"
        )
          ? "The name needs to be unique across workspace."
          : error.message;

        updateToast({
          description: errorMessage,
          type: "failure",
        });
      },
    });
  };

  return (
    <Modal
      title='Create new dataset'
      description='A dataset groups data for Al analysis,
      enhancing targeted insights and content generation based on your specific needs.'
      isOpen={isOpen}
      onClose={handleClose}
      className={bStyles("modal", { isDarkMode })}>
      <TrainingSetForm
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
      />
      <Button
        disabled={!name || isCreating}
        onClick={handleCreate}
        className={styles.button}>
        {saveText}
      </Button>
    </Modal>
  );
};
