import { Button, Dropdown, DropdownItem } from "@/shared/v2";

import React, { ReactElement, useMemo, useState } from "react";

import styles from "./history-item.module.scss";
import classNames from "classnames/bind";
import { Body } from "@/shared/v2";
import { AssistiveChip } from "@/shared/v2/assistive-chip";
import { PersonasList } from "../../personas-list";
import { Persona } from "@/models/persona";
import {
  ChevronDownIcon,
  CopyDocumentsIcon,
  FileTextIcon,
  HandOutputStarIcon,
  ReloadArrowIcon,
} from "@/icons";
import { EyePreviewIcon } from "@/icons";
import { AiOrchestrationHistory } from "@/models/ai-orchestration";
import { useMutation } from "@apollo/client";
import { REGENERATE_REPORT } from "@/graphql/mutations/ai-orchestration-mutations";
import { useWorkflowEventsContext } from "@/context/workflow-contexts";
import { useToastContext } from "@/context/toast-context";
import { ChooseDatasetModal } from "@/workflow/modals/choose-dataset-modal";
import { useThemeMode } from "@/context/theme-mode-context";

interface RegenerateReportData {
  regenerateAiOrchestrationReport: AiOrchestrationHistory;
}

const bStyles = classNames.bind(styles);

interface HistoryItemProps {
  history: AiOrchestrationHistory;
  currentHistory?: AiOrchestrationHistory;
  setCurrentHistory: (history: AiOrchestrationHistory) => void;
  historyLength: number;
  index: number;
}

export const HistoryItem = ({
  history,
  currentHistory,
  setCurrentHistory,
  historyLength,
  index,
}: HistoryItemProps): ReactElement => {
  const { isDarkMode } = useThemeMode();
  const { updateToast } = useToastContext();

  const { isGeneratingReport } = useWorkflowEventsContext();
  const [isChooseDatasetModalOpen, setIsChooseDatasetModalOpen] =
    useState(false);

  const [regenerateReport] = useMutation<RegenerateReportData>(
    REGENERATE_REPORT,
    {
      onCompleted: (data: RegenerateReportData) => {
        if (data.regenerateAiOrchestrationReport) {
          currentHistory?.reportUrl ===
            data.regenerateAiOrchestrationReport.reportUrl;
        }

        updateToast({
          description: "New report generated successfully.",
          type: "success",
        });
      },
    }
  );

  const handleRegenerateReport = (historyId: string) => {
    updateToast({
      description:
        "Generating new report in progress... This can take around 2-3 minutes.",
      type: "informational",
    });
    regenerateReport({ variables: { historyId } });
  };

  const formatDate = (entry: AiOrchestrationHistory): string => {
    const date = new Date(entry.completedAt);
    return date.toLocaleString().split(",")[0];
  };

  const dropdownItems = useMemo(() => {
    const items: DropdownItem[] = [];

    if (history?.reportUrl) {
      items.push({
        tooltip: "View the Output in PDF report.",
        icon: <EyePreviewIcon />,
        label: "View PDF",
        onClick: () => {
          window.open(history?.reportUrl, "_blank");
        },
      });

      if (history?.reportWordUrl) {
        items.push({
          tooltip: "View the Output in Word report.",
          icon: <FileTextIcon />,
          label: "View Word",
          onClick: () => {
            window.open(history?.reportWordUrl, "_blank");
          },
        });
      }
    }

    if (history?.reportUrl || history?.reportWordUrl) {
      items.push({
        tooltip: "Save the Output to a dataset.",
        icon: <CopyDocumentsIcon />,
        label: "Save to Dataset",
        onClick: () => setIsChooseDatasetModalOpen(true),
      });
    }

    if (
      history?.reportUrl ||
      history?.reportWordUrl ||
      history.status === "failed"
    ) {
      items.push({
        tooltip:
          "Generate a new report using existing agent outputs. Will overwrite the existing report. Can take around 2-3 minutes.",
        icon: <ReloadArrowIcon />,
        label: "Rerun Reports",
        disabled:
          isGeneratingReport ||
          history.processState?.includes("Generating") ||
          history.processState?.includes("regenerating"),
        onClick: () => handleRegenerateReport(history.id),
      });
    }

    return items;
  }, [
    history.reportUrl,
    history.reportWordUrl,
    history.status,
    history.processState,
    isGeneratingReport,
  ]);

  return (
    <>
      <div
        className={bStyles("entry", {
          active: currentHistory?.id === history.id,
          isDarkMode,
        })}
        key={history.id}
        onClick={() => setCurrentHistory(history)}>
        <div className={styles.firstRow}>
          <div className={styles.textWrapper}>
            <Body
              size='xs'
              type='medium'
              color='text-tertiary'
              className={styles.date}>
              {formatDate(history)}
            </Body>
            <div className={styles.runWrapper}>
              <Body size='base' type='medium'>
                Run {historyLength - index}
              </Body>
              {!history?.reportUrl && !history?.reportWordUrl && (
                <AssistiveChip
                  text={history.status === "failed" ? "Failed" : "In progress"}
                />
              )}
            </div>
          </div>

          <PersonasList
            className={styles.personasList}
            size='sm'
            personas={
              history.outputHistoryTasks
                .map((task) => task.persona)
                .filter((persona) => Boolean(persona)) as Persona[]
            }
            max={4}
          />
        </div>
        <div className={styles.secondRow}>
          <Dropdown
            position='bottom-start'
            trigger={
              <Button
                variant='text'
                size='small'
                leftIcon={<HandOutputStarIcon />}
                rightIcon={<ChevronDownIcon />}>
                Output Actions
              </Button>
            }
            items={dropdownItems}
          />
        </div>
      </div>

      {isChooseDatasetModalOpen && (
        <ChooseDatasetModal
          isOpen={isChooseDatasetModalOpen}
          onClose={() => setIsChooseDatasetModalOpen(false)}
          reportUrl={history?.reportUrl || history?.reportWordUrl}
        />
      )}
    </>
  );
};
