import {UPDATE_SURVEY} from "../../../graphql/mutations/survey-mutations";
import React, {ReactElement, useCallback, useContext, useMemo, useState} from "react";
import {Icon, Select} from "../../../shared";
import {useMutation, useQuery} from "@apollo/client";
import {Descendant} from "slate";
import {GET_INTRO_IMAGE_UPLOAD} from "../../../graphql/queries/client-queries";
import {IntroImageUploadModal} from "../../../modals/intro-image-upload";
import {IntroMedia} from "../../components/intro-media";
import {RichTextInput} from "../../../shared/components/rich-text-input";
import {SurveyContext} from "../../../context/survey-context";
import styles from "./survey-intro.module.scss";
import {toggleIntroImageUpload} from "../../../cache";
import {Form, FormControl} from "../../components/builder-form";
import {TremendousCurrencyType, WorkspaceFundingAndCampaignReturn} from "../../../models/rewards";
import {CURRENCY_SELECT_VALUES} from "../../../shared/constants/constants";
import {GET_FUNDING_SOURCES} from "../../../graphql/queries/reward-queries";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {useUserContext} from "../../../context/user-context";
import {Link} from "../../../route";
import {Card} from "../../../shared/layout/card";
import {UpdateSurveyKeys, UpdateSurveyReturn, UpdateSurveyVars} from "../../../models/survey";
import {Button, Input, NumberInput, CurrencyInput} from "../../../shared/v2";
import {AutoResizeTextarea} from "../../../shared/v2/inputs";
import {DownloadIcon, EyeIcon} from "../../../icons";
import config from "../../../config";
import {useToastContext} from "../../../context/toast-context";
import {client} from "../../../shared/utility/client";
import {GET_WORKSPACE_CREDIT_BALANCE} from "../../../graphql/queries/credit-queries";


const titlePlaceholder = "Enter your title...";
const introPlaceholder = "Enter your introduction...";

/**
 * Returns the Survey Intro page, and allows the user to update the introduction
 * text as well as the start button text if they want.
 */
const SurveyIntro = (): ReactElement => {
	const {survey, questions} = useContext(SurveyContext);
	const {workspace: {id, permissions}} = useWorkspaceContext();
	const {isEnterpriseManagerOrSupport} = useUserContext();
	const {updateToast} = useToastContext();
	const [incentive, setIncentive] = useState(survey.incentiveAmount || "");
	const [creditAmount, setCreditAmount] = useState(survey.creditAmount || 0);
	const [disclaimer, setDisclaimer] = useState(survey.disclaimer || "");
	const [title, setTitle] = useState(survey.title || "");
	const [estimatedCompletionTime, setEstimatedCompletionTime] = useState(survey.estimatedCompletionTime || 0);
	const [startText, setStartText] = useState(survey.startText || "");
	const [currency, setCurrency] =
	useState<TremendousCurrencyType>(survey.incentiveCurrency);

	const {data: modal} = useQuery(GET_INTRO_IMAGE_UPLOAD);
	const {data} = useQuery<WorkspaceFundingAndCampaignReturn>(GET_FUNDING_SOURCES, {
		skip: !id,
		variables: {id},
	});
	
	// Fetch workspace credit balance
	const {data: creditBalanceData, loading: creditBalanceLoading, error: creditBalanceError, refetch: refetchCreditBalance} = useQuery(GET_WORKSPACE_CREDIT_BALANCE, {
		skip: !id,
		variables: {workspaceId: id},
		fetchPolicy: "network-only",
		onError: (error) => {
			console.error("Error fetching credit balance:", error);
			updateToast({
				description: "Failed to load workspace credit balance. Retrying...",
				type: "failure"
			});
			// Auto-retry after a short delay
			setTimeout(() => {
				refetchCreditBalance().catch(err => 
					console.error("Retry failed:", err)
				);
			}, 3000);
		}
	});
	
	const workspaceCreditBalance = creditBalanceData?.workspaceCreditBalance || 0;

	const [updateSurvey] = useMutation<UpdateSurveyReturn, UpdateSurveyVars>(UPDATE_SURVEY);

	const canManageTremendousSettings = useMemo(() => {
		if (!permissions) return false;
		return permissions.some(value => value.includes("tremendousSettings"));
	}, []);

	const handleCloseModal = (): boolean => toggleIntroImageUpload(false);

	// All purpose update instead of the thing we had before
	const handleUpdate = useCallback((value: string | number, key: UpdateSurveyKeys): void => {
		updateSurvey({
			variables: {id: survey.id, changes: {[key]: value}},
			onCompleted: () => {
				if (key === "incentiveCurrency") setCurrency(value as TremendousCurrencyType);
			},
			onError: () => {
				if (key === "title") updateToast({description: `Failed to update ${key}`, type: "failure"});
			},
		});
	}, []);

	// Above but specific to rich text fields
	const handleUpdateRichFields = useCallback((value: Descendant[], key: UpdateSurveyKeys): void => {
		const content = JSON.stringify(value);
		updateSurvey({variables: {id: survey.id, changes: {[key]: content}}});
	}, []);

	const handleDeleteImage = (): void => {
		updateSurvey({
			variables: {id: survey.id, deletions: {introImageId: true, introVideoId: true}},
		});
	};

	const handlePreview = (): void => {
		const open = window.open(`${config.responder}/#/${survey.id}/preview`);
		(open as Window).opener = null;
	};

	async function handleDownload(e): Promise<void> {
		 e.preventDefault(); // Prevent the default anchor behavior

		const url = `${config.apiHost}/exports/survey/docx/${survey.id}`;

		try {
			const blob = await client.get(url).blob();
			const downloadUrl = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = downloadUrl;
			a.download = 'survey.docx'; // The filename you want to download as
			document.body.appendChild(a);
			a.click();
			a.remove(); // Clean up
			window.URL.revokeObjectURL(downloadUrl);
		} catch (error) {
			console.error('Download failed:', error);
		}
	};

	if (survey.createdAt === survey.updatedAt) {
		return <div className={styles.intro}>
			<Icon name="rocket-launch" size="large" />
			<span className={styles.introTitle}>Ready to start building?</span>
			<span className={styles.introDescription}>
				Create your introduction and start adding questions to bring your vurvey to life.
			</span>
			<Button
				onClick={() => handleUpdate("Enter your title...", "title")}
				id="create-introduction"
			>
				Create Introduction
			</Button>
		</div>;
	}

	const sources = id && data?.workspace?.tremendousFundingSources;
	return <>
		<div className={styles.introContainer}>
			<div className={styles.buttons}>
				<Button
					leftIcon={<EyeIcon />}
					className={styles.preview}
					variant="outlined"
					onClick={handlePreview}
					disabled={questions.length === 0}
				>
          Preview
				</Button>
				<Button
					leftIcon={<DownloadIcon />}
					variant="outlined"
					onClick={handleDownload}
					disabled={questions.length === 0}
				>
          Download Survey
				</Button>
			</div>
			<Card className={styles.card}>
				<Form title="Introduction">
					<FormControl label="Graphic (optional)" id="graphic">
						<IntroMedia
							deleteImage={handleDeleteImage}
							image={survey.introImage || survey.introVideo}
							isVideo={Boolean(survey.introVideo)}
							id="graphic"
						/>
					</FormControl>
					<FormControl label="Title shown to creators" id="title">
						<AutoResizeTextarea
							id="title-input"
							aria-label="survey-title"
							value={title}
							onChange={setTitle}
							onBlur={val => handleUpdate(val, "title")}
							placeholder={titlePlaceholder}
							maxLength={255}
						/>
					</FormControl>
					<FormControl label="Introduction">
						<RichTextInput
							text={survey.introduction || ""}
							onDoneEditing={val => handleUpdateRichFields(val, "introduction")}
							placeholder={introPlaceholder}
							aria-label="survey-intro"
						/>
					</FormControl>
					<FormControl label="Instructions">
						<RichTextInput
							text={survey.instructions || ""}
							onDoneEditing={val => handleUpdateRichFields(val, "instructions")}
							placeholder={"Give some brief instructions"}
							aria-label="survey-instructions"
						/>
					</FormControl>
					<FormControl label="Incentive">
						<div className={styles.incentive}>
							<CurrencyInput
								value={incentive}
								onChange={setIncentive}
								className={styles.curInput}
								currencyType={currency}
								onBlur={val => handleUpdate(val, "incentiveAmount")}
								disabled={!sources}
							/>
							<Select
								id="current-type-select"
								selectedValue={currency}
								onChange={val => handleUpdate(val, "incentiveCurrency")}
								options={CURRENCY_SELECT_VALUES}
								className={styles.curSelect}
								disabled={!sources}
							/>
						</div>
						{id && data ? undefined :
							!sources && canManageTremendousSettings
								? <span className={styles.sub}>
								Set up <Link to="/rewards" workspace>Tremendous</Link> to set incentives.
								</span> : <span className={styles.sub}>
									An owner needs to set up Tremendous in order to give incentives.
								</span>
						}
					</FormControl>
					<FormControl label="Credits per Response">
						<div className={styles.creditInput}>
							<NumberInput
								id="creditAmount"
								min={0}
								value={creditAmount}
								onChange={(value) => setCreditAmount(value || 0)}
								onBlur={(val) => handleUpdate(val || 0, "creditAmount")}
								className={styles.numberInput}
								disabled={!isEnterpriseManagerOrSupport}
							/>
						</div>
						{!isEnterpriseManagerOrSupport && (
							<span className={styles.sub}>
								Only Vurvey employees can edit credit amounts.
							</span>
						)}
						<span className={styles.creditBalanceText}>
							{creditBalanceLoading 
								? 'Loading workspace balance...' 
								: creditBalanceError 
									? <span onClick={() => refetchCreditBalance()} style={{ cursor: 'pointer' }}>Error loading balance - Click to retry</span>
									: `Workspace balance: ${workspaceCreditBalance.toFixed(2)} credits`}
						</span>
					</FormControl>
					<FormControl label="Estimated Duration (minutes)">
						<NumberInput
							id="estimatedCompletionTime"
							min={0}
							value={estimatedCompletionTime}
							onChange={setEstimatedCompletionTime}
							onBlur={val => handleUpdate(val || 0, "estimatedCompletionTime")}
							className={styles.numberInput}
						/>
					</FormControl>
					<FormControl label="Action button">
						<Input
							value={startText}
							onChange={setStartText}
							onBlur={val => handleUpdate(val, "startText")}
							className={styles.start}
						/>
					</FormControl>
					<FormControl label="Disclaimer (optional)" id="disclaimer">
						<Input
							value={disclaimer}
							placeholder="Enter text..."
							onChange={setDisclaimer}
							onBlur={val => handleUpdate(val, "disclaimer")}
							maxLength={70}
							className={styles.disclaimer}
							id="disclaimer"
						/>
					</FormControl>
				</Form>
				{
					modal.toggleIntroImageUpload &&
					<IntroImageUploadModal
						isOpen={modal.toggleIntroImageUpload}
						onClose={handleCloseModal}
						surveyId={survey.id}
					/>
				}
			</Card>
		</div>
	</>;
};

export {SurveyIntro};
