import {first} from "lodash-es";
import {useNavigate} from "react-router";
import classNames from "classnames/bind";
import React, {forwardRef} from "react";

import {useAgentsPageContext} from "../../../../context/agents-page-context";
import {
	BinDeleteIcon,
	DotsIcon,
	EyeIcon,
	PenEditIcon,
	SparkAiStarsIcon,
	VurveyLabsIconVolt,
} from "../../../../icons";
import {
	Persona,
	PersonaStatus
} from "../../../../models/persona";
import {
	Body,
	ButtonIcon,
	Dropdown,
	DropdownItem,
	Subheader
} from "../../../../shared/v2";
import {ImageWithAlt} from "../../../../shared/v2/image-with-alt";
import {SizeTransition} from "../../../../shared/v2/size-transition";
import {OverflowTooltip} from "../../../../shared/v2/tooltip/overflow-tooltip";

import styles from "./agent-card.module.scss";

const cx = classNames.bind(styles);

export interface AgentCardProps {
	persona: Persona;
	isEditable?: boolean;
	editPersona?: (persona: Persona) => void;
	deletePersona?: (id: string) => void;
	className?: string;
}

const AgentCard = forwardRef<HTMLDivElement, AgentCardProps>(({
	persona,
	deletePersona,
	editPersona,
	isEditable = true
}: AgentCardProps, ref) => {
	const navigate = useNavigate();
	const isActive = persona.personaStatus === PersonaStatus.PUBLISHED;

	const {setCurrentAgent} = useAgentsPageContext();

	const openAgentDrawer = () => {
		setCurrentAgent(persona);
	}

	const openInCopilot = (): void => {
		navigate("/workflow/conversation", {
			state: {
				chatConversation: {
					chat: {
						aiPersona: persona
					}
				}
			}
		});
	};

	const itemsCallback = (): DropdownItem[] => {
		const items: DropdownItem[] = [
			{
				label: persona.isVurvey ? "View Agent" : "Edit Agent",
				icon: persona.isVurvey ? <EyeIcon /> : <PenEditIcon />,
				onClick: () => editPersona?.(persona)
			},
			{
				label: "Delete Agent",
				icon: <BinDeleteIcon />,
				color: "danger",
				onClick: () => deletePersona?.(persona.id)
			}
		];

		if (isActive) {
			items.unshift({
				label: "Start Conversation",
				icon: <SparkAiStarsIcon />,
				onClick: openInCopilot,
			});
		}

		return items;
	}

	return (
		<div
			ref={ref}
			className={styles.agentCard}
			onClick={openAgentDrawer}
		>
			<div>
				<ImageWithAlt
					className={styles.background}
					src={persona.picture?.url || persona.avatarUri || ""}
					alt={persona.name}
				>
					<div className={cx("background", "noImage")}>
						<Body
							className={styles.initials}
						>
							{first(persona.name)?.toUpperCase()}
						</Body>
					</div>
				</ImageWithAlt>

				<div className={styles.basicGradient} />
				<div className={styles.hoverGradient} />

				<div className={styles.controlPanel}>
					<span className={cx("status", {active: persona.personaStatus === PersonaStatus.PUBLISHED})} />

					{isEditable ?
						<Dropdown
							className={styles.dropdown}
							popupClassName={styles.dropdownPopup}
							position="right-start"
							trigger={
								<ButtonIcon
									className={styles.dropdownTrigger}
									icon={<DotsIcon className={styles.icon} />}
								/>
							}
							items={itemsCallback()}
						/> : null
					}
				</div>
				<div className={cx("content", {hasVurveyIcon: persona.isVurvey})}>
					<OverflowTooltip>
						<Subheader
							color="charcoal-elevation-25"
							size="l"
							type="semibold"
							className={styles.agentName}
						>
							{persona.name}
						</Subheader>
					</OverflowTooltip>

					<Body
						className={styles.agentDescription}
						size="xs"
						type="semibold"
						color="purple-brand-400"
					>
						{persona.personaType?.name.replace(" agent", "")}
					</Body>

					{persona.isVurvey && (
						<VurveyLabsIconVolt className={styles.vurveyIcon} />
					)}

					<SizeTransition speed={40}>
						<Body
							size="xs"
							color="charcoal-elevation-25"
							className={cx("description")}
						>
							{persona.description}
						</Body>
					</SizeTransition>
				</div>
			</div>
		</div>
	);
});

AgentCard.displayName = "AgentCard";

export {AgentCard};
