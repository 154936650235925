/* eslint-disable react/prop-types */
import React, {ReactElement, useContext, useEffect, useMemo, useRef, useState, useCallback} from "react";
import {NetworkStatus, useMutation, useQuery} from "@apollo/client";
import ReactTimeago from "react-timeago";

import {StatsCard, NewAvatar, Options, StatsHeader, formatDate} from "../../../shared";
import {
	GET_DELETE_RESPONSE_MODAL,
	GET_SELECTED_RESPONSE,
} from "../../../graphql/queries/client-queries";
import {GET_RESPONSES} from "../../../graphql/queries/survey-queries";
import {DELETE_RESPONSE} from "../../../graphql/mutations/results-mutations";
import {UPDATE_RESPONSE_CREDIT, BULK_UPDATE_RESPONSE_CREDIT} from "../../../graphql/mutations/credit-mutations";
import {AlignText} from "../../../shared/typography/align-text";
import {SurveyContext} from "../../../context/survey-context";
import {ToastContext} from "../../../context/toast-context";
import {useUserContext} from "../../../context/user-context";
import {selectedResponse, toggleDeleteResponseModal} from "../../../cache";
import {DeleteResponseModal} from "../../../modals/delete-response";
import {Response, ResponsePageData, UpdateResponseCreditInput, BulkUpdateResponseCreditInput} from "../../../models/response";
import {PaginatedTable} from "../../../shared/components/table/paginated-table";
import {currencySymbol} from "../../../shared/utility/utility";
import styles from "./responses-tab.module.scss";
import {useLoadingQuery} from "../../../hooks";
import {useFilter, useNavigate} from "../../../route";
import {Card} from "../../../shared/layout/card";
import {ContactModal} from "../../../modals/contact-modal";
import {useSearchParams} from "react-router-dom";
import {HeaderGroup} from "react-table";
import {ResponsesSort} from "../../../models/rewards";
import {Button, NumberInput, Input} from "../../../shared/v2";
import {AdjustResponseCreditModal} from "../../../modals/adjust-response-credit";
import {DropdownOptions, IconTypes} from "../../../shared";

const TABLE_PAGE_SIZE = 50;

const ResponsesTab = (): ReactElement => {

	const {
		survey: {id: surveyId, firstOpenedAt, closedAt, incentiveAmount, incentiveCurrency, creditAmount: defaultCreditAmount},
		responsesTabPage,
		setResponsesTabPage,
	} = useContext(SurveyContext);
	const {isEnterpriseManagerOrSupport} = useUserContext();
	const [selectedUser, setSelectedUser] = useState("");
	const [params, setSearchParams] = useSearchParams();
	const {updateToast} = useContext(ToastContext);
	const navigate = useNavigate();
	const {membersFilter} = useFilter();
	const [sortBy, setSortBy] = useState(ResponsesSort.DEFAULT);
	const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE);
	const [selectedResponses, setSelectedResponses] = useState<string[]>([]);
	const [bulkCreditAmount, setBulkCreditAmount] = useState<number>(0);
	const [bulkCreditNote, setBulkCreditNote] = useState<string>("");
	const bulkPanelRef = useRef<HTMLDivElement>(null);
	const [adjustCreditModalOpen, setAdjustCreditModalOpen] = useState(false);
	const [selectedResponseForCredit, setSelectedResponseForCredit] = useState<Response | null>(null);
	const [isAllSelected, setIsAllSelected] = useState(false);

	const userId = params.get("userId");

	useEffect(() => {
		setSelectedUser(userId ?? "");
	}, [userId]);

	const handleSetUserId = (id: string | null): void => {
		if (id) {
			params.append("userId", id);
		} else {
			params.delete("userId");
		}

		setSearchParams(params);
	};

	const {data: deleteResponseModal} = useQuery(GET_DELETE_RESPONSE_MODAL);
	const {data: selectedResponseData} = useQuery(GET_SELECTED_RESPONSE);
	const {data, fragment, fetchMoreFragment, handleFetchMore, networkStatus} = useLoadingQuery<ResponsePageData>(GET_RESPONSES, {
		variables: {surveyId, filter: membersFilter, limit: pageSize, sort: sortBy},
		fetchPolicy: "network-only",
		nextFetchPolicy: "cache-first",
		errorPolicy: "all",
		notifyOnNetworkStatusChange: true,
		what: "responses",
	});
	const [deleteResponse] = useMutation(DELETE_RESPONSE, {
		onCompleted: () => updateToast(
			{description: "All answers from user deleted", type: "informational"},
		),
	});
	const [updateResponseCredit] = useMutation(UPDATE_RESPONSE_CREDIT, {
		onCompleted: () => updateToast(
			{description: "Response credit updated", type: "success"},
		),
		onError: () => updateToast(
			{description: "Failed to update response credit", type: "failure"},
		),
	});
	const [bulkUpdateResponseCredit] = useMutation(BULK_UPDATE_RESPONSE_CREDIT, {
		onCompleted: () => {
			updateToast({description: "Response credits updated", type: "success"});
			setSelectedResponses([]);
			setBulkCreditAmount(0);
			setBulkCreditNote("");
		},
		onError: () => updateToast(
			{description: "Failed to update response credits", type: "failure"},
		),
	});

	const handleDelete = (id: string): boolean => {
		selectedResponse(id);
		return toggleDeleteResponseModal(true);
	};

	const handleDeleteResponse = (): void => {
		deleteResponse({
			variables: {id: selectedResponseData.selectedResponse},
			update(cache) {
				cache.modify({
					fields: {
						responses: (existingResponses, {readField}) => ({
							...existingResponses,
							items: existingResponses.items.filter(
								response => selectedResponseData.selectedResponse !== readField(
									"id",
									response,
								),
							),
						}),
					},
				});
			},
		});
		toggleDeleteResponseModal(false);
	};

	const handleReward = (): void => {
		navigate(
			{pathname: "/rewards", search: {surveyIds: [surveyId], completed: true}},
			{workspace: true},
		);
	};

	const handleUserClick = (id: string): void => handleSetUserId(id);

	const handleUpdateCredit = (responseId: string, creditAmount: number, creditNote?: string): void => {
		const input: UpdateResponseCreditInput = {
			responseId,
			creditAmount,
			creditNote
		};
		updateResponseCredit({
			variables: {input},
			update(cache, {data: {updateResponseCredit}}) {
				cache.modify({
					id: cache.identify(updateResponseCredit),
					fields: {
						creditAmount: () => updateResponseCredit.creditAmount,
						creditNote: () => updateResponseCredit.creditNote,
					},
				});
			},
		});
	};

	const handleBulkUpdateCredit = (): void => {
		if (selectedResponses.length === 0) return;
		
		// Validate that note is provided
		if (!bulkCreditNote.trim()) {
			updateToast({
				description: "Please provide a note explaining the credit adjustment",
				type: "failure"
			});
			return;
		}

		const input: BulkUpdateResponseCreditInput = {
			responseIds: selectedResponses,
			creditAmount: bulkCreditAmount,
			creditNote: bulkCreditNote
		};
		bulkUpdateResponseCredit({
			variables: {input},
			update(cache, {data: {bulkUpdateResponseCredit}}) {
				bulkUpdateResponseCredit.forEach(response => {
					cache.modify({
						id: cache.identify(response),
						fields: {
							creditAmount: () => response.creditAmount,
							creditNote: () => response.creditNote,
						},
					});
				});
			},
		});
	};

	const currentData = useMemo(() => data?.responses.items.slice(
		responsesTabPage * pageSize,
		(responsesTabPage + 1) * pageSize,
	), [data, responsesTabPage]);

	const handleSelectResponse = (responseId: string, isSelected: boolean): void => {
		if (isSelected) {
			// Add the response ID to the selected responses array if it's not already there
			setSelectedResponses(prev => {
				if (prev.includes(responseId)) {
					return prev;
				}
				return [...prev, responseId];
			});
		} else {
			// Remove the response ID from the selected responses array
			setSelectedResponses(prev => prev.filter(id => id !== responseId));
		}
		
		// Update the isAllSelected state based on the current data
		if (currentData) {
			// Use a timeout to ensure state updates have been processed
			setTimeout(() => {
				// Get the latest selectedResponses state
				const allSelected = currentData.every(response => {
					// For the response we just clicked, use the new isSelected value
					if (response.id === responseId) {
						return isSelected;
					}
					// For other responses, check if they're in the selectedResponses array
					return selectedResponses.includes(response.id);
				});
				setIsAllSelected(allSelected);
			}, 0);
		}
	};

	const handleSelectAllResponses = (isSelected: boolean): void => {
		// Set the isAllSelected state
		setIsAllSelected(isSelected);
		
		// Force immediate update of the selected responses array
		if (isSelected && currentData) {
			const responseIds = currentData.map(response => response.id);
			setSelectedResponses(prev => {
				// Only add IDs that aren't already in the array
				const newIds = responseIds.filter(id => !prev.includes(id));
				return [...prev, ...newIds];
			});
		} else {
			setSelectedResponses([]);
		}
	};

	useEffect(() => {
		if (currentData && currentData.length > 0) {
			const allSelected = currentData.every(response => 
				selectedResponses.includes(response.id)
			);
			setIsAllSelected(allSelected);
		} else {
			setIsAllSelected(false);
		}
	}, [selectedResponses, currentData]);

	const handleOpenAdjustCreditModal = (response: Response): void => {
		setSelectedResponseForCredit(response);
		setAdjustCreditModalOpen(true);
	};

	const handleCloseAdjustCreditModal = (): void => {
		setAdjustCreditModalOpen(false);
		setSelectedResponseForCredit(null);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const columns = useMemo((): any => [
		{
			id: 'selection',
			Header: () => (
				<div>
					{isEnterpriseManagerOrSupport && (
						<input
							type="checkbox"
							checked={isAllSelected}
							onChange={(e) => handleSelectAllResponses(e.target.checked)}
						/>
					)}
				</div>
			),
			Cell: ({ row }) => (
				<div>
					{isEnterpriseManagerOrSupport && (
						<input
							type="checkbox"
							checked={selectedResponses.includes(row.original.id)}
							onChange={(e) => handleSelectResponse(row.original.id, e.target.checked)}
						/>
					)}
				</div>
			),
			width: 40,
		},
		{
			Header: "Name",
			accessor: "user",
			width: 250,
			Cell: ({value}) =>
				<NewAvatar user={value} showName showTag onClick={() => handleUserClick(value.id)}/>,
		},
		{
			Header: "Completed",
			accessor: "completedAt",
			width: 150,
			textAlign: "right",
			cellProps: () => ({
				styles: {textAlign: "right"},
			}),
			Cell: ({value}) => (
				value ?
					<ReactTimeago date={value}/> : <span>Partial</span>
			),
		},
		{
			Header: "Submitted from",
			accessor: "submittedFrom",
			width: 200,
			Cell: ({row: {original: response}}) => (response.submittedFrom ?
				<span>
					{response.submittedFrom.city},
					&nbsp;
					{response.submittedFrom.countryName}
				</span>
				: null),
		},
		{
			Header: "Credits",
			accessor: "creditAmount",
			width: 100,
			Cell: ({row: {original: response}}) => {
				const creditValue = response.creditAmount || defaultCreditAmount || 0;
				return <span>{creditValue}</span>;
			},
		},
		{
			id: 'options',
			Header: () => null,
			width: 60,
			Cell: ({row: {original: response}}) => {
				const menuOptions: DropdownOptions[] = [
					...(isEnterpriseManagerOrSupport ? [{
						name: "Adjust Credit",
						actionOptions: {
							onClick: () => handleOpenAdjustCreditModal(response),
						},
						icon: "pen" as IconTypes,
					}] : []),
					...(isEnterpriseManagerOrSupport ? [{
						name: "Delete",
						actionOptions: {
							onClick: () => handleDelete(response.id),
						},
						icon: "trash" as IconTypes,
					}] : [])
				];
				
				return (
					<div className={styles.optionsCell}>
						<div className={styles.alignRight}>
							<Options
								type="menu-vertical"
								options={menuOptions}
								position="left"
							/>
						</div>
					</div>
				);
			},
		},
	], [isEnterpriseManagerOrSupport, selectedResponses, defaultCreditAmount]);

	const handleSort = (value: HeaderGroup<Response>): void => {
		if (!value.canSort) return;

		switch (value.Header) {
		case "Name":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(ResponsesSort.CREATOR_TAG_ASC);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(ResponsesSort.CREATOR_TAG_DESC);
			setSortBy(ResponsesSort.DEFAULT);
			break;
		case "Completed":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(ResponsesSort.COMPLETED_AT_ASC);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(ResponsesSort.COMPLETED_AT_DESC);
			setSortBy(ResponsesSort.DEFAULT);
			break;
		case "Submitted from":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(ResponsesSort.LOCATION_ASC);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(ResponsesSort.LOCATION_DESC);
			setSortBy(ResponsesSort.DEFAULT);
			break;
		default:
			setSortBy(ResponsesSort.DEFAULT);
		}
	};

	const items = data?.responses?.items;
	if (!items?.length && (networkStatus !== NetworkStatus.loading)) {
		return (
			<div className={styles.noResponses}>
				<p>No responses yet</p>
				<p>As soon as responses are submitted, they will appear here.</p>
			</div>
		);
	}

	const cards: StatsCard[] = [{
		title: "Starts",
		value: ({survey}) => survey?.responseCount,
	}, {
		title: "Completes",
		value: ({survey}) => survey?.completedResponseCount,
		element: ({survey}) => (survey?.completedResponseCount && Number(incentiveAmount) ?
			<div className={styles.reward}>
				<span className={styles.action} onClick={handleReward}>
				Reward {currencySymbol(incentiveCurrency)}
					{(Number(incentiveAmount) * survey.completedResponseCount).toFixed(2)}
				</span>
				<span>*Tremendous may charge additional fees per reward</span>
			</div> : null),
	}, {
		title: "Median Completion Time",
		dataType: "time",
		value: ({survey}) => survey?.medianCompletionTime,
	}, {
		title: "Total Video",
		dataType: "time",
		value: ({survey}) => survey?.totalVideoDuration,
	}];

	const handlePerPageChange = (newPageSize: number): void => {
		setPageSize(newPageSize);
		setResponsesTabPage(0);
	}

	return (
		<div className={styles.container}>
			{(firstOpenedAt || closedAt) ? (
				<div className={styles.live}>
					{firstOpenedAt ? (

						`Live: ${formatDate(firstOpenedAt)} - ${closedAt ? formatDate(closedAt) : "Present"}`
					) : (

						`Closed on ${formatDate(closedAt!)}`
					)}
				</div>
			) : <></>}
			<StatsHeader cards={cards} variables={{id: surveyId, filter: membersFilter}} />
			<Card className={styles.table}>
				<h2 className={styles.header}>Participation</h2>
				{isEnterpriseManagerOrSupport && selectedResponses.length > 0 && (
					<div ref={bulkPanelRef} className={styles.bulkPanel}>
						<div className={styles.bulkInfo}>
							<span>{selectedResponses.length} responses selected</span>
						</div>
						<div className={styles.bulkControls}>
							<div className={styles.bulkCredit}>
								<label>Credits:</label>
								<NumberInput
									min={0}
									value={bulkCreditAmount}
									onChange={(value) => setBulkCreditAmount(value as number)}
									className={styles.bulkCreditInput}
									placeholder="Enter amount"
									aria-label="Bulk credit amount"
								/>
							</div>
							<div className={styles.bulkNote}>
								<label>Note: <span className={styles.requiredField}>*</span></label>
								<Input
									value={bulkCreditNote}
									onChange={setBulkCreditNote}
									className={styles.bulkNoteInput}
									placeholder="Required: Add note explaining credit adjustment..."
									aria-label="Credit adjustment note"
								/>
								
							</div>
							<Button
								onClick={handleBulkUpdateCredit}
								className={styles.bulkUpdateButton}
								disabled={selectedResponses.length === 0 || !bulkCreditNote.trim()}
							>
								Update Selected
							</Button>
						</div>
					</div>
				)}
				{
					(!data && fragment) || (data && currentData && data.responses.items.length > 0 && (
						<div className={styles.tableContainer}>
							<PaginatedTable
								columns={columns}
								data={currentData}
								totalCount={data.responses.items.length + data.responses.remaining}
								dataLength={data.responses.items.length}
								handleFetchMore={handleFetchMore}
								pageSize={pageSize}
								fetchMoreFragment={fetchMoreFragment}
								pageState={[responsesTabPage, setResponsesTabPage]}
								sortLoading={networkStatus === NetworkStatus.setVariables}
								onSort={handleSort}
								disablePagination={networkStatus === NetworkStatus.fetchMore}
								tableClassName={styles.table}
								onPageSizeChange={handlePerPageChange}
							/>
						</div>
					))
				}
				<DeleteResponseModal
					isOpen={deleteResponseModal.toggleDeleteResponseModal}
					confirm={handleDeleteResponse}
					onClose={() => toggleDeleteResponseModal(false)}
					isDeleteAll
				/>
				{selectedResponseForCredit && (
					<AdjustResponseCreditModal
						isOpen={adjustCreditModalOpen}
						onClose={handleCloseAdjustCreditModal}
						responseId={selectedResponseForCredit.id}
						currentCreditAmount={selectedResponseForCredit.creditAmount || defaultCreditAmount || 0}
						currentCreditNote={selectedResponseForCredit.creditNote || ''}
						defaultCreditAmount={defaultCreditAmount || 0}
					/>
				)}
			</Card>
			<ContactModal
				isOpen={Boolean(selectedUser)}
				onClose={() => handleSetUserId(null)}
				userId={selectedUser}
			/>
		</div>
	);
};

export {ResponsesTab};
