import React, { ReactElement, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Input, NumberInput, Button } from '../../shared/v2';
import { UPDATE_RESPONSE_CREDIT } from '../../graphql/mutations/credit-mutations';
import { useToastContext } from '../../context/toast-context';
import styles from './adjust-response-credit.module.scss';

interface AdjustResponseCreditModalProps {
  isOpen: boolean;
  onClose: () => void;
  responseId: string;
  currentCreditAmount: number;
  currentCreditNote?: string;
  defaultCreditAmount: number;
}

export const AdjustResponseCreditModal = ({
  isOpen,
  onClose,
  responseId,
  currentCreditAmount,
  currentCreditNote = '',
  defaultCreditAmount
}: AdjustResponseCreditModalProps): ReactElement => {
  const { updateToast } = useToastContext();
  const [creditAmount, setCreditAmount] = useState<number>(currentCreditAmount);
  const [creditNote, setCreditNote] = useState<string>(currentCreditNote);

  // Reset state when modal opens with new data
  useEffect(() => {
    if (isOpen) {
      setCreditAmount(currentCreditAmount);
      setCreditNote(currentCreditNote);
    }
  }, [isOpen, currentCreditAmount, currentCreditNote]);

  const [updateResponseCredit, { loading }] = useMutation(UPDATE_RESPONSE_CREDIT, {
    onCompleted: () => {
      updateToast({ description: 'Response credit updated successfully', type: 'success' });
      onClose();
    },
    onError: (error) => {
      updateToast({ description: `Failed to update credit: ${error.message}`, type: 'failure' });
    }
  });

  const handleSubmit = () => {
    updateResponseCredit({
      variables: {
        input: {
          responseId,
          creditAmount,
          creditNote: creditNote.trim()
        }
      },
      update(cache, { data: { updateResponseCredit } }) {
        cache.modify({
          id: cache.identify(updateResponseCredit),
          fields: {
            creditAmount: () => updateResponseCredit.creditAmount,
            creditNote: () => updateResponseCredit.creditNote,
          },
        });
      }
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Adjust Response Credit"
      className={styles.modal}
    >
      <div className={styles.modalContent}>
        <div className={styles.creditRow}>
          <div className={styles.creditInfo}>
            <div className={styles.label}>Default Credit</div>
            <div className={styles.value}>{defaultCreditAmount}</div>
          </div>
          
          <div className={styles.creditInfo}>
            <div className={styles.label}>Current Credit</div>
            <div className={styles.value}>{currentCreditAmount}</div>
          </div>
        </div>
        
        <div className={styles.inputSection}>
          <NumberInput
            label="New Credit Amount"
            id="creditAmount"
            min={0}
            value={creditAmount}
            onChange={(value) => setCreditAmount(value as number)}
          />
        </div>
        
        <div className={styles.noteSection}>
          <Input
            label="Credit Change Reason"
            id="creditNote"
            value={creditNote}
            onChange={setCreditNote}
            placeholder="Optional: Reason for credit adjustment..."
            className={styles.noteInput}
          />
        </div>
        
        <div className={styles.buttons}>
          <div className={styles.actionButtons}>
            <Button 
              onClick={onClose}
              variant="outlined"
              style="brand"
            >
              Cancel
            </Button>
            <Button 
              onClick={handleSubmit}
              disabled={loading}
              variant="filled"
              style="brand"
            >
              {loading ? "Saving..." : "Save Changes"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
