/* eslint-disable react/prop-types */
import React, {ReactElement, useContext, useMemo, useState, useEffect} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {
	MultiSelect,
	Option,
	Select,
} from "../../shared";
import {MANAGE_WORKSPACES} from "../../graphql/mutations/mutations";
import {BATCH_UPDATE_WORKSPACE_CREDIT} from "../../graphql/mutations/credit-mutations";
import {PaginatedTable} from "../../shared/components/table/paginated-table";
import {Column, Row} from "react-table";
import {ToastContext} from "../../context/toast-context";
import {useLoadingQuery} from "../../hooks/useLoadingQuery";
import {GET_ADMIN_FEATURE_WORKSPACES} from "../../graphql/queries/queries";
import {
	Workspace,
	WorkspaceChanges,
	WorkspacesData,
	WorkspaceFilter,
	WorkspacePlan,
	WorkspacesPageData,
	WorkspacesPageVars,
} from "../../models/workspace";
import {Panel} from "../../shared/components/panel";
import {SelectValue} from "../../shared/components/multi-select";
import {FilterDisplay} from "../components/filter-display";
import {Body, Button, ButtonIcon, DebounceSearch, Modal, NumberInput} from "../../shared/v2";
import styles from "./index.module.scss";
import {FilterIcon, PenEditIcon, SearchIcon} from "../../icons";
import {gql} from "@apollo/client";

const TABLE_PAGE_SIZE = 100;

const GET_WORKSPACES = gql`
	query GetWorkspaces($filter: WorkspaceFilter, $limit: Int, $offset: Int) {
		allWorkspaces(filter: $filter, limit: $limit, offset: $offset) {
			items {
				id
				name
				logoId
				logo {
					id
					url
				}
				myRole
				responseCount
				aiInsightsEnabled
				allowAnonymous
				enableOfflineAssist
				plan
				chatbotEnabled
				workflowEnabled
				workspaceCreditBalance
			}
			remaining
		}
	}
`;

const UPDATE_WORKSPACES = gql`
	mutation UpdateWorkspaces($ids: [ID!]!, $changes: UpdateWorkspaceChanges!) {
		updateWorkspaces(ids: $ids, changes: $changes) {
			id
			name
			logoId
			logo {
				id
				url
			}
			myRole
			responseCount
			aiInsightsEnabled
			allowAnonymous
			enableOfflineAssist
			plan
			chatbotEnabled
			workflowEnabled
			workspaceCreditBalance
		}
	}
`;

const ADMIN_UPDATE_WORKSPACE_CREDIT = gql`
	mutation AdminUpdateWorkspaceCredit($workspaceId: GUID!, $amount: Float!, $note: String) {
		adminUpdateWorkspaceCredit(workspaceId: $workspaceId, amount: $amount, note: $note) {
			id
			workspaceId
			amount
			note
			changeReason
			createdAt
		}
	}
`;

const GET_WORKSPACE_CREDITS = gql`
	query GetWorkspaceCredits($workspaceId: GUID!, $limit: PositiveInt, $cursor: String) {
		workspaceCredits(workspaceId: $workspaceId, limit: $limit, cursor: $cursor) {
			items {
				id
				amount
				note
				createdAt
				userId
				changeReason
				adminId
				user {
					name
				}
			}
			cursor
			remaining
		}
	}
`;

const WorkspaceManagement = React.memo((): ReactElement => {
	const {updateToast} = useContext(ToastContext);
	// States
	const [filter, setFilter] = useState<WorkspaceFilter>({});
	const [unsavedFilter, setUnsavedFilter] = useState<{
		aiInsightsEnabled?: string;
		allowAnonymous?: string;
		enableOfflineAssist?: string;
		plan?: WorkspacePlan[];
		chatbotEnabled?: string;
		workflowEnabled?: string;
	}>({});
	const [changes, setChanges] = useState<{
		aiInsightsEnabled: boolean | undefined;
		allowAnonymous: boolean | undefined;
		enableOfflineAssist: boolean | undefined;
		plan: WorkspacePlan | undefined;
		chatbotEnabled: boolean | undefined;
		workflowEnabled: boolean | undefined;
		workspaceCreditBalance: number | undefined;
	}>({
		aiInsightsEnabled: undefined,
		allowAnonymous: undefined,
		enableOfflineAssist: undefined,
		plan: undefined,
		chatbotEnabled: undefined,
		workflowEnabled: undefined,
		workspaceCreditBalance: undefined,
	});
	const [showFilter, setShowFilter] = useState(false);
	const [selectedWorkspaces, setSelectedWorkspaces] = useState<Row<Workspace>[]>([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [selectAll, setSelectAll] = useState(false);
	const [showBulkActions, setShowBulkActions] = useState<boolean>(false);
	const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE);
	const [creditStates, setCreditStates] = useState<Record<string, {
		operation: "add" | "subtract";
		amount: number | undefined;
		note: string;
	}>>({});
	const [selectedWorkspaceForCredits, setSelectedWorkspaceForCredits] = useState<string | null>(null);
	const [creditHistoryLimit] = useState(20);
	const [selectedWorkspaceForCreditManagement, setSelectedWorkspaceForCreditManagement] = useState<string | null>(null);
	const [bulkCreditState, setBulkCreditState] = useState<{
		operation: "add" | "subtract" | "none";
		amount: number | undefined;
		note: string;
	}>({
		operation: "none",
		amount: undefined,
		note: "",
	});
	const [creditHistorySort, setCreditHistorySort] = useState({
		column: 'date', // Default sort by date
		direction: 'desc' // Default newest first
	});

	const toggleBulkActionModel = (): void => {
		if (showBulkActions) {
			setChanges({
				aiInsightsEnabled: undefined,
				allowAnonymous: undefined,
				enableOfflineAssist: undefined,
				plan: undefined,
				chatbotEnabled: undefined,
				workflowEnabled: undefined,
				workspaceCreditBalance: undefined,
			});
		}
		setShowBulkActions(!showBulkActions);
	};

	const numFiltersUsed = useMemo(
		() => {
			return Object.keys(unsavedFilter).length;
		},
		[unsavedFilter],
	);

	const loadingQuery = useLoadingQuery<WorkspacesPageData, WorkspacesPageVars>(GET_ADMIN_FEATURE_WORKSPACES, {
		variables: {filter, limit: pageSize || 50},
		errorPolicy: "all",
		what: "Workspaces",
		fetchPolicy: "network-only",
		nextFetchPolicy: "cache-first",
		fetchMoreClassName: styles.fetchMore,
		notifyOnNetworkStatusChange: true,
	});

	const {data, error, refetch, fetchMoreFragment, fragment, handleFetchMore} = loadingQuery;
	/** ******************************
	 * Mutations start below
	 *********************************/
	const [setFeatures] = useMutation<WorkspacesData>(MANAGE_WORKSPACES, {
		onCompleted: changed => {
			updateToast({
				description: `${changed.manageWorkspaces} workspace(s) changed.`,
				type: "informational",
			});
			refetch();
		},
	});
	const updateFeatures = (changesToMake: WorkspaceChanges): void => {
		setFeatures({
			variables: {
				changes: changesToMake,
				useFilter: selectAll,
				filter,
				workspaceIds: selectedWorkspaces.map(w => w.original.id),
			},
		});
	};
	/** ******************************
	 * End of Mutations
	 *********************************/

	const [updateWorkspaceCredit] = useMutation(ADMIN_UPDATE_WORKSPACE_CREDIT, {
		onCompleted: () => {
			updateToast({
				description: `Credit balance updated successfully.`,
				type: "informational",
			});
			// Force a full refetch to update all data including credit balances
			refetch();
		},
		onError: (error) => {
			updateToast({
				description: `Error updating credit balance: ${error.message}`,
				type: "failure",
			});
		}
	});
	
	const [batchUpdateWorkspaceCredit] = useMutation(BATCH_UPDATE_WORKSPACE_CREDIT, {
		onCompleted: (data) => {
			const count = data.batchUpdateWorkspaceCredit.length;
			updateToast({
				description: `Successfully updated credits for ${count} workspace${count !== 1 ? 's' : ''}.`,
				type: "informational",
			});
			// Force a full refetch to update all data including credit balances
			refetch();
		},
		onError: (error) => {
			updateToast({
				description: `Error processing bulk credit update: ${error.message}`,
				type: "failure",
			});
		}
	});

	const handleUpdate = (): void => {
		updateFeatures({...changes});

		// Handle bulk credit updates if applicable
		if (bulkCreditState.operation !== "none" && bulkCreditState.amount && bulkCreditState.amount > 0) {
			handleBulkCreditUpdate();
		}

		toggleBulkActionModel();
		setSelectedWorkspaces([]);
		setSelectAll(false);
		
		// Reset bulk credit state
		setBulkCreditState({
			operation: "none",
			amount: undefined,
			note: ""
		});
	};

	// Function to handle bulk credit updates
	const handleBulkCreditUpdate = async (): Promise<void> => {
		if (!bulkCreditState.amount || bulkCreditState.amount <= 0) {
			updateToast({
				description: "Please enter a valid credit amount",
				type: "failure",
			});
			return;
		}
		
		// Validate that note is provided
		if (!bulkCreditState.note.trim()) {
			updateToast({
				description: "Please provide a note explaining the credit adjustment",
				type: "failure"
			});
			return;
		}

		const workspaceIds = selectedWorkspaces.map(row => row.original.id);
		const finalAmount = bulkCreditState.operation === "add" ? bulkCreditState.amount : -bulkCreditState.amount;
		
		try {
			// Use the batch update mutation to process all workspaces in a single request
			await batchUpdateWorkspaceCredit({
				variables: {
					input: {
						workspaceIds,
						amount: finalAmount,
						note: bulkCreditState.note
					}
				}
			});
			
			// Success handling is done in the mutation's onCompleted callback
		} catch (error: any) {
			// Error handling is done in the mutation's onError callback
			console.error("Error in batch credit update:", error);
		}
	};

	const handleClearFilters = (): void => {
		setFilter({});
		refetch();
	};
	const handleRemoveFilter = (clearKey, clearValue?): void => {
		const newFilter = {...filter};

		if (Array.isArray(newFilter[clearKey])) {
			newFilter[clearKey] = newFilter[clearKey].filter(
				item => item !== clearValue,
			);
			if (newFilter[clearKey].length === 0) newFilter[clearKey] = undefined;
		} else {
			newFilter[clearKey] = undefined;
		}
		setFilter(newFilter);
		refetch();
	};
	const updateUnsavedFilter = (value, id: string): void => {
		setUnsavedFilter({...unsavedFilter, [id]: value});
	};
	const updateChanges = (value, id: string): void => {
		setChanges({...changes, [id]: value});
	};

	const currentData =

		useMemo(
			() =>
				data?.allWorkspaces?.items?.slice(
					currentPage * pageSize,
					(currentPage + 1) * pageSize,
				),
			[data, currentPage],
		);
	const columns: Column<Workspace>[] = [
		{
			id: "name",
			Header: "Name",
			accessor: "name",
			minWidth: 250,
			Cell: ({value}) => <Body size="s">{value}</Body>,
		},
		{
			Header: "Credit Balance",
			accessor: (row) => row.workspaceCreditBalance,
			Cell: ({ row }: { row: Row<Workspace> }) => {
				const workspace = row.original;
				const balance = workspace.workspaceCreditBalance !== undefined ? workspace.workspaceCreditBalance : 0;
				
				return (
					<div className={styles.creditBalanceColumn}>
						<span className={balance > 0 ? styles.hasCredits : styles.noCredits}>
							{balance.toFixed(2)}
						</span>
						<ButtonIcon
							icon={<PenEditIcon />}
							title="Edit credits"
							variant="table"
							onClick={(e) => {
								e.stopPropagation();
								handleManageCredits(workspace.id);
							}}
						/>
					</div>
				);
			},
			disableSortBy: false,
		},
		{
			Header: "AI Insights Enabled",
			accessor: "aiInsightsEnabled",
			Cell: ({value}) => (
				<Body size="s">{value ? "On" : ""}</Body>
			),
		},
		{
			Header: "Allow Anonymous",
			accessor: "allowAnonymous",
			Cell: ({value}) => (
				<Body size="s">{value ? "On" : ""}</Body>
			),
		},
		{
			Header: "Offline Assist",
			accessor: "enableOfflineAssist",
			Cell: ({value}) => (
				<Body size="s">{value ? "On" : ""}</Body>
			),
		},
		{
			Header: "Plan",
			accessor: "plan",
			Cell: ({value}) => <Body size="s">{value}</Body>,
		},
		{
			Header: "Chatbot Enabled",
			accessor: "chatbotEnabled",
			Cell: ({value}) => (
				<Body size="s">{value ? "On" : ""}</Body>
			),
		},
		{
			Header: "Workflow Enabled",
			accessor: "workflowEnabled",
			Cell: ({value}) => (
				<Body size="s">{value ? "On" : ""}</Body>
			),
		}
	];

	const isFiltered = Object.keys(filter).some(
		value => filter[value] !== undefined,
	);

	const handleSearch = (newValue: string): void => {
		setFilter({...filter, name: newValue});
		setCurrentPage(0); // In case there's only one page of results, want to start at the first page.
	};

	const handlePerPageChange = (newPageSize: number): void => {
		setPageSize(newPageSize);
		setCurrentPage(0);
	}

	const getWorkspaceCreditState = (workspaceId: string) => {
		if (!creditStates[workspaceId]) {
			setCreditStates(prev => ({
				...prev,
				[workspaceId]: {
					operation: "add",
					amount: undefined,
					note: ""
				}
			}));
		}
		return creditStates[workspaceId] || { operation: "add", amount: undefined, note: "" };
	};

	const updateCreditOperation = (workspaceId: string, operation: "add" | "subtract") => {
		setCreditStates(prev => ({
			...prev,
			[workspaceId]: {
				...prev[workspaceId] || { amount: undefined, note: "" },
				operation
			}
		}));
	};

	const updateCreditAmount = (workspaceId: string, amount: number | undefined) => {
		setCreditStates(prev => ({
			...prev,
			[workspaceId]: {
				...prev[workspaceId] || { operation: "add", note: "" },
				amount
			}
		}));
	};

	const updateCreditNote = (workspaceId: string, note: string) => {
		setCreditStates(prev => ({
			...prev,
			[workspaceId]: {
				...prev[workspaceId] || { operation: "add", amount: undefined },
				note
			}
		}));
	};

	const handleCreditUpdate = (workspaceId: string) => {
		const state = getWorkspaceCreditState(workspaceId);
		
		if (state.amount === undefined || state.amount <= 0) {
			updateToast({
				description: "Please enter a valid credit amount",
				type: "failure",
			});
			return;
		}
		
		// Validate that note is provided
		if (!state.note.trim()) {
			updateToast({
				description: "Please provide a note explaining the credit adjustment",
				type: "failure"
			});
			return;
		}
		
		const finalAmount = state.operation === "add" ? state.amount : -state.amount;
		
		updateWorkspaceCredit({
			variables: {
				workspaceId,
				amount: finalAmount,
				note: state.note
			},
			onError: (error) => {
				// Error handling is in the mutation definition
			}
		});
		
		setCreditStates(prev => ({
			...prev,
			[workspaceId]: {
				operation: "add",
				amount: undefined,
				note: ""
			}
		}));
	};

	const { data: creditsData, loading: creditsLoading, fetchMore: fetchMoreCredits, refetch: refetchCredits } = useQuery(GET_WORKSPACE_CREDITS, {
		variables: {
			workspaceId: selectedWorkspaceForCredits || "",
			limit: creditHistoryLimit
		},
		skip: !selectedWorkspaceForCredits,
		fetchPolicy: "network-only",
		onError: (error) => {
			updateToast({
				description: `Error fetching credit history: ${error.message}`,
				type: "failure",
			});
		}
	});

	// Add effect to refetch credits when workspace changes
	useEffect(() => {
		if (selectedWorkspaceForCredits) {
			// Reset any previous errors
			refetchCredits().catch(error => {
				console.error("Error fetching credit history:", error);
				updateToast({
					description: `Error fetching credit history: Network error or server unavailable`,
					type: "failure",
				});
			});
		}
	}, [selectedWorkspaceForCredits, refetchCredits, updateToast]);

	const handleLoadMoreCredits = () => {
		if (creditsData?.workspaceCredits?.cursor && creditsData?.workspaceCredits?.remaining > 0) {
			fetchMoreCredits({
				variables: {
					cursor: creditsData.workspaceCredits.cursor
				},
				updateQuery: (prev, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev;
					return {
						workspaceCredits: {
							...fetchMoreResult.workspaceCredits,
							items: [
								...prev.workspaceCredits.items,
								...fetchMoreResult.workspaceCredits.items
							]
						}
					};
				}
			});
		}
	};

	const formatDate = (dateString: string) => {
		const date = new Date(dateString);
		return date.toLocaleString();
	};

	// Calculate running balance for credit history
	const getCreditHistoryWithRunningBalance = () => {
		if (!creditsData?.workspaceCredits?.items) return [];
		
		let items = [...creditsData.workspaceCredits.items];
		
		// First sort by date ascending to calculate running balance
		const itemsByDate = [...items].sort((a, b) => 
			new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
		);
		
		// Calculate running balance
		let runningTotal = 0;
		const itemsWithBalance = itemsByDate.map(credit => {
			runningTotal += credit.amount;
			return {
				...credit,
				runningBalance: runningTotal,
				date: new Date(credit.createdAt) // Add date object for easier sorting
			};
		});
		
		// Now apply the user's preferred sort
		const sortedItems = [...itemsWithBalance].sort((a, b) => {
			if (creditHistorySort.column === 'date') {
				const dateA = a.date.getTime();
				const dateB = b.date.getTime();
				return creditHistorySort.direction === 'asc' ? dateA - dateB : dateB - dateA;
			} else if (creditHistorySort.column === 'amount') {
				return creditHistorySort.direction === 'asc' ? a.amount - b.amount : b.amount - a.amount;
			} else if (creditHistorySort.column === 'balance') {
				return creditHistorySort.direction === 'asc' ? a.runningBalance - b.runningBalance : b.runningBalance - a.runningBalance;
			}
			return 0;
		});
		
		return sortedItems;
	};

	const formatChangeReason = (reason: string | undefined) => {
		if (!reason) return "Unknown";
		
		switch (reason) {
			case "ADMIN_ADJUSTMENT":
				return "Admin Adjustment";
			case "SURVEY_RESPONSE":
				return "Survey Response";
			case "SYSTEM":
				return "System Adjustment";
			case "OTHER":
				return "Other";
			default:
				return reason;
		}
	};

	// Function to open the credit management modal
	const handleManageCredits = (workspaceId: string) => {
		// Initialize credit state if it doesn't exist for this workspace
		if (!creditStates[workspaceId]) {
			setCreditStates({
				...creditStates,
				[workspaceId]: {
					operation: "add",
					amount: undefined,
					note: ""
				}
			});
		}
		setSelectedWorkspaceForCreditManagement(workspaceId);
	};

	// Function to handle column header click
	const handleSortChange = (column: string) => {
		setCreditHistorySort(prev => ({
			column,
			direction: prev.column === column && prev.direction === 'asc' ? 'desc' : 'asc'
		}));
	};

	return (
		<>
			{error && (
				<p className={styles.err}>
					Error loading workspace list: {error.message}
				</p>
			)}
			{currentData &&
			currentData?.length === 0 &&
			!isFiltered &&
			!fetchMoreFragment ? (
					<></>
				) : (
					<header className={styles.header}>
						<DebounceSearch
							id="search-input"
							value={filter.name || ""}
							onChange={handleSearch}
							placeholder="Search"
							leftIcon={<SearchIcon />}
						/>
						<Button
							leftIcon={<FilterIcon />}
							variant="outlined"
							onClick={() => {
								setUnsavedFilter({
									plan: filter.plan,
									enableOfflineAssist:
									filter.enableOfflineAssist === true
										? "On"
										: filter.enableOfflineAssist === false
											? "Off"
											: undefined,
									aiInsightsEnabled:
									filter.aiInsightsEnabled === true
										? "On"
										: filter.aiInsightsEnabled === false
											? "Off"
											: undefined,
									allowAnonymous:
									filter.allowAnonymous === true
										? "On"
										: filter.allowAnonymous === false
											? "Off"
											: undefined,
									chatbotEnabled:
									filter.chatbotEnabled === true
										? "On"
										: filter.chatbotEnabled === false
											? "Off"
											: undefined,
									workflowEnabled:
									filter.workflowEnabled === true
										? "On"
										: filter.workflowEnabled === false
											? "Off"
											: undefined
								});
								setShowFilter(!showFilter);
							}}
						>
							Filter
						</Button>
						<div className={styles.actions}>
							{selectedWorkspaces.length > 0 && data && (
								<Button
									variant="outlined"
									onClick={toggleBulkActionModel}
								>
									{`Bulk Actions${
										selectAll
											? ` (${
												(data.allWorkspaces.items?.length ?? 0) +
												data.allWorkspaces.remaining
											})`
											: ` (${selectedWorkspaces.length})`
									}`}
								</Button>
							)}
						</div>
					</header>
				)}
			<FilterDisplay
				filter={filter}
				handleClearFilters={handleClearFilters}
				handleRemoveFilter={handleRemoveFilter}
			/>
			{fragment ||
				(data && currentData && data.allWorkspaces.items?.length > 0 ? (
					<>
						<PaginatedTable<Workspace>
							columns={columns}
							data={currentData}
							selectedValues={selectedWorkspaces}
							onSelectChange={setSelectedWorkspaces}
							pageState={[currentPage, setCurrentPage]}
							pageSize={pageSize}
							totalCount={
								(data.allWorkspaces.items?.length ?? 0) +
								data.allWorkspaces.remaining
							}
							handleFetchMore={handleFetchMore}
							fetchMoreFragment={fetchMoreFragment}
							selectAllState={[selectAll, setSelectAll]}
							dataLength={data.allWorkspaces.items?.length}
							onPageSizeChange={handlePerPageChange}
						/>
					</>
				) : (
					<Body size="s">No results found</Body>
				))}
			{showFilter && (
				<Panel
					title="Filter"
					handleClose={() => setShowFilter(false)}
					theme="purple"
				>
					<div className={styles.sidebarContainer}>
						<div className={styles.sidebarProperties}>
							<Select
								id="aiInsightEnabled"
								label="AI Insights"

								options={[
									{text: "Not filtered", value: undefined},
									{text: "On", value: "On"},
									{text: "Off", value: "Off"},
								]}
								onChange={newValue =>
									updateUnsavedFilter(newValue, "aiInsightsEnabled")
								}
								selectedValue={unsavedFilter.aiInsightsEnabled}
							/>
							<Select
								id="chatbotEnabled"
								label="Chatbot Enabled"

								options={[
									{text: "Not filtered", value: undefined},
									{text: "On", value: "On"},
									{text: "Off", value: "Off"},
								]}
								onChange={newValue =>
									updateUnsavedFilter(newValue, "chatbotEnabled")
								}
								selectedValue={unsavedFilter.chatbotEnabled}
							/>
							<Select
								id="workflowEnabled"
								label="Workflow Enabled"
								options={[
									{text: "Not filtered", value: undefined},
									{text: "On", value: "On"},
									{text: "Off", value: "Off"},
								]}
								onChange={newValue =>
									updateUnsavedFilter(newValue, "workflowEnabled")
								}
								selectedValue={unsavedFilter.workflowEnabled}
							/>
							<Select
								id="allowAnonymous"
								label="Allow Anonymous"

								options={[
									{text: "Not filtered", value: undefined},
									{text: "On", value: "On"},
									{text: "Off", value: "Off"},
								]}
								onChange={newValue =>
									updateUnsavedFilter(newValue, "allowAnonymous")
								}
								selectedValue={unsavedFilter.allowAnonymous}
							/>
							<Select
								id="enableOfflineAssist"
								label="Offline Assist"

								options={[
									{text: "Not filtered", value: undefined},
									{text: "On", value: "On"},
									{text: "Off", value: "Off"},
								]}
								onChange={newValue =>
									updateUnsavedFilter(newValue, "enableOfflineAssist")
								}
								selectedValue={unsavedFilter.enableOfflineAssist}
							/>
							<MultiSelect
								id="plan"
								key="plan"
								value={unsavedFilter.plan || []}
								label="Workspace Plan"
								options={Object.keys(WorkspacePlan).map(
									(key): SelectValue<WorkspacePlan> => {
										const value = WorkspacePlan[key];
										return {name: value, id: value};
									},
								)}
								onChange={updateUnsavedFilter}
							/>
						</div>
						<div className={styles.sidebarFooter}>
							<Button
								onClick={() => {
									setFilter({
										plan: unsavedFilter.plan,
										enableOfflineAssist:
											unsavedFilter.enableOfflineAssist === "On"
												? true
												: unsavedFilter.enableOfflineAssist === "Off"
													? false
													: undefined,
										aiInsightsEnabled:
											unsavedFilter.aiInsightsEnabled === "On"
												? true
												: unsavedFilter.aiInsightsEnabled === "Off"
													? false
													: undefined,
										chatbotEnabled:
											unsavedFilter.chatbotEnabled === "On"
												? true
												: unsavedFilter.chatbotEnabled === "Off"
													? false
													: undefined,
										allowAnonymous:
											unsavedFilter.allowAnonymous === "On"
												? true
												: unsavedFilter.allowAnonymous === "Off"
													? false
													: undefined,

										workflowEnabled:
											unsavedFilter.workflowEnabled === "On"
												? true
												: unsavedFilter.workflowEnabled === "Off"
													? false
													: undefined,
									});
									setShowFilter(false);
								}}
							>
								{
									numFiltersUsed
										? `Apply Filters (${String(numFiltersUsed)})`
										: "Apply Filters"
								}
							</Button>
							<Button
								variant="outlined"
								onClick={() => {
									setFilter({});
									setShowFilter(false);
								}}
							>
								Clear Filters
							</Button>
						</div>
					</div>
				</Panel>
			)}
			<Modal
				className={styles.workspaceManagementModal}
				isOpen={showBulkActions}
				onClose={toggleBulkActionModel}
				title={`Updating ${
					selectAll
						? (data?.allWorkspaces?.items?.length ?? 0) + (data?.allWorkspaces?.remaining ?? 0)
						: selectedWorkspaces.length
				} Workspaces`}
			>

				<div>
					<Select
						className={styles.modelSelect}
						id="changesAiInsightEnabled"
						label="AI Insights"
						options={[
							{text: "Do not change", value: undefined},
							{text: "On", value: true},
							{text: "Off", value: false},
						]}
						onChange={newValue =>
							updateChanges(newValue, "aiInsightsEnabled")
						}
						selectedValue={changes.aiInsightsEnabled}
					/>
					<Select
						className={styles.modelSelect}
						id="changesAllowAnonymous"
						label="Allow Anonymous"
						options={[
							{text: "Do not change", value: undefined},
							{text: "On", value: true},
							{text: "Off", value: false},
						]}
						onChange={newValue => updateChanges(newValue, "allowAnonymous")}
						selectedValue={changes.allowAnonymous}
					/>
					<Select
						className={styles.modelSelect}
						id="changesEnableOfflineAssist"
						label="Offline Assist"
						options={[
							{text: "Do not change", value: undefined},
							{text: "On", value: true},
							{text: "Off", value: false},
						]}
						onChange={newValue =>
							updateChanges(newValue, "enableOfflineAssist")
						}
						selectedValue={changes.enableOfflineAssist}
					/>
					<Select
						className={styles.modelSelect}
						id="changesPlan"
						label="Workspace Plan"
						options={[
							{text: "Do not change", value: undefined},
							...Object.keys(WorkspacePlan).map(
								(key): Option<WorkspacePlan> => {
									const value = WorkspacePlan[key];
									return {text: value, value};
								},
							),
						]}
						onChange={newValue => updateChanges(newValue, "plan")}
						selectedValue={changes.plan}
					/>
					<Select
						className={styles.modelSelect}
						id="changesChatbotEnabled"
						label="Enable Chatbot"
						options={[
							{text: "Do not change", value: undefined},
							{text: "On", value: true},
							{text: "Off", value: false},
						]}
						onChange={newValue =>
							updateChanges(newValue, "chatbotEnabled")
						}
						selectedValue={changes.chatbotEnabled}
					/>

					<Select
						className={styles.modelSelect}
						id="changesWorkflowEnabled"
						label="Enable Workflow"
						options={[
							{text: "Do not change", value: undefined},
							{text: "On", value: true},
							{text: "Off", value: false},
						]}
						onChange={newValue =>
							updateChanges(newValue, "workflowEnabled")
						}
						selectedValue={changes.workflowEnabled}
					/>

					<div className={styles.bulkCreditSection}>
						<h3>Update Credits</h3>
						<div className={styles.bulkCreditContent}>
							<div className={styles.creditRadioGroup}>
								<label>
									<input
										type="radio"
										name="bulkCreditOperation"
										checked={bulkCreditState.operation === "add"}
										onChange={() => setBulkCreditState({
											...bulkCreditState,
											operation: "add"
										})}
									/>
									Add
								</label>
								<label>
									<input
										type="radio"
										name="bulkCreditOperation"
										checked={bulkCreditState.operation === "subtract"}
										onChange={() => setBulkCreditState({
											...bulkCreditState,
											operation: "subtract"
										})}
									/>
									Subtract
								</label>
								<label>
									<input
										type="radio"
										name="bulkCreditOperation"
										checked={bulkCreditState.operation === "none"}
										onChange={() => setBulkCreditState({
											...bulkCreditState,
											operation: "none"
										})}
									/>
									No Change
								</label>
							</div>
							
							{bulkCreditState.operation !== "none" && (
								<>
									<div className={styles.creditAmountInput}>
										<label>Amount</label>
										<input
											type="number"
											min="0"
											step="0.01"
											value={bulkCreditState.amount || ""}
											onChange={(e) => {
												const amount = e.target.value ? parseFloat(e.target.value) : undefined;
												setBulkCreditState({
													...bulkCreditState,
													amount
												});
											}}
											placeholder="Enter amount"
										/>
									</div>
									
									<div className={styles.creditNoteInput}>
										<label>Note: <span className={styles.requiredField}>*</span></label>
										<input
											type="text"
											value={bulkCreditState.note || ""}
											onChange={(e) => setBulkCreditState({
												...bulkCreditState,
												note: e.target.value
											})}
											placeholder="Required: Reason for bulk credit adjustment"
										/>
										
									</div>
								</>
							)}
						</div>
					</div>
				</div>
				<div className={styles.modelFooter}>
					<Button
						onClick={toggleBulkActionModel}
						variant="outlined"
					>
						Cancel
					</Button>
					<Button
						onClick={handleUpdate}
						disabled={
							bulkCreditState.operation !== "none" && 
							(
								!bulkCreditState.amount || 
								bulkCreditState.amount <= 0 || 
								!bulkCreditState.note?.trim()
							)
						}
					>
						Update
					</Button>
				</div>
			</Modal>
			<Modal
				className={styles.creditHistoryModal}
				isOpen={!!selectedWorkspaceForCredits}
				onClose={() => setSelectedWorkspaceForCredits(null)}
				title="Credit Transaction History"
			>
				{creditsLoading ? (
					<div className={styles.loadingCredits}>Loading credit history...</div>
				) : creditsData?.workspaceCredits?.items?.length > 0 ? (
					<>
						<div className={styles.creditHistoryTable}>
							<table>
								<thead>
									<tr>
										<th 
											className={`${styles.sortableHeader} ${creditHistorySort.column === 'date' ? styles.activeSorted : ''}`}
											onClick={() => handleSortChange('date')}
										>
											Date
											<span className={styles.sortIcon}>
												{creditHistorySort.column === 'date' && (creditHistorySort.direction === 'asc' ? '↑' : '↓')}
											</span>
										</th>
										<th 
											className={`${styles.sortableHeader} ${creditHistorySort.column === 'amount' ? styles.activeSorted : ''}`}
											onClick={() => handleSortChange('amount')}
										>
											Amount
											<span className={styles.sortIcon}>
												{creditHistorySort.column === 'amount' && (creditHistorySort.direction === 'asc' ? '↑' : '↓')}
											</span>
										</th>
										<th>Note</th>
										<th>Reason</th>
										<th>Performed By</th>
										<th 
											className={`${styles.sortableHeader} ${creditHistorySort.column === 'balance' ? styles.activeSorted : ''}`}
											onClick={() => handleSortChange('balance')}
										>
											Balance After
											<span className={styles.sortIcon}>
												{creditHistorySort.column === 'balance' && (creditHistorySort.direction === 'asc' ? '↑' : '↓')}
											</span>
										</th>
									</tr>
								</thead>
								<tbody>
									{getCreditHistoryWithRunningBalance().map(credit => (
										<tr key={credit.id} className={credit.amount >= 0 ? styles.creditAddition : styles.creditDeduction}>
											<td>{formatDate(credit.createdAt)}</td>
											<td>{credit.amount}</td>
											<td>{credit.note || "-"}</td>
											<td>{formatChangeReason(credit.changeReason)}</td>
											<td>{credit.user?.name || (credit.adminId ? "Admin" : "-")}</td>
											<td>{credit.runningBalance.toFixed(2)}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						
				{creditsData?.workspaceCredits?.remaining > 0 && (
					<div className={styles.loadMoreContainer}>
						<Button variant="text" onClick={handleLoadMoreCredits}>
							Load More
						</Button>
					</div>
				)}
					</>
				) : (
					<div className={styles.noCreditTransactions}>
						<p>No credit transactions found for this workspace.</p>
						<p className={styles.debugInfo}>Workspace ID: {selectedWorkspaceForCredits}</p>
					</div>
				)}
				<div className={styles.modalFooter}>
					<Button onClick={() => setSelectedWorkspaceForCredits(null)}>
						Close
					</Button>
				</div>
			</Modal>
			<Modal
				className={styles.creditManagementModal}
				isOpen={!!selectedWorkspaceForCreditManagement}
				onClose={() => setSelectedWorkspaceForCreditManagement(null)}
				title="Manage Credit Balance"
			>
				{/* TODO: move to seperate component and replace with our components */}
				{selectedWorkspaceForCreditManagement && (
					<div className={styles.creditManagementContent}>
						<div className={styles.creditOperations}>
							<div className={styles.creditOperationTitle}>
								<h3>Operation Type</h3>
							</div>
							<div className={styles.creditRadioGroup}>
								<label>
									<input
										type="radio"
										checked={creditStates[selectedWorkspaceForCreditManagement]?.operation === "add"}
										onChange={() => setCreditStates({
											...creditStates,
											[selectedWorkspaceForCreditManagement]: {
												...creditStates[selectedWorkspaceForCreditManagement] || {},
												operation: "add"
											}
										})}
									/>
									Add
								</label>
								<label>
									<input
										type="radio"
										checked={creditStates[selectedWorkspaceForCreditManagement]?.operation === "subtract"}
										onChange={() => setCreditStates({
											...creditStates,
											[selectedWorkspaceForCreditManagement]: {
												...creditStates[selectedWorkspaceForCreditManagement] || {},
												operation: "subtract"
											}
										})}
									/>
									Subtract
								</label>
							</div>
							
							<div className={styles.creditAmountInput}>
								<label>Amount</label>
								<input
									type="number"
									min="0"
									step="0.01"
									value={creditStates[selectedWorkspaceForCreditManagement]?.amount || ""}
									onChange={(e) => {
										const amount = e.target.value ? parseFloat(e.target.value) : undefined;
										setCreditStates({
											...creditStates,
											[selectedWorkspaceForCreditManagement]: {
												...creditStates[selectedWorkspaceForCreditManagement] || {},
												amount
											}
										});
									}}
									placeholder="Enter credit amount"
								/>
							</div>
							
							<div className={styles.creditNoteInput}>
								<label>Note: <span className={styles.requiredField}>*</span></label>
								<input
									type="text"
									value={creditStates[selectedWorkspaceForCreditManagement]?.note || ""}
									onChange={(e) => setCreditStates({
										...creditStates,
										[selectedWorkspaceForCreditManagement]: {
											...creditStates[selectedWorkspaceForCreditManagement] || {},
											note: e.target.value
										}
									})}
									placeholder="Required: Reason for credit adjustment"
								/>
								
							</div>
							
							<div className={styles.creditActionButtons}>
								<Button
									variant="outlined"
									onClick={() => setSelectedWorkspaceForCreditManagement(null)}
								>
									Cancel
								</Button>
								
								<Button
									onClick={() => {
										handleCreditUpdate(selectedWorkspaceForCreditManagement);
										setSelectedWorkspaceForCreditManagement(null);
									}}
									disabled={
										!creditStates[selectedWorkspaceForCreditManagement]?.amount || 
										!creditStates[selectedWorkspaceForCreditManagement]?.note?.trim()
									}
								>
									Update
								</Button>
							</div>
						</div>
						
						<div className={styles.creditHistoryActions}>
							<Button
								variant="outlined"
								onClick={() => {
									setSelectedWorkspaceForCredits(selectedWorkspaceForCreditManagement);
									setSelectedWorkspaceForCreditManagement(null);
								}}
							>
								View Transaction History
							</Button>
						</div>
					</div>
				)}
			</Modal>
		</>
	);
});

WorkspaceManagement.displayName = "WorkspaceManagement";
export {WorkspaceManagement};
