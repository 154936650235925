import {gql} from "@apollo/client";

/**
 * Get workspace credit balance
 */
export const GET_WORKSPACE_CREDIT_BALANCE = gql`
  query GetWorkspaceCreditBalance($workspaceId: GUID!) {
    workspaceCreditBalance(workspaceId: $workspaceId)
  }
`;

/**
 * Get workspace credit transactions with optional date filtering
 */
export const GET_WORKSPACE_CREDITS = gql`
  query GetWorkspaceCredits(
    $workspaceId: GUID!,
    $filter: WorkspaceCreditsFilter,
    $limit: PositiveInt,
    $cursor: String
  ) {
    workspaceCredits(
      workspaceId: $workspaceId,
      filter: $filter,
      limit: $limit,
      cursor: $cursor
    ) {
      items {
        id
        amount
        note
        createdAt
        userId
        user {
          id
          firstName
          lastName
        }
        responseId
        response {
          id
          surveyId
        }
      }
      cursor
      remaining
    }
  }
`;
