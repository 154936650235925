import React, {ReactElement, useMemo, useState} from "react";
import {Icon} from "../icon";
import styles from "./table.module.scss";
import classnames from "classnames/bind";
import {Body, ButtonIcon, SelectInput} from "@/shared/v2";
import {ChevronLeftIcon, ChevronRightIcon} from "@/icons";
import {useThemeMode} from "@/context/theme-mode-context";

const bStyles = classnames.bind(styles);

export interface TablePaginationProps {
	currentPage: number;
	pageCount: number;
	handleNext: () => void;
	handlePrev: () => void;
	gotoPage: (page: number) => void;
	disablePagination?: boolean;
	startingRow: number;
	endRow: number;
	totalCount: number;
	onPageSizeChange?: (pageSize: number) => void;
	pageSize: number;
}

// Constants maybe could be props?
const MAX_PAGES = 5;
const MIN_PAGES = 0;


const TablePagination = ({
		currentPage,
		pageCount,
		handleNext,
		handlePrev,
		gotoPage,
		disablePagination,
		startingRow,
		endRow,
		totalCount,
		onPageSizeChange,
		pageSize,
	}: TablePaginationProps): ReactElement | null => {
	const {isDarkMode} = useThemeMode();
	const [min, setMin] = useState(MIN_PAGES);
	const [max, setMax] = useState(MAX_PAGES);

	/**
	 * Making sure there are enough pages on left and right
	 */
	const handlePrevious = (): void => {
		if (currentPage - 1 < min + 2 && min > 0) {
			setMax(prev => prev - 1);
			setMin(prev => prev - 1);
		}
		handlePrev();
	};
	/**
	 * Same as above but ascending
	 */
	const handleNextPage = (): void => {
		if (currentPage + 1 > max - 2) {
			setMin(prev => prev + 1);
			setMax(prev => prev + 1);
		}
		handleNext();
	};
	const handleClick = (page: number): void => {
		if (disablePagination) return;
		if (page - 3 > 0) {
			setMin(page - 3);
			setMax(page + 3);
		} else {
			setMin(MIN_PAGES);
			setMax(MAX_PAGES);
		}
		gotoPage(page);
	};

	// Array for total count of pages (0 to pageCount)
	const pageNumbers = [...Array(pageCount).keys()];

	// within a specified range, we populate pages to click
	const pages = pageNumbers?.map(page => {
		if (page < max && page >= min && page !== 0) {
			return (
				<div
					className={bStyles("circle", {active: page === currentPage, disabled: disablePagination, isDarkMode})}
					key={page}
					onClick={(): void => handleClick(page)}
				>
					<Body size="s" className={styles.pageItem}>{page + 1}</Body>
				</div>
			);
		}
		return null;
	}) || [];

	const showOptions = useMemo(() => {
		return [
				{
						id: "100",
						name: "100",
				},
				{
						id: "50",
						name: "50",
				},
				{
						id: "20",
						name: "20",
				},
				{
						id: "10",
						name: "10",
				},
		];
}, [])

const handlePageSize = (value: string): void => {
	onPageSizeChange?.(parseInt(value));
}

	return (
		<div className={styles.paginationContainer}>
			<div className={styles.info}>
				<Body size="s" color='text-secondary'>
					Showing {startingRow} to {endRow} of {totalCount}
				</Body>
			</div>
			<div className={styles.pagination}>
				<ButtonIcon
					icon={<ChevronLeftIcon className={styles.chevronIcon} />}
					onClick={handlePrevious}
					disabled={currentPage === 0 || disablePagination}
					title="Previous page"
			/>
				<div className={styles.pages}>
					{/* We always want the first page showing*/}
					<div
						className={bStyles("circle", {active: currentPage === 0, disabled: disablePagination, isDarkMode})}
						onClick={(): void => handleClick(0)}
					>
						<Body size="s" className={styles.pageItem}>1</Body>
					</div>
					{min > 1 && <Body size="s">...</Body>}
					{pages}
					{pages?.length > max && <Body size="s">...</Body>}
				</div>

				<ButtonIcon
					icon={<ChevronRightIcon className={styles.chevronIcon} />}
					onClick={handleNextPage}
					disabled={disablePagination || (currentPage * pageSize) >= totalCount || currentPage === pageCount - 1}
					title="Next page"
				/>
			</div>
			<div className={styles.rowsWrapper}>
				<Body size='s' color='text-secondary'>Show</Body>
				<SelectInput
					className={styles.showDropdown}
					options={showOptions}
					value={pageSize?.toString()}
					onChange={(value) => handlePageSize(value)}
				/>
			</div>
		</div>
	);
};

export {TablePagination};
