import React, { useState, useMemo, useCallback } from 'react'; // Added useCallback
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import styles from './IntegrationManagementPage.module.scss';
import classnames from 'classnames'; // Import classnames for conditional styling
import { useToastContext } from '../../context/toast-context';
import {
  GET_SUPPORTED_TOOLS,
  GET_USER_CONNECTIONS,
  GET_CONNECT_URL,
  DELETE_CONNECTION, // Added DELETE_CONNECTION
} from '../../graphql/composio.gql';
import { ConnectionListItem } from './ConnectionListItem';

// Define the enum at the module level (outside the component)
enum ComposioAuthScheme {
  OAUTH2 = 'OAUTH2',
  API_KEY = 'API_KEY',
  BEARER_TOKEN = 'BEARER_TOKEN',
}

// Assuming a shared Spinner component exists
// import { Spinner } from '../../shared/components/Spinner';
// Assuming a shared ErrorMessage component exists
// import { ErrorMessage } from '../../shared/components/ErrorMessage';

// Placeholder types - replace with actual generated types if available
interface SupportedTool {
  id: string;
  name: string;
  description?: string;
  logoUrl?: string;
  categories?: string[]; // Added categories
}

interface UserConnection {
  id: string;
  connectionId: string;
  toolId: string;
  toolName: string;
  toolLogoUrl?: string;
  status: string; // Consider using an enum if defined: 'ACTIVE', 'ERROR', 'REVOKED', 'PENDING'
  createdAt: string;
}

export const IntegrationManagementPage: React.FC = () => {
  // State for loading indicators
  const [connectToolId, setConnectToolId] = useState<string | null>(null);
  const [disconnectingId, setDisconnectingId] = useState<string | null>(null);
  const { updateToast } = useToastContext();
  // State for collapsible categories { [categoryName: string]: boolean }
  const [expandedCategories, setExpandedCategories] = useState<Record<string, boolean>>({});

  // Use the real GraphQL queries to fetch data
  // Update the query result type to include categories
  const {
    data: supportedToolsData,
    loading: supportedToolsLoading,
    error: supportedToolsError,
  } = useQuery<{ composioSupportedTools: SupportedTool[] }>(GET_SUPPORTED_TOOLS, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only', // Consider 'cache-and-network' or 'cache-first' for better UX
  });

  const {
    data: userConnectionsData,
    loading: userConnectionsLoading,
    error: userConnectionsError,
    refetch: refetchUserConnections,
  } = useQuery<{ composioConnections: UserConnection[] }>(GET_USER_CONNECTIONS, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });

  // Extract data from query results or use empty arrays if data is not available
  const supportedTools = supportedToolsData?.composioSupportedTools || [];
  const userConnections = userConnectionsData?.composioConnections || [];
  
  // Show loading state if either query is loading
  const isFullyLoading = supportedToolsLoading || userConnectionsLoading;
  
  // Create maps for connected tools
  const connectedToolIds = new Set(userConnections.map(conn => conn.toolId));
  const connectionMap = new Map(userConnections.map(conn => [conn.toolId, conn]));

  // --- Grouping Logic ---
  const categoryOrder = [
    'Collaboration & Communication',
    'Productivity & Project Management',
    'Document & File Management',
    'CRM',
    'Sales & Customer Support',
    'HR & Recruiting',
    'Finance & Accounting',
    'Developer Tools & DevOps',
    'Marketing & Social Media',
    'Analytics & Data',
    'AI & Machine Learning',
    'Design & Creative Tools',
    'E-commerce',
    'Education & LMS',
    'Other / Miscellaneous', // Catch-all for known but less common categories
  ];
  const OTHER_CATEGORY = 'Other Integrations'; // Name for tools not matching above

  const groupedTools = useMemo(() => {
    const groups: Record<string, SupportedTool[]> = {};

    supportedTools.forEach(tool => {
      let assignedCategory = OTHER_CATEGORY; // Default category

      if (tool.categories && tool.categories.length > 0) {
        // Find the first category that matches our defined order
        const matchedCategory = categoryOrder.find(cat => tool.categories?.includes(cat));
        if (matchedCategory) {
          assignedCategory = matchedCategory;
        } else if (tool.categories[0]) {
          // If no primary category matches, but it has categories, use the first one
          // This helps group less common categories together if needed, though they'll appear last
          assignedCategory = tool.categories[0];
          // Ensure this less common category exists in the order if we want to display it
          if (!categoryOrder.includes(assignedCategory)) {
             // For simplicity now, lump into OTHER_CATEGORY
             assignedCategory = OTHER_CATEGORY;
          }
        }
      }

      if (!groups[assignedCategory]) {
        groups[assignedCategory] = [];
      }
      groups[assignedCategory].push(tool);
    });

    // Ensure the OTHER_CATEGORY exists even if empty initially
    if (!groups[OTHER_CATEGORY]) {
        groups[OTHER_CATEGORY] = [];
    }

    return groups;
  }, [supportedTools]);

  // Get a list of categories that actually have tools, plus 'Other' if it has tools
  const categoriesWithTools = useMemo(() => {
     const activeCategories = categoryOrder.filter(cat => groupedTools[cat]?.length > 0);
     if (groupedTools[OTHER_CATEGORY]?.length > 0 && !activeCategories.includes(OTHER_CATEGORY)) {
         activeCategories.push(OTHER_CATEGORY);
     }
     // Expand the first category by default when tools load
     if (activeCategories.length > 0 && Object.keys(expandedCategories).length === 0) {
        setExpandedCategories({ [activeCategories[0]]: true });
     }

     return activeCategories;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedTools]); // Keep expandedCategories out to avoid loop on set

  // Toggle category expansion
  const toggleCategory = useCallback((category: string) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  }, []);
  // --- End Grouping Logic ---


  // Use real mutation hooks
  // Updated variable type to include optional authScheme
  const [getConnectUrl, { loading: connectLoading, error: connectError }] = useMutation<
    { composioGetConnectUrl: string },
    { toolId: string; authScheme?: ComposioAuthScheme }
  >(GET_CONNECT_URL);

  const [deleteConnection, { loading: disconnectLoading, error: disconnectError }] = useMutation<
    { composioDeleteConnection: boolean },
    { connectionId: string }
  >(DELETE_CONNECTION, {
    onCompleted: () => {
      updateToast({ description: 'Service disconnected successfully.', type: 'success' });
      // Refetch connections after successful deletion
      refetchUserConnections();
    },
    onError: (error) => {
      console.error('Failed to disconnect service:', error);
      updateToast({ description: `Error disconnecting service: ${error.message}`, type: 'failure' });
    },
  });

  // Loading state - moved below all hooks to avoid conditional hook rendering
  if (isFullyLoading && supportedTools.length === 0 && userConnections.length === 0) {
    return <div className={styles.loadingMessage}>Loading integrations...</div>;
  }

  // Updated function signature to use enum type and default value
  const handleConnect = async (toolId: string, authScheme: ComposioAuthScheme = ComposioAuthScheme.OAUTH2) => {
    setConnectToolId(toolId); // Set loading state for this specific tool
    try {
      // Find the tool name for better error messages
      const toolName = supportedTools.find(tool => tool.id === toolId)?.name || toolId;
      
      // Call the GraphQL mutation to get the connection URL with auth scheme
      const { data } = await getConnectUrl({ 
        variables: { 
          toolId,
          authScheme 
        } 
      });
      
      if (data?.composioGetConnectUrl) {
        // Show a toast notification that we're redirecting
        const authMethodText = {
          'OAUTH2': 'OAuth',
          'API_KEY': 'API Key',
          'BEARER_TOKEN': 'Bearer Token'
        }[authScheme] || 'authentication';
        
        updateToast({
          description: `Redirecting to connect ${toolName} with ${authMethodText}...`,
          type: 'informational' // Changed 'info' to 'informational'
        });

        // Redirect the user to the Composio auth flow
        // Use a slight delay to ensure the toast is shown
        setTimeout(() => {
          window.location.href = data.composioGetConnectUrl;
        }, 500);
        
        // Note: The page will navigate away here. Callback handling is separate.
      } else {
        const errorMsg = `Failed to start connection process for ${toolName}. Please try again.`;
        console.error(errorMsg);
        updateToast({ description: errorMsg, type: 'failure' });
        setConnectToolId(null); // Reset loading state
      }
    } catch (err) {
      const error = err as ApolloError | Error; // Type assertion
      const toolName = supportedTools.find(tool => tool.id === toolId)?.name || toolId;
      const errorMsg = `Error connecting ${toolName}: ${error instanceof Error ? error.message : 'Unknown error'}`;
      console.error('Failed to get connect URL:', error);
      updateToast({ description: errorMsg, type: 'failure' });
      setConnectToolId(null); // Reset loading state
    }
    // We don't need a finally block because if the redirect succeeds, 
    // the page will navigate away and this component will unmount
  };
  // --- End Connect Logic ---

  const handleDisconnect = async (connectionId: string, toolId: string) => {
    // Prevent multiple clicks while processing
    if (disconnectLoading) return;

    // Find tool name for better UX messages
    const connection = userConnections.find(conn => conn.id === connectionId);
    const toolName = connection?.toolName || 
                     supportedTools.find(tool => tool.id === toolId)?.name || 
                     "service";

    // Show confirmation dialog
    if (!window.confirm(`Are you sure you want to disconnect from ${toolName}? The AI assistants will no longer be able to access this service on your behalf.`)) {
      return; // User cancelled
    }

    setDisconnectingId(connectionId); // Set loading state for this specific connection
    
    try {
      // Display processing message
      updateToast({ description: `Disconnecting ${toolName}...`, type: 'informational' }); // Changed 'info' to 'informational'

      // Execute the GraphQL mutation
      await deleteConnection({ 
        variables: { connectionId },
        // Update cache optimistically
        optimisticResponse: {
          composioDeleteConnection: true
        }
      });
      
      // Success/error handling is done in onCompleted/onError callbacks (in the mutation setup)
    } catch (err) {
      // Catch potential network errors not handled by onError (less likely with Apollo Client)
      const error = err as Error;
      console.error('Disconnect mutation failed unexpectedly:', error);
      updateToast({ description: `An unexpected error occurred disconnecting ${toolName}: ${error.message}`, type: 'failure' });
    } finally {
      setDisconnectingId(null); // Reset loading state
    }
  };
  // --- End Disconnect Logic ---

  // We don't need these variables anymore since we'll handle the errors directly in the JSX

  return (
    <div className={styles.pageContainer}>
      <h1 className={styles.pageHeader}>Third-Party Integrations</h1>
      <p className={styles.pageDescription}>Connect your accounts to enable AI assistants to help you with tasks in these services.</p>

      {/* Render grouped tools */}
      {categoriesWithTools.length === 0 && !isFullyLoading && (
         <p className={styles.noToolsMessage}>No integration tools available at this time.</p>
      )}

      {categoriesWithTools.map(category => {
        const isExpanded = !!expandedCategories[category];
        return (
          <div key={category} className={styles.categoryGroup}>
            <h2
              className={classnames(styles.categoryHeader, styles.clickable)}
              onClick={() => toggleCategory(category)}
            >
              {category}
              <span className={classnames(styles.expandIcon, { [styles.expanded]: isExpanded })}>
                {/* Simple +/- indicator, replace with icons if available */}
                {isExpanded ? '−' : '+'}
              </span>
            </h2>
            {isExpanded && groupedTools[category].map((tool) => { // Conditionally render items
              const isConnected = connectedToolIds.has(tool.id);
              const connection = connectionMap.get(tool.id) || null;

              return (
                <ConnectionListItem
                  key={tool.id}
                  tool={tool}
                  connection={connection}
                  onConnect={handleConnect}
                  onDisconnect={handleDisconnect}
                  isConnectLoading={connectLoading && connectToolId === tool.id}
                  isDisconnectLoading={disconnectLoading && disconnectingId === connection?.id}
                />
              );
            })}
          </div>
        );
      })}

      {/* Display general query errors */}
      {supportedToolsError && (
        <p className={styles.errorMessage}>
          Error loading supported tools: {supportedToolsError.message || 'Unknown error'}
        </p>
      )}
      {userConnectionsError && (
        <p className={styles.errorMessage}>
          Error loading your connections: {userConnectionsError.message || 'Unknown error'}
        </p>
      )}
      
      {/* Add a refresh button if we have errors */}
      {(supportedToolsError || userConnectionsError) && (
        <button 
          className={styles.refreshButton} 
          onClick={() => {
            if (supportedToolsError) refetchUserConnections(); // Should refetch supported tools too?
            if (userConnectionsError) refetchUserConnections();
            updateToast({ description: "Refreshing connections...", type: "informational" }); // Changed 'info' to 'informational'
          }}
        >
          Refresh
        </button>
      )}
    </div>
  );
};

// Optional: Export for routing
// export default IntegrationManagementPage;
