import {useMutation} from "@apollo/client";
import {useNavigate} from "react-router";
import classNames from "classnames/bind";
import React, {ReactElement, useCallback, useMemo, useState} from "react";

import {
	BinDeleteIcon,
	ChatBubbleCameraIcon,
	ChatBubbleQuestionIcon,
	CopyDocumentsIcon,
	DotsIcon,
	PreviewFullScreenArrows,
	PublishSendFileIcon,
	SparkAiStarsIcon,
} from "../../../../icons";
import {AssistiveChip} from "../../../../shared/v2/assistive-chip";
import {Badge} from "../../../../workspace-settings/components/model-card/badge";
import {ButtonIcon, Caption, Card, DropdownItem, Subheader} from "../../../../shared/v2";
import {Dropdown} from "../../../../shared/v2/dropdown";
import {
	CopySurveyReturn,
	CopySurveyVars,
	DeleteSurveyReturn,
	DeleteSurveyVars,
	Survey,
} from "../../../../models/survey";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {
	COPY_SURVEY,
	DELETE_SURVEY,
	PUBLISH_SURVEY,
} from "../../../../graphql/mutations/survey-mutations";
import {useToastContext} from "../../../../context/toast-context";
import {SURVEY_FRAGMENT} from "../../../../graphql/fragments/fragments";
import {
	deleteSurveyDashboard,
	updateSurveyDashboard,
} from "../../../../shared/utility/update-survey-dashboard";
import {DeleteSurveyModal} from "../../../../modals/delete-survey";
import {useWorkspaceContext} from "../../../../context/workspace-context";
import {useUserContext} from "../../../../context/user-context";

import styles from "./campaign-card.module.scss";
import {pluralize} from "../../../../shared/utility/utility";
import {useInView} from "react-intersection-observer";
import {canStartConversation} from "./utils/canStartConversation";

const bStyles = classNames.bind(styles);

const cx = classNames.bind(styles);

export interface CampaignCardProps {
	campaign: Survey;
}

const MIN_RESPONSES = 5;

export const CampaignCard = ({campaign}: CampaignCardProps): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {user} = useUserContext();
	const navigate = useNavigate();
	const {isDarkMode} = useThemeMode();
	const {updateToast} = useToastContext();
	const hasAiSummary = useMemo(
		() => campaign.surveyInsights?.some(insight => Boolean(insight.summary)),
		[campaign.surveyInsights],
	);

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const {ref, inView} = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

	const [copySurvey] = useMutation<CopySurveyReturn, CopySurveyVars>(COPY_SURVEY, {
		onCompleted: () =>
			updateToast({
				type: "informational",
				description: "Copied survey",
			}),
	});

	const [deleteSurveyHandler] = useMutation<DeleteSurveyReturn, DeleteSurveyVars>(DELETE_SURVEY, {
		onCompleted: () =>
			updateToast({
				type: "informational",
				description: "Survey deleted",
			}),
	});

	const [publishSurvey] = useMutation(PUBLISH_SURVEY, {
		onCompleted: () => {
			// Probably should do this in index.html?
			const host = window.location.hostname;
			if (host !== "localhost" && !host.includes("staging")) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(window as any).Vitally.track({
					event: "published-new-campaign",
					userId: user.id,
					properties: {
						accountId: workspaceId,
					},
				});
			}
			updateToast({description: "Vurvey opened", type: "informational"});
		},
	});

	const totalVideoDuration = campaign.totalVideoDuration / 1000 / 60;

	const navigateToCampaign = ():void => {
		navigate(`/survey/${campaign.id}/${campaign.status === "DRAFT" ? "questions" : "results"}`);
	};

	const hadleOpenInCoPilot = (event?: MouseEvent): void => {
		event?.stopPropagation();

		navigate("/workflow/conversation", {
			state: {
				chatConversation: {
					chat: {
						surveys: [campaign],
					}
				}
			}
		});
	};

	const handlePreview = (event?: MouseEvent): void => {
		event?.stopPropagation();

		navigateToCampaign();
	};

	const handlePublish = (event?: MouseEvent): void => {
		event?.stopPropagation();

		publishSurvey({variables: {id: campaign.id}});
	};

	const handleCopy = (event?: MouseEvent): void => {
		event?.stopPropagation();

		copySurvey({
			variables: {id: campaign.id},
			update(cache, {data}) {
				if (!data) return;
				const newSurveyRef = cache.writeFragment({
					data: data.duplicateSurvey,
					fragment: SURVEY_FRAGMENT,
				});
				updateSurveyDashboard(cache, newSurveyRef);
			},
		});
	};

	const handleDelete = (event?: MouseEvent): void => {
		event?.stopPropagation();

		setIsDeleteModalOpen(true);
	};

	const confirmDelete = (surveyId: string): void => {
		deleteSurveyHandler({
			variables: {id: surveyId},
			update(cache, {data}) {
				if (!data) return;
				const survey = cache.identify(data.deleteSurvey);
				deleteSurveyDashboard(cache, survey, data.deleteSurvey.id);
			},
		});
	};

	const renderVideoPlaceholders = (): JSX.Element => {
		return <>
			<div className={bStyles("previewImage", "firstPlaceholder")} />
			<div className={bStyles("previewImage", "secondPlaceholder")} />
			<div className={bStyles("previewImage", "thirdPlaceholder")} />
			<div className={bStyles("previewImage", "fourthPlaceholder")} />
			<div className={bStyles("previewImage", "fifthPlaceholder")} />
		</>;
	};

	const renderPreviewList = useCallback((): JSX.Element | JSX.Element[] => {
		const videoPreviewCount = campaign.answersPreview.length;
		const completedCount = campaign.completedResponseCount;

		if (videoPreviewCount === 0 && completedCount === 0) {
			return renderVideoPlaceholders();
		}
		// If previews and completed are above 5, we can show normally
		if (videoPreviewCount > MIN_RESPONSES && completedCount > MIN_RESPONSES) {
			return <>
				{campaign.answersPreview.slice(0, 5).map((answer, index) => (
					<img
						alt={`Preview ${index}`}
						className={styles.previewImage}
						key={index}
						src={answer.video.badge}
					/>
				))}
				<div className={bStyles("previewImage", "moreWrapper")}>
					<img
						src={campaign.answersPreview[5].video.badge}
						className={styles.overlayImage}
					/>
					<Caption
						className={styles.moreText}
						type="semibold"
						color="text-secondary"
					>
							+{completedCount - 5}
					</Caption>
				</div>
			</>;
		}

		// Now we check if there are completes, but perhaps not any videos
		if (videoPreviewCount < MIN_RESPONSES && completedCount >= 1) {
			if (videoPreviewCount === 0) return <Caption className={styles.completes}>
				{completedCount} {pluralize(completedCount, "complete")}
			</Caption>
			const totalCount = completedCount - videoPreviewCount
			return <>
				{campaign.answersPreview.map((answer, index) => (
					<img
						alt={`Preview ${index}`}
						className={styles.previewImage}
						key={index}
						src={answer.video.badge}
					/>
				))}
				{totalCount > 0 &&
					<Caption className={styles.completes}>
						+{totalCount} {pluralize(totalCount, "complete")}
					</Caption>
				}
			</>
		}

		// Otherwise, we want to show a max amount of the completed count (1-5)
		return campaign.answersPreview.slice(0, videoPreviewCount).map((answer, index) => (
			<img
				alt={`Preview ${index}`}
				className={styles.previewImage}
				key={index}
				src={answer.video.badge}
			/>
		));
	}, [campaign]);

	const dropdownItems = useMemo(() => {
		const items: DropdownItem[] = [];
		
		if (canStartConversation(campaign)) {
			items.push({
				label: "Start Conversation",
				color: "primary",
				icon: <SparkAiStarsIcon />,
				onClick: hadleOpenInCoPilot,
			});
		}

		items.push({
			label: "Preview",
			icon: <PreviewFullScreenArrows />,
			onClick: handlePreview,
		});

		if (campaign.status === "DRAFT") {
			items.push({
				color: "default",
				label: "Publish",
				icon: <PublishSendFileIcon />,
				onClick: handlePublish,
			});
		}

		items.push({
			color: "default",
			label: "Copy",
			icon: <CopyDocumentsIcon />,
			onClick: handleCopy,
		});

		items.push({
			label: "Delete",
			color: "danger",
			icon: <BinDeleteIcon />,
			onClick: handleDelete,
		});

		return items;
	}, [campaign.status]);

	return (
		<>
			<Card
				className={cx("campaignCard", {isDarkMode})}
				onClick={navigateToCampaign}
			>
				<div className={styles.campaignTop}>
					<Badge text={campaign.status} />
					<Subheader className={styles.campaignName} type="semibold">
						{campaign.name}
					</Subheader>
					<Caption className={styles.campaignCreator} color="text-secondary">
						by {campaign.creator.firstName} {campaign.creator.lastName}
					</Caption>
					<Dropdown
						className={styles.campaignOptions}
						popupClassName={styles.dropdownPopup}
						items={dropdownItems}
						position="right-start"
						space={4}
						trigger={<ButtonIcon icon={<DotsIcon />} />}
					/>
				</div>

				<div className={styles.previewList} ref={ref}>
					{inView && renderPreviewList()}
				</div>

				<div className={styles.chips}>
					<AssistiveChip
						text={campaign.questionCount.toString()}
						icon={<ChatBubbleQuestionIcon />}
						size="small"
					/>
					<AssistiveChip
						text={`${totalVideoDuration.toFixed(0)} min`}
						icon={<ChatBubbleCameraIcon />}
						size="small"
					/>
					{
						hasAiSummary && (
							<AssistiveChip
								text="Ai Summary"
								icon={<SparkAiStarsIcon />}
								size="small"
								color="brand"
							/>
						)
					}
				</div>
			</Card>
			<DeleteSurveyModal
				isOpen={isDeleteModalOpen}
				onClose={() => setIsDeleteModalOpen(false)}
				onDelete={confirmDelete}
				survey={{
					id: campaign.id,
					name: campaign.name,
				}}
			/>
		</>
	);
};
