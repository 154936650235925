import {useMutation, ApolloError} from "@apollo/client";
import {Dispatch, useCallback, useEffect, useState, useRef} from "react"; // Import useRef
import {ChatSendQuestionResponse, ChatSendQuestionVariables} from "../chat-conversation-context";
import {CHAT_SEND_QUESTION} from "@/graphql/mutations/ai-mutations";
import {useWorkspaceContext} from "@/context/workspace-context";
import {getImageBase64} from "@/shared/utility";
import {ChatConversationMode, ChatState, SendQuestionPayload, ChatAction, ChatActionTypes} from "@/reducer/chat-reducer";

const FIVE_MINUTES = 5 * 60 * 1000;

export const useSendQuestion = (state: ChatState, dispatch: Dispatch<ChatAction>) => {
  const {workspace: {id: workspaceId}} = useWorkspaceContext();
  const {
    current,
    actions: {
      isSendingQuestion,
      isCreating,
      isUpdating,
    },
  } = state;
  
  // Store the explicit chat mode from the most recent payload
  const [explicitChatMode, setExplicitChatMode] = useState<ChatConversationMode | null>(null);
  const isExecutingSendRef = useRef(false); // Use ref to track execution status

  const [chatSendQuestion] = useMutation<ChatSendQuestionResponse, ChatSendQuestionVariables>(CHAT_SEND_QUESTION, {
    context: {
      timeout: FIVE_MINUTES,
    },
    onError: (error: ApolloError) => {
      console.error('[SendQuestion] Mutation Error:', error);
      // Dispatch the dedicated error action to reset state in the reducer
      dispatch({ type: ChatActionTypes.SEND_QUESTION_ERROR, payload: { error: error.message } });
      // Clear local component state as well
      setExplicitChatMode(null); 
      isExecutingSendRef.current = false; // Reset execution flag on error
    },
    onCompleted: (data) => {
       console.log('[SendQuestion] Mutation Completed:', data);
       // Success state (isSendingQuestion=false) is handled by SEND_QUESTION_SUCCESS in the reducer
       // Reset execution flag on successful completion
       isExecutingSendRef.current = false; 
    }
  });

  const handleSendQuestion = useCallback(async () => {
    console.log('[handleSendQuestion] Entered function', { isSendingQuestion, isExecuting: isExecutingSendRef.current }); // Add log
    // Check state flags AND local execution ref
    if (isCreating || !current.id || !isSendingQuestion || isUpdating || isExecutingSendRef.current) {
       if (isExecutingSendRef.current) {
         console.warn('[handleSendQuestion] Already executing (ref check), skipping duplicate call.');
       } else {
         console.log('[SendQuestion handleSendQuestion] Conditions not met (state check), skipping call.', { isCreating, currentId: current.id, isSendingQuestion, isUpdating });
       }
      return;
    }
    
    isExecutingSendRef.current = true; // Set execution flag
    console.log('[SendQuestion handleSendQuestion] Starting execution (ref set true)');

    const {nextQuestion} = current;
    const image = nextQuestion.image ? await getImageBase64(nextQuestion.image) : undefined;
    
    const chatModeToUse = explicitChatMode || current.mode;
    
    console.log('[SendQuestion handleSendQuestion] Using chat mode:', chatModeToUse, 
      explicitChatMode ? '(explicitly passed)' : '(from conversation state)');

    try {
      // Clear explicit mode *before* calling mutation
      setExplicitChatMode(null); 

      await chatSendQuestion({ // Await the mutation call
        variables: {
          workspaceId,
          question: nextQuestion.query ?? "",
          conversationId: current.id,
          instructions: nextQuestion.instructions ?? undefined,
          image,
          documentId: nextQuestion.document?.id,
          smartPromptEnabled: chatModeToUse === ChatConversationMode.SMART_TOOLS,
          personaId: nextQuestion.persona?.id,
          systemAgentId: nextQuestion.systemAgent?.id,
          messageId: nextQuestion.message?.id,
          chatMode: chatModeToUse, 
        },
      });
      // Note: isExecutingSendRef is reset in onError and onCompleted
    } catch (err) {
       // Error should be caught by onError handler, but log here just in case
       console.error('[SendQuestion handleSendQuestion] Error during chatSendQuestion call:', err);
       isExecutingSendRef.current = false; // Ensure ref is reset even if onError doesn't fire
       // Dispatch error action again for redundancy? Maybe not needed if onError is reliable.
       // dispatch({ type: ChatActionTypes.SEND_QUESTION_ERROR, payload: { error: err.message || 'Unknown error during send' } });
    }

  }, [isCreating, isUpdating, isSendingQuestion, current, explicitChatMode, workspaceId, chatSendQuestion, dispatch]); 

  // Add useEffect hook to trigger message sending when isSendingQuestion becomes true
  useEffect(() => {
    if (isSendingQuestion && !isExecutingSendRef.current) {
      console.log('[useSendQuestion useEffect] isSendingQuestion is true, triggering handleSendQuestion');
      handleSendQuestion();
    }
  }, [isSendingQuestion, handleSendQuestion]);

  return useCallback((payload: SendQuestionPayload) => {
    // Check ref *before* doing anything else to prevent multiple optimistic updates/calls
    if (isExecutingSendRef.current) { 
      console.warn('[SendQuestion Callback] Already executing send (ref check), ignoring new request.');
      return;
    }
    
    // If mode is provided in the payload, capture it before dispatching
    if (payload.mode) {
      console.log('[SendQuestion Callback] Received explicit mode:', payload.mode);
      setExplicitChatMode(payload.mode);
    }

    // Dispatch action to update state optimistically (sets isSendingQuestion = true)
    console.log('[SendQuestion Callback] Dispatching SEND_QUESTION');
    dispatch({type: ChatActionTypes.SEND_QUESTION, payload});
    
    // We don't need to call handleSendQuestion() here anymore
    // The useEffect will trigger it when isSendingQuestion becomes true

  }, [dispatch]); 
}
