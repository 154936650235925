/**
 * Maps special characters to their standard Latin alphabet equivalents.
 * Used for text normalization in search operations.
 */
export const specialCharsMap: Record<string, string> = {
  ⱥ: "a", Ⱥ: "a", æ: "a", Æ: "a", ǽ: "a", Ǽ: "a", ǣ: "a", Ǣ: "a",
  ƀ: "b", ɓ: "b", ƃ: "b", Ƃ: "b", Ƀ: "b", Ɓ: "b",
  ȼ: "c", ƈ: "c", Ȼ: "c", Ƈ: "c",
  đ: "d", ƌ: "d", ɗ: "d", ð: "d", ǳ: "d", ǆ: "d", Đ: "d",
  Ƌ: "d", Ɗ: "d", Ð: "d", Ǳ: "d", ǲ: "d", Ǆ: "d", ǅ: "d",
  ɇ: "e", Ɇ: "e",
  ƒ: "f", Ƒ: "f", "℉": "f",
  ǥ: "g", ɠ: "g", Ǥ: "g", Ɠ: "g",
  ħ: "h", ⱨ: "h", Ħ: "h", Ⱨ: "h",
  ı: "i", ɨ: "i", ĳ: "i", Ɨ: "i", Ĳ: "i",
  ɉ: "j", Ɉ: "j",
  ƙ: "k", ⱪ: "k", Ƙ: "k", Ⱪ: "k",
  ŀ: "l", ⱡ: "l", ƚ: "l", ł: "l", ǉ: "l",
  Ŀ: "l", Ⱡ: "l", Ƚ: "l", Ł: "l", Ǉ: "l", ǈ: "l",
  ɲ: "n", ƞ: "n", ǌ: "n", ŋ: "n", Ɲ: "n", Ƞ: "n", Ǌ: "n", ǋ: "n", Ŋ: "n",
  ø: "o", ǿ: "o", ƣ: "o", œ: "o", Ø: "o", Ǿ: "o", Ƣ: "o", Œ: "o",
  ᵽ: "p", ƥ: "p", Ᵽ: "p", Ƥ: "p",
  ɋ: "q", Ɋ: "q",
  ɍ: "r", ɽ: "r", Ɍ: "r", Ɽ: "r",
  ß: "s", ẞ: "s",
  ƭ: "t", ʈ: "t", ⱦ: "t", þ: "t", ŧ: "t", Ƭ: "t", Ʈ: "t", Ⱦ: "t", Þ: "t", Ŧ: "t",
  ʉ: "u", Ʉ: "u",
  ʋ: "v", Ʋ: "v",
  ⱳ: "w", Ⱳ: "w",
  ɏ: "y", ƴ: "y", Ɏ: "y", Ƴ: "y",
  ƶ: "z", ȥ: "z", ⱬ: "z", Ƶ: "z", Ȥ: "z", Ⱬ: "z",
};


export const normalizeText = (text: string): string => {
  let normalizedText = text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

  for (const [special, replacement] of Object.entries(specialCharsMap)) {
    normalizedText = normalizedText.replace(
      new RegExp(special, "g"),
      replacement
    );
  }

  return normalizedText;
};
