import React, {ReactElement, useContext, useMemo, useState} from "react";
import {FileUploadIcon, FilterIcon, SparkAiStarsIcon} from "../../../icons";
import {Outlet} from "react-router-dom";

import {FilterDisplay} from "../../../shared";
import {useFilter, useParams} from "../../../route";
import {useMemoObject} from "../../../hooks";
import styles from "./results.module.scss";
import {ResultsNav} from "../../components/results-nav";
import {SurveyContext} from "../../../context/survey-context";
import {FilterPanel} from "../../components/filter-panel";
import {useQuery} from "@apollo/client";
import {GET_ALL_ATTRIBUTES_W_EXTANT} from "../../../graphql/queries/attribute-queries";
import {useLocation, useNavigate} from "react-router";
import classNames from "classnames";
import {CSVUseFilterModal} from "../../modals/use-filter";
import config from "../../../config";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {Button} from "../../../shared/v2";
import {SurveyParams} from "@/survey";
import {canStartConversation} from "../survey-dashboard/campaign-card/utils/canStartConversation";

const Results = (): ReactElement => {
	const {questions} = useContext(SurveyContext);
	const {questionId = questions[0]?.id} = useParams<SurveyParams>();
	const {pathname} = useLocation();
	const navigate = useNavigate();
	const [handleScroll, setHandleScroll] = useState<React.UIEventHandler<HTMLElement>>();
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {membersFilter, updateFilter, removeCustomProp, removeFilter} = useFilter();
	const attributes = useQuery(GET_ALL_ATTRIBUTES_W_EXTANT, {variables: {workspaceId}});

	const handleClearFilters = (): void => {
		updateFilter({});
	};

	const {
		showFilter,
		survey,
		setShowFilter,
		setResponsesTabPage,
	} = useContext(SurveyContext);

	const context = useMemoObject({setHandleScroll});

	const [showModal, setModal] = useState<boolean>(false);
	const toggleModal = (): void => setModal(prev => !prev);

	const filteredUrl = `${config.apiHost}/exports/survey/responses/${survey.id}/${
		encodeURIComponent(JSON.stringify({
			...membersFilter,
			ageRange: membersFilter.ageRange ? [membersFilter.ageRange] : undefined,
			gender: membersFilter.gender?.map((a: string): string => a.toLocaleLowerCase()),
		}))
	}`;
	const unfilteredUrl = `${config.apiHost}/exports/survey/responses/${survey.id}/{}`;

	// Triggers download if there's no filter
	const handleUnfilteredUrl = (): void => {
		window.location.href = unfilteredUrl;
	}

	const handleConversation = () => {
		const question = questions.find(q => q.id === questionId);

		navigate("/workflow/conversation", {
			state: {
				chatConversation: {
					chat: {
						questions: [question],
					}
				}
			}
		});
	}

	const isStartConversationDisabled = useMemo(() => {
		return !canStartConversation(survey);
	}, [survey]);

	return (
		<div
			className={classNames(styles.mainContainer, pathname.includes("analyze") && styles.nogrid)}>
			{ !pathname.includes("analyze") &&
				<ResultsNav />
			}
			<div className={styles.container} onScroll={handleScroll}>
				<div className={styles.filterDisplay}>

					{!pathname.includes("summary") &&
						<div className={styles.buttons}>
							<Button
								variant="outlined"
								leftIcon={<FilterIcon />}
								className={styles.filter}
								onClick={() => setShowFilter(!showFilter)}
							>
								Filter
							</Button>
							<div className={styles.rightButtons}>
								<Button
									disabled={isStartConversationDisabled}
									leftIcon={<SparkAiStarsIcon />}
									variant="text"
									onClick={handleConversation}
								>
									Talk with Responses
								</Button>

								{(filteredUrl === unfilteredUrl) ?
									<Button
										leftIcon={<FileUploadIcon />}
										variant="text"
										onClick={handleUnfilteredUrl}
									>
										Export Responses (.csv)
									</Button> :
									<Button
										leftIcon={<FileUploadIcon />}
										variant="text"
										onClick={toggleModal}
									>
										Export Responses (.csv)
									</Button>
								}

							</div>
						</div>
					}
					<FilterDisplay
						filter={membersFilter}
						attributes={attributes?.data?.attributes?.items}
						handleClearFilters={handleClearFilters}
						handleRemoveFilter={removeFilter}
						handleRemoveCustomProp={removeCustomProp}
					/>
				</div>
				<Outlet context={context} />
				{
					showFilter &&
					<FilterPanel closeFilter={setShowFilter} setPage={setResponsesTabPage} campaignFilter/>
				}
			</div>
			<CSVUseFilterModal
				isOpen={showModal}
				onClose={toggleModal}
				filteredUrl={filteredUrl}
				unfilteredUrl={unfilteredUrl}
			/>
		</div>
	);
};

export {Results};
