import {ARUploadModal} from "../../../modals/ar-upload";
import {Form, FormControl} from "../../components/builder-form";
import {
	determineQuestionType,
	Question,
	QuestionType,
	UpdateQuestionData,
	UpdateQuestionVars,
} from "../../../models/questions";
import React, {ReactElement, useContext, useState, useEffect} from "react";
import {animated, useSpring} from "react-spring";
import {gql, useMutation} from "@apollo/client";
import {
	CREATE_ATTRIBUTE,
	CREATE_ATTRIBUTE_RULE,
	DELETE_ATTRIBUTE_RULE,
	UPDATE_ATTRIBUTE_RULE,
} from "../../../graphql/mutations/attribute-mutations";
import {
	Attribute,
	AttributeRule,
	AttributeType,
	CreateAttributeData,
	CreateAttributeRuleData,
	CreateAttributeRuleVars,
	CreateAttributeVars,
	RuleAction,
	UpdateAttributeRuleData,
	UpdateAttributeRuleVars,
} from "../../../models/attribute";
import {ATTR_RULE_FRAGMENT, QUESTION_TEMPLATE_FRAGMENT} from "../../../graphql/fragments/fragments";
import {AttributeCreatorModal} from "../../../modals/attribute-creator";
import {QUESTION_TYPES} from "../../../shared/constants/constants";
import {UPDATE_QUESTION} from "../../../graphql/mutations/survey-mutations";
import {AttributeSelector} from "../attribute-selector";
import {TimeSelector} from "../time-selector";
import {QuestionImageUploadModal} from "../../../modals/question-image-upload";
import {QuestionMedia} from "../question-media";
import {SurveyContext} from "../../../context/survey-context";
import {ToastContext} from "../../../context/toast-context";
import {ToggleSwitch} from "../../../shared/components/toggle-switch";
import styles from "./question-previewer.module.scss";
import {FormBuilder} from "../form-builder";
import {updateCacheAddPageItem} from "../../../shared/utility/update-cache";
import {CREATE_QUESTION_TEMPLATE_FROM_QUESTION} from "../../../graphql/mutations/question-templates";
import {Card} from "../../../shared/layout/card";
import {AutoResizeTextarea} from "../../../shared/v2/inputs";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {Button, Checkbox, RadioGroup} from "../../../shared/v2";
import {DownloadIcon, EyeIcon, FolderAddPlusIcon} from "../../../icons";
import config from "../../../config";
import {client} from "../../../shared/utility/client";

export interface VideoFollowUpFormProps {
	question: Question;
	handleTimerChange: (newTime: number) => void;
	handleTextChange: (newValue: string) => void;
	handleFollowUpChange: (newValue: string) => void;
	checkBox?: JSX.Element;
	mediaForm: ReactElement;
}

export const VideoFollowUpForm = ({
	question,
	handleTimerChange,
	handleTextChange,
	handleFollowUpChange,
	mediaForm,
}: VideoFollowUpFormProps): ReactElement => {
	const {workspace} = useWorkspaceContext();

	const [text, setText] = useState(question.text ?? "");
	const [followUp, setFollowUp] = useState(question.followUp ?? "");

	const isMainQuestion = question.type === "NONE";
	return (
		<>
			<FormControl label="Question" id="question">
				<AutoResizeTextarea
					value={isMainQuestion ? text : followUp}
					onChange={isMainQuestion ? setText : setFollowUp}
					onBlur={isMainQuestion ? handleTextChange : handleFollowUpChange}
					placeholder={isMainQuestion ? "Untitled Question" : "Enter your question..."}
					maxLength={400}
					id="video-question"
				/>
				{/* TODO: ucomment when https://vurvey.atlassian.net/browse/VU-1599 will be ready */}
				{/* {checkBox} */}
			</FormControl>

			{isMainQuestion && mediaForm}

			<FormControl className={styles.timeSelector} label="Max response length">
				<TimeSelector
					workspaceId={workspace.id}
					plan={workspace.plan}
					currentTime={question.timeLimit}
					handleChange={handleTimerChange}
				/>
			</FormControl>
		</>
	);
};

export interface QuestionPreviewerProps {
	/**
	 * The question we want to display
	 */
	question: Question;
}

/**
 * Note: maybe this should be a container / page
 * Also could potentially wrap all of this inside of a context
 * @param question Question we are currently looking at and all its values.
 *
 * @returns The question previewer layout and editable fields.
 */
const QuestionPreviewer = (props: QuestionPreviewerProps): ReactElement => {
	const {question} = props;
	const {
		survey: {workspaceId, id: surveyId},
		attributes,
	} = useContext(SurveyContext);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showUploadARModal, setShowUploadARModal] = useState(false);
	const [showAttributeModal, setShowAttributeModal] = useState(false);
	const [isDeletingMedia, setIsDeletingMedia] = useState(false);
	const {updateToast} = useContext(ToastContext);
	const [attr, setAttr] = useState<Attribute>();
	const [rule, setRule] = useState<AttributeRule>();
	const [questionText, setQuestionText] = useState(question.text);

	const [createQuestionTemplate] = useMutation(CREATE_QUESTION_TEMPLATE_FROM_QUESTION, {
		onCompleted: () => updateToast({type: "informational", description: "Question Saved"}),
	});

	/**
	 * Messy. A "custom property" exists if one of the attributes has an attribute rule
	 * that has the same questionId as the current question we are looking at. We
	 * check this every time the questionId changes.
	 *
	 * We could add the fetchPolicy: "cache-and-network" to remove question.id dependency
	 * and the requirement to reset states (routing), but causes way more queries.
	 */
	useEffect(() => {
		setAttr(undefined);
		setRule(undefined);
		if (question.type === QuestionType.NONE || question.rank) return;
		for (const val of attributes) {
			let index = 0;
			const exists = val.rules.some((r, i) => {
				if (r.questionId === question.id) {
					index = i;
					return true;
				}
				return false;
			});
			if (exists) {
				setAttr(val);
				setRule(val.rules[index]);
				return;
			}
		}
	}, [question.id]);

	const [updateQuestion] = useMutation<UpdateQuestionData, UpdateQuestionVars>(UPDATE_QUESTION);

	const questionType = determineQuestionType(question);

	const animateProps = useSpring({
		opacity: 1,
		from: {opacity: 0},
		config: {duration: 250},
	});

	const [createAttribute] = useMutation<CreateAttributeData, CreateAttributeVars>(CREATE_ATTRIBUTE);
	const [createAttributeRule] = useMutation<CreateAttributeRuleData, CreateAttributeRuleVars>(CREATE_ATTRIBUTE_RULE);
	const [updateAttributeRule] = useMutation<UpdateAttributeRuleData, UpdateAttributeRuleVars>(UPDATE_ATTRIBUTE_RULE);
	const [deleteAttributeRule] = useMutation(DELETE_ATTRIBUTE_RULE);

	const handleCloseModal = (): void => setShowUploadModal(false);
	const handleCloseARModal = (): void => setShowUploadARModal(false);
	const handleCloseAttrModal = (): void => setShowAttributeModal(false);

	/**
	 * Deletes the image on the current question
	 */
	const handleDeleteImage = (): void => {
		if (question.image || question.video || question.arModel) {
			setIsDeletingMedia(true);
			updateQuestion({
				variables: {
					id: question.id,
					deletions: {
						imageId: true,
						videoId: true,
						arModelId: true,
					},
				},
				onCompleted: () => setIsDeletingMedia(false),
				onError: () => {
					updateToast({description: "Failed to delete image", type: "failure"});
					setIsDeletingMedia(false);
				},
			});
		}
	};

	const handleTextChange = (newValue: string): void => {
		updateQuestion({
			variables: {id: question.id, changes: {text: newValue}},
			onError: () => updateToast({description: "Failed to update question text", type: "failure"}),
		});
	};
	/**
	 * Changes the text for the follow up question (available only on multiple choice)
	 * @param newValue new text value received from input
	 * @returns updates the text value on question
	 */
	const handleFollowUpChange = (newValue: string): void => {
		updateQuestion({
			variables: {id: question.id, changes: {followUp: newValue}},
			onError: () => updateToast({description: "Failed to update follow up question text", type: "failure"}),
		});
	};

	const handleRequired = (e: React.ChangeEvent<HTMLInputElement>): void => {
		updateQuestion({
			variables: {id: question.id, changes: {required: e.target.checked}},
		});
	};

	const handleToggleVideo = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (event.target.checked) {
			updateQuestion({
				variables: {
					id: question.id,
					changes: {videoResponse: event.target.checked, followUp: "Enter your question..."},
				},
				// Not strictly needed, but makes UI update faster on bad connections.
				optimisticResponse: {
					updateQuestion: {
						...question,
						videoResponse: event.target.checked,
					},
				},
			});
		} else {
			updateQuestion({
				variables: {id: question.id, changes: {videoResponse: event.target.checked}},
				// Not strictly needed, but makes UI update faster on bad connections.
				optimisticResponse: {
					updateQuestion: {
						...question,
						videoResponse: event.target.checked,
					},
				},
			});
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleRemoveAttr = async (): Promise<any> => {
		if (!rule || !attr) return;
		return deleteAttributeRule({
			variables: {id: rule.id},
			onCompleted: () => {
				setAttr(undefined);
				setRule(undefined);
			},
			update(cache, {data}) {
				if (data) {
					cache.modify({
						id: `Attribute:${attr.id}`,
						fields: {
							rules(currentItems = [], {readField}) {
								const updatedItems = currentItems.filter(

									(ref) => data.deleteAttributeRule.id !== readField("id", ref),
								);
								return updatedItems;
							},
						},
					});

					cache.evict({id: `AttributeRule:${data.deleteAttributeRule.id}`, broadcast: false});
					cache.gc();
				}
			},
		});
	};

	/**
	 * Changes the time limit for the current question
	 * @param newTime New time (in seconds)
	 * @returns updates timeLimit on current question
	 */
	const handleTimerChange = (newTime: number): void => {
		updateQuestion({
			variables: {id: question.id, changes: {timeLimit: newTime}},
		});
	};

	/**
	 * Each attribute needs a rule. For now we only want a single rule
	 * per question. Generally used on initial selection of an attribute.
	 *
	 * Only currently support "SET" and "ADD" actions.
	 */

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const createNewRule = async (attribute: Attribute): Promise<any> =>
		createAttributeRule({
			variables: {
				attributeId: attribute.id,
				questionId: question.id,
				picked: true,
				action: RuleAction.ADD,
			},
			onCompleted: (data) => {
				if (rule || attr) handleRemoveAttr();
				setRule(data.createAttributeRule);
				setAttr(attribute);
			},
			update(cache, {data}) {
				if (!data) return;
				const newRef = cache.writeFragment({
					data: data.createAttributeRule,
					fragment: ATTR_RULE_FRAGMENT,
				});
				cache.modify({
					id: `Attribute:${attribute.id}`,
					fields: {
						rules(currentRules = []) {
							return [...currentRules, newRef];
						},
					},
				});
			},
		});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleCreateAttr = async (name: string): Promise<any> => {
		if (attr || rule) await handleRemoveAttr();
		createAttribute({
			variables: {
				workspaceId,
				name,
				type: AttributeType.USER,
			},
			onCompleted: (data) => {
				if (!data) return;
				updateToast({description: "Created new property", type: "informational"});
				createNewRule(data.createAttribute);
			},
			update(cache, {data}) {
				if (!data) return;
				const newRef = cache.writeFragment({
					data: data.createAttribute,
					fragment: gql`
						fragment NewAttribute on Attribute {
							id
							workspaceId
							name
							type
							category {
								id
								name
							}
							rules {
								id
								attributeId
								questionId
								picked
								action
							}
						}
					`,
				});
				updateCacheAddPageItem(cache, newRef, "attributes", data.createAttribute.id);
			},
		});
	};

	const handleSaveAsTemplate = (): void => {
		createQuestionTemplate({
			variables: {questionId: question.id, workspaceId},
			update(cache, {data: createData}) {
				if (createData) {
					const newRef = cache.writeFragment({
						data: createData.createQuestionTemplateFromQuestion,
						fragment: QUESTION_TEMPLATE_FRAGMENT,
					});

					updateCacheAddPageItem(cache, newRef, "questionTemplates", createData.createQuestionTemplateFromQuestion.id);
				}
			},
		});
	};

	const handlePreview = (): void => {
		const open = window.open(`${config.responder}/#/${surveyId}/preview`);
		(open as Window).opener = null;
	};

	async function handleDownload(e): Promise<void> {
		e.preventDefault(); // Prevent the default anchor behavior

		const url = `${config.apiHost}/exports/survey/docx/${surveyId}`;

		try {
			const blob = await client.get(url).blob();
			const downloadUrl = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = downloadUrl;
			a.download = "survey.docx"; // The filename you want to download as
			document.body.appendChild(a);
			a.click();
			a.remove(); // Clean up
			window.URL.revokeObjectURL(downloadUrl);
		} catch (error) {
			console.error("Download failed:", error);
		}
	}

	const handleChangeAction = (newAction: RuleAction): void => {
		if (!(rule && attr)) return;
		updateAttributeRule({
			variables: {
				id: rule.id,
				changes: {
					action: newAction,
					questionId: question.id,
					attributeId: attr.id,
					picked: true,
				},
			},
			onCompleted: () => {
				setRule({...rule, action: newAction});
			},
		});
	};

	const setCurrentAttr = (newAttr: Attribute | undefined): void => {
		if (!newAttr) {
			setRule(undefined);
			handleRemoveAttr();
			return;
		}
		if (!rule) {
			createNewRule(newAttr);
			return;
		}
		/**
		 * If we have a current rule while changing the attribute, we need to update
		 * the current rule to be on the new attribute instead.
		 */
		updateAttributeRule({
			variables: {
				id: rule.id,
				changes: {
					action: rule.action,
					questionId: question.id,
					attributeId: newAttr.id,
					picked: true,
				},
			},
			onCompleted: (data) => {
				setAttr(newAttr);
				setRule(data.updateAttributeRule);
			},
			update(cache, {data}) {
				if (!data) return;
				const newRef = cache.writeFragment({
					data: data.updateAttributeRule,
					fragment: ATTR_RULE_FRAGMENT,
				});
				cache.modify({
					id: `Attribute:${newAttr.id}`,
					fields: {
						rules(currentRules = []) {
							return [...currentRules, newRef];
						},
					},
				});
				cache.modify({
					id: `Attribute:${attr?.id}`,
					fields: {
						rules({DELETE}) {
							return DELETE;
						},
					},
				});
			},
		});
	};

	/**
	 * WorkspacePlan for the workspace we are looking at.
	 */

	const saveToQuestionLibraryButton = (
		<Button
			variant="outlined"
			onClick={handleSaveAsTemplate}
			className={styles.addLibrary}
			leftIcon={<FolderAddPlusIcon />}
		>
			Save to Library
		</Button>
	);

	const toggleButton =
		question.type === QuestionType.NONE ? undefined : (
			<ToggleSwitch isChecked={question.videoResponse} id="video-toggle" onChange={handleToggleVideo} />
		);
	const info = QUESTION_TYPES[questionType].info;
	const text = QUESTION_TYPES[questionType].text;
	const title = info ? `${text}: ${info}` : text;

	return (
		<>
			<animated.div style={animateProps} className={styles.main}>
				<div className={styles.buttons}>
					<Button leftIcon={<EyeIcon />} className={styles.preview} variant="outlined" onClick={handlePreview}>
						Preview
					</Button>
					<Button leftIcon={<DownloadIcon />} variant="outlined" onClick={handleDownload}>
						Download Survey
					</Button>
				</div>
				<Card className={styles.card}>
					{question.type !== QuestionType.NONE && (
						<Form title={title} toggle={saveToQuestionLibraryButton}>
							<FormControl label="Question" id="multiple-choice-question">
								<AutoResizeTextarea
									value={questionText}
									onChange={setQuestionText}
									onBlur={handleTextChange}
									placeholder="Untitled Question"
									id="multiple-choice-question"
									key={question.id}
									maxLength={400}
								/>
								{/* TODO: ucomment when https://vurvey.atlassian.net/browse/VU-1599 will be ready */}
								{/* <Checkbox
									id="required-checkbox"
									checked={question.required}
									onChange={handleRequired}
									text="Required"
									className={styles.requiredCheckbox}
									size="s"
								/> */}
							</FormControl>

							<FormControl label="Graphic (optional)">
								<QuestionMedia
									deleteImage={handleDeleteImage}
									image={question.image || question.video || question.arModel}
									setShowUploadModal={setShowUploadModal}
									setShowUploadARModal={setShowUploadARModal}
									isDeleting={isDeletingMedia}
								/>
							</FormControl>
							{/* Builds the bulk of the "form" based on question(Type)*/}
							<FormBuilder question={question} questionType={questionType} />
							{

								!question.rank && question.type !== QuestionType.PICTURE && question.type !== QuestionType.BARCODE && (
									<FormControl
										label="Map responses to Creator profile data (optional)"
										labelClassName={styles.attributeSelectorLabel}
										className={styles.attributeSelector}
									>
										<AttributeSelector
											value={attr}
											attributes={attributes}
											showModal={setShowAttributeModal}
											handleSelection={setCurrentAttr}
										/>
									</FormControl>
								)
							}
							{attr && (
								<FormControl>
									<RadioGroup size="s" value={rule?.action} onChange={handleChangeAction}>
										<RadioGroup.Option value={RuleAction.ADD} label="Append: add to existing value" disabled={!attr} />
										<RadioGroup.Option value={RuleAction.SET} label="Set: overwrite existing value" disabled={!attr} />
									</RadioGroup>
								</FormControl>
							)}
						</Form>
					)}
					{questionType !== "VIDUPLOAD" && questionType !== "BARCODE" && (
						<Form
							title={question.type === QuestionType.NONE ? "Video" : "Video follow up"}

							toggle={question.type === QuestionType.NONE ? saveToQuestionLibraryButton : toggleButton}
						>
							{question.videoResponse && (
								<VideoFollowUpForm
									question={question}
									key={question.followUp}
									handleTimerChange={handleTimerChange}
									handleTextChange={handleTextChange}
									handleFollowUpChange={handleFollowUpChange}
									checkBox={
										question.type === QuestionType.NONE ? (
											<Checkbox
												id="required-checkbox"
												checked={question.required}
												onChange={handleRequired}
												text="Required"
												className={styles.requiredCheckbox}
												size="s"
											/>
										) : undefined
									}
									mediaForm={
										<FormControl label="Graphic (optional)">
											<QuestionMedia
												deleteImage={handleDeleteImage}
												image={question.image || question.video || question.arModel}
												setShowUploadModal={setShowUploadModal}
												setShowUploadARModal={setShowUploadARModal}
												isDeleting={isDeletingMedia}
											/>
										</FormControl>
									}
								/>
							)}
						</Form>
					)}
				</Card>
			</animated.div>
			<QuestionImageUploadModal isOpen={showUploadModal} onClose={handleCloseModal} questionId={question.id} />
			<ARUploadModal isOpen={showUploadARModal} onClose={handleCloseARModal} questionId={question.id} />

			<AttributeCreatorModal
				isOpen={showAttributeModal}
				onClose={handleCloseAttrModal}

				handleCreate={handleCreateAttr}
			/>
		</>
	);
};

export {QuestionPreviewer};
