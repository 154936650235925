import {useUserContext} from "../../../../context/user-context";
import React, {ReactElement, useState} from "react";

import {Avatar, ButtonIcon, Body, Separator, Tooltip} from "../../../../shared/v2";
import {cache} from "../../../../cache";
import {ConfirmLogoutModal} from "../../../../shared/v2/modals/confirm-logout-modal";
import {LogoutIcon, WeatherSunIcon, AstrologyMoonIcon} from "../../../../icons";
import {NavLink} from "../../../../route";
import {useAuthentication} from "../../../../hooks/user/useAuthentication";
import {useThemeMode} from "../../../../context/theme-mode-context";

import styles from "./footer.module.scss";

export const Footer = (): ReactElement => {
	const {user: me} = useUserContext();
	const {logout} = useAuthentication();
	const {isDarkMode, toggleTheme} = useThemeMode();

	const [isConfirmLogoutModalOpen, setIsConfirmLogoutModalOpen] = useState(false);

	const confirmLogout = (): void => {
		cache.reset();
		logout();
		window.location.href = window.location.origin;
	};

	return (
		<>
			<div className={styles.footer}>
				<Separator color="navigation" className={styles.footerSeperator} />
				<div className={styles.profileWrapper}>
					<NavLink
						workspace
						to="/me"
						className={styles.userSectionWrapper}
					>
						<Avatar
							url={me.picture?.thumbnail}
							firstName={me.firstName}
							key={me.picture?.thumbnail}
						/>
						<div className={styles.userInfo}>
							<Body
								size="s"
								type="semibold"
								className={styles.text}
							>
								{me.firstName} {me.lastName || me.lastInitial}
							</Body>
							<Body className={styles.text} size="xs" color="text-secondary">{me.email}</Body>
						</div>
					</NavLink>
					<div className={styles.actionsWrapper}>
						<Tooltip
							content={<Body size="xs">{isDarkMode ? "Light Mode" : "Dark Mode"}</Body>}
						>
							<ButtonIcon 
								aria-label="theme-toggle-button" 
								onClick={toggleTheme} 
								icon={isDarkMode ? <WeatherSunIcon /> : <AstrologyMoonIcon />} 
							/>
						</Tooltip>
						<Tooltip
							content={<Body size="xs">Log out</Body>}
						>
							<ButtonIcon aria-label="logout-button" onClick={() => setIsConfirmLogoutModalOpen(true)} icon={<LogoutIcon />} />
						</Tooltip>
					</div>
				</div>
			</div>
			<ConfirmLogoutModal
				isOpen={isConfirmLogoutModalOpen}
				onClose={() => setIsConfirmLogoutModalOpen(false)}
				onConfirm={confirmLogout}
			/>
		</>
	)
}
